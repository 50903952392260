import SkiAndNightApiService from './BaseSkiAndNightApiService';

const USUARIO_API_VERSION = 'v1';
const USUARIO_API_BASEPATH_URL = '/festivales/' + USUARIO_API_VERSION;

class EventosApiService {
	async getFestivales() {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL);
	}

	async getFestival(uri) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}`));
	}

	async getFestivalSeo(uri) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/seo`));
	}

	async getEventosFestival(uri) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos`));
	}

	async getEvento(uri, eventoId) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}`));
	}

	async getTicketsEvento(evento) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${evento}/obtenerTickets`));
	}

	async createTicketera(uri, eventoId, bodyCreateTicketera) {
		return await SkiAndNightApiService.post(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/ticketera`),
			bodyCreateTicketera
		);
	}

	async getTicketera() {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat('/ticketeras'));
	}

	async getInformacionTicketera(uri, eventoId, codigoUnico) {
		return await SkiAndNightApiService.get(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/tickets/${codigoUnico}`)
		);
	}

	async getValidarTicketera(uri, eventoId, codigoUnico) {
		return await SkiAndNightApiService.post(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/tickets/${codigoUnico}/validar`)
		);
	}

	async getValidaCodigoDescuento(uri, eventoId, ticketeraId, codigo) {
		return await SkiAndNightApiService.get(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/validarDescuento?codigo=${codigo}&ticketeraId=${ticketeraId}`)
		);
	}

}

export default new EventosApiService();
