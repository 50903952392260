<template>
	<teleport to="body">
		<modal-base :show="modalActive" hide-submit hide-cancel :title="$t('general.resumen')" @on-close="close">
			<template #body>
				<MisReservasPago :grupo="grupo" :reset="false" style="width: 1000px" />
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase.vue';
	import MisReservasPago from '@/components/modales/MisReservasPago.vue';

	defineProps({
		grupo: {
			type: Object,
			default: null,
		},
		modalActive: {
			type: Boolean,
			default: null,
		},
	});

	const emit = defineEmits(['close-modal']);

	const close = () => emit('close-modal');
</script>

<style lang="scss"></style>
