<template>
	<template v-if="!loading">
		<div class="row container-view justify-content-around">
			<div class="col-12 col-sm-10 col-lg-7 position-relative">
				<h4 class="text-primary fw-bold">Viajes a medida</h4>
				<p class="fs-6 text-muted">Desde Ski&Night te damos la opción de que cada persona elija sus extras a medida</p>

				<template v-for="(informacionHabitacion, indexHabitacion) of listaServiciosHoteltes" :key="indexHabitacion">
					<h4 class="text-primary fw-bold">{{ $t('general.habitacion') }} {{ indexHabitacion + 1 }}</h4>
					<template v-for="(miembroHabitacion, indexPersona) of informacionHabitacion" :key="miembroHabitacion">
						<div class="my-3">
							<dropwdown-services-buscador
								:index-habitacion="indexHabitacion"
								:index-persona="indexPersona"
								:informacion-miembro="miembroHabitacion"
								:id-viaje="viajeId"
								@servicios-persona-actualizado="actualizarPersonaHabitacion($event, indexHabitacion)" />
						</div>
					</template>
				</template>
			</div>
			<div v-if="!loading" class="d-none d-lg-block col-4">
				<CardResumenServiciosBuscador :servicios-seleccionados="serviciosSelecionados" />
			</div>

			<template v-if="!isLogged">
				<div class="my-4">
					<div v-if="!isLogged" class="pt-3">
						<LoginReserva
							v-if="!loginFormData.recordar && !loginFormData.fromRecordar"
							@login="updateRecordar()"
							@recordar="updateRecordar()" />

						<LoginReserva
							v-else-if="!loginFormData.recordar && loginFormData.fromRecordar"
							:show-registrar-inicial="false"
							@login="updateRecordar()"
							@recordar="updateRecordar()" />

						<UsuarioPasswordRecordar
							v-else
							:from-login="false"
							:from-flujo-reserva="true"
							@volver-a-login="updateRecordar()" />
					</div>
				</div>
			</template>
			<div class="text-center">
				<button class="btn btn-primary me-2" @click="$router.go(-1)">
					{{ $t('general.atras') }}
				</button>
				<button class="btn btn-primary" @click="crearReserva()">
					{{ $t('general.siguiente') }}
				</button>
			</div>
		</div>
	</template>
	<template v-else>
		<div class="container-loading">
			<Spinner />
		</div>
	</template>
</template>

<script>
	import { computed, defineComponent, reactive, watch, ref } from 'vue';
	import BuscadorApiService from '@/services/api/skiandnight/BuscadorApiServices';
	import CardResumenServiciosBuscador from 'src/components/card/Reserva/CardResumenServiciosBuscador.vue';
	import UsuarioPasswordRecordar from 'src/views/usuario/UsuarioPasswordRecordar/UsuarioPasswordRecordar.vue';
	import DropwdownServicesBuscador from 'src/components/dropdown/DropwdownServicesBuscador.vue';
	import Spinner from 'src/components/SpinnerLoading.vue';
	import LoginReserva from 'src/views/login/LoginReserva.vue';
	import { reservaHoteles } from '@/store_pinia/reservaHoteles';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { useRouter } from 'vue-router';
	import { fomatDatesHyphen } from '@/helpers/dateUtiles';
	import { BuscadorResumen } from '@/router/RouterNames';
	import { createToaster } from '@meforma/vue-toaster';
	import { controlErrorReservarHotel } from 'src/services/utils/ErrorControl';
	import { useStore } from 'vuex';

	export default defineComponent({
		components: {
			DropwdownServicesBuscador,
			CardResumenServiciosBuscador,
			UsuarioPasswordRecordar,
			Spinner,
			LoginReserva,
		},
		setup() {
			const router = useRouter();

			const store = useStore();

			const isLogged = computed(() => store.state.login.isLogged);

			const buscador_store = buscadorStore();

			const loading = ref(true);

			const viajeId = ref(0);

			const storeReservaHoteles = reservaHoteles();

			let reservaHotelHabitacion = reactive({});

			storeReservaHoteles.$reset();

			let filtroBuscador = {};

			if (!buscador_store.combinacionSelecionada) {
				loading.value = true;
				router.push({ name: 'home' });
			} else {
				filtroBuscador = {
					area: buscador_store.filtrosSeleccionadosBuscador.destino.areaId,
					lugarExperiencia: buscador_store.estacionOpcionSeleccionada,
					hotel: `${buscador_store.hotelSeleccionado.id}`,
					cacheResultadosId: buscador_store.combinacionSelecionada.cacheResultadosId,
					cacheForfaitsId: buscador_store.combinacionSelecionada.cacheForfaitsId,
					startDate: fomatDatesHyphen(buscador_store.filtrosSeleccionadosBuscador.fechasAlojamiento[0]),
					endDate: fomatDatesHyphen(buscador_store.filtrosSeleccionadosBuscador.fechasAlojamiento[1]),
					startDateFF: fomatDatesHyphen(buscador_store.filtrosSeleccionadosBuscador.fechasForfait[0]),
					endDateFF: fomatDatesHyphen(buscador_store.filtrosSeleccionadosBuscador.fechasForfait[1]),
					habitaciones: buscador_store.filtrosSeleccionadosBuscador.habitaciones,
				};
			}

			const toaster = createToaster({ position: 'top-right' });

			const loginFormData = reactive({
				email: false,
				fromRecordar: false,
				recordar: false,
			});

			function updateRecordar(email) {
				loginFormData.email = email;
				loginFormData.fromRecordar = true;
				loginFormData.recordar = !loginFormData.recordar;
			}

			const listaServiciosHoteltes = computed(() => storeReservaHoteles.informacionHabitaciones);

			const serviciosSelecionados = computed(() => storeReservaHoteles.serviciosSelecionados);

			const informacionCombinacion = computed(() => {
				let combinacionAux = [];
				buscador_store.combinacionSelecionada.combinacion.map(combinacion => {
					combinacionAux[combinacion.rph - 1] = combinacion;
				});
				return combinacionAux;
			});

			const actualizarPersonaHabitacion = ($event, indexHabitacion) => {
				Object.keys($event).map(persona => {
					Object.keys($event[persona]).map(servicio => {
						reservaHotelHabitacion['habitacion' + indexHabitacion][persona][servicio] = $event[persona][servicio];
					});
				});
			};

			const emailComputed = computed(() => {
				return loginFormData.email;
			});

			const crearReserva = () => {
				if (!store.state.login.isLogged) {
					toaster.error('Es necesario logearse para continuar');
					return;
				}
				if (storeReservaHoteles.nombresIncompletos) {
					toaster.error('Existen nombres sin completar');
					return;
				}

				let informacionReserva = BuscadorApiService._buildReserva(
					reservaHotelHabitacion,
					informacionCombinacion.value,
					viajeId.value,
					buscador_store.echoToken,
					buscador_store.combinacionSelecionada.tipoCancelacion
				);

				BuscadorApiService.createReserva(informacionReserva)
					.then(res => {
						storeReservaHoteles.setReservaCreada(res);
					})
					.catch(err => {
						controlErrorReservarHotel(err);
					})
					.then(() => {
						router.push({ name: BuscadorResumen });
					});
			};

			BuscadorApiService.getServiciosHotel(filtroBuscador)
				.then(res => {
					storeReservaHoteles.setServiciosHabitaciones(res);
					viajeId.value = res.viajeId;
				})
				.then(() => {
					reservaHotelHabitacion = storeReservaHoteles.serviciosSelecionados;
				})
				.finally(() => {
					loading.value = false;
				});

			watch(reservaHotelHabitacion, () => storeReservaHoteles.setServiciosSeleccionados(reservaHotelHabitacion), {
				deep: true,
			});

			return {
				listaServiciosHoteltes,
				actualizarPersonaHabitacion,
				reservaHotelHabitacion,
				serviciosSelecionados,
				loading,
				crearReserva,
				viajeId,
				isLogged,
				updateRecordar,
				emailComputed,
				loginFormData,
			};
		},
	});
</script>

<style scoped>
	.container-view {
		padding-top: 12rem;
		padding-bottom: 4rem;
		margin: auto;
	}

	.btn-secondary {
		border-radius: var(--bs-border-radius-xl);
		color: white;
	}

	.container-loading {
		height: 80vh;
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.mensaje-navidad {
		right: 50px;
		top: -70px;
		font-size: 13px;
		max-width: 300px;
	}
</style>
