<template>
	<div v-if="storeGlobal.valores != {}" class="banner-top-page__container">
		<div
			:class="backgroundBanner.class"
			:style="backgroundBanner.url ? { 'background-image': 'url(' + backgroundBanner.url + ')' } : {}">
			<div v-if="storeGlobal.permisos.filtro_viajes.showed" class="container py-3 pt-5 pt-md-3">
				<div class="mt-5">
					<BuscadorContainer :selector-grupos="viajesGrupoCatalogo" :is-home="isHomePage" />
				</div>
			</div>

			<div class="container" :class="!storeGlobal.permisos.filtro_viajes.showed ? 'pt-5 pt-md-3 mt-5' : ''">
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup>
	import BuscadorContainer from '@/components/buscadores/BuscadorContainer.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import { computed, ref, onMounted } from 'vue';

	import { useRoute } from 'vue-router';
	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		viajesGrupoCatalogo: Boolean,
	});

	const isMobile = ref(window.innerWidth <= 992);

	const storeGlobal = appGlobalStore();

	const route = useRoute();

	const isHomePage = ref(false);

	function checkIfHomePage() {
		isHomePage.value = route.name === 'home';
	}

	const backgroundBanner = computed(() => {
		if (isMobile.value && !isHomePage.value) {
			return {};
		}
		if (isHomePage.value) {
			return {
				class: 'home_background',
				url: storeGlobal.valores.img_home,
			};
		}
		if (props.viajesGrupoCatalogo) {
			return {
				class: 'viajes-grupo-catalogo__background',
				url: storeGlobal.valores.img_catalogo_fondo,
			};
		}
		return {};
	});

	onMounted(() => {
		checkIfHomePage();
		window.addEventListener('resize', () => {
			isMobile.value = window.innerWidth <= 992;
		});
	});
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/san.scss';

	.banner-top-page__container {
		.home_background {
			padding-top: 14rem;
			padding-bottom: 3rem;
			background-position: 50%;
			// background-image: url('~@/assets/fotos/est1.webp');
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}

		.viajes-grupo-catalogo__background {
			// background-image: url('~@/assets/home/banner.png');
			padding-top: 10rem;
			padding-bottom: 4rem;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}
	}
</style>
