import i18n from '../i18n';
const { t } = i18n.global;

const FORFAIT_SELECTOR = 'forfait';
const RESTAURACION_SELECTOR = 'restauracion';
const TRANSPORTE_SELECTOR = 'transporte';
const SEGURO_SELECTOR = 'seguro';
const ACTIVIDAD_SELECTOR = 'actividad';
const FESTIVAL_SELECTOR = 'festival';
const CLASES_SELECTOR = 'clases';
const ALQUILER_SELECTOR = 'alquiler';

export const selectServiceText = {
	[FORFAIT_SELECTOR]: {
		titulo: t('servicios.forfait.titulo'),
		subtitulo: t('servicios.forfait.subtitulo'),
		icono: 'fa-solid fa-credit-card fa-2x',
	},
	[RESTAURACION_SELECTOR]: {
		titulo: t('servicios.comida.titulo'),
		subtitulo: t('servicios.comida.subtitulo'),
		icono: 'fa-solid fa-bowl-food fa-2x',
	},
	[TRANSPORTE_SELECTOR]: {
		titulo: t('servicios.transporte.titulo'),
		subtitulo: t('servicios.transporte.subtitulo'),
		icono: 'fa-solid fa-van-shuttle fa-2x',
	},
	[SEGURO_SELECTOR]: {
		titulo: t('servicios.seguro.titulo'),
		subtitulo: t('servicios.seguro.subtitulo'),
		icono: 'fa-solid fa-notes-medical fa-2x',
	},
	[ACTIVIDAD_SELECTOR]: {
		titulo: t('servicios.actividad.titulo'),
		subtitulo: t('servicios.actividad.subtitulo'),
		icono: 'fa-solid fa-plus',
	},
	[FESTIVAL_SELECTOR]: {
		titulo: t('servicios.festival.titulo'),
		subtitulo: t('servicios.festival.subtitulo'),
		icono: 'fa-solid fa-credit-card fa-2x',
	},
	[CLASES_SELECTOR]: {
		titulo: t('servicios.clase.titulo'),
		subtitulo: t('servicios.clase.subtitulo'),
		icono: 'fa-solid fa-person-skiing fa-2x',
	},
	[ALQUILER_SELECTOR]: {
		titulo: t('servicios.alquiler.titulo'),
		subtitulo: t('servicios.alquiler.subtitulo'),
		icono: 'fa-solid fa-person-skiing-nordic fa-2x',
	},
};
