import SkiAndNightApiService from './BaseSkiAndNightApiService';
import axios from 'axios';

const PASARELAPAGO_API_VERSION = 'v1';
const PASARELAPAGO_API_BASEPATH_URL = '/pasarelaPago/' + PASARELAPAGO_API_VERSION;

class PasarelaPagoApiService {
	/**
	 * Crea en el sistema la solicitud para el cobro inicial de una reserva dada y devuelve la informacion necesaria
	 * para redirigir a la pasarela de pago
	 *
	 * @param reservaId
	 * @param codigoDescuento
	 * @param pagoUnico
	 * @returns {Promise<*>} Objeto que contiene todos los datos necesarios para hacer la redireccion a la pasarela de pago
	 *
	 */
	async solicitaCobroInicial(
		reservaId,
		codigoDescuento,
		pagoUnico,
		conociste,
		estudiante,
		universidad,
		titulacion,
		preguntaLibreRespuesta
	) {
		const ppSolicitudCobroDTO = {
			grupoReservas: reservaId,
			codigoDescuento,
			pagoUnico,
			conociste,
			estudiante,
			universidad,
			titulacion,
			preguntaLibreRespuesta,
		};
		return await SkiAndNightApiService.post(
			PASARELAPAGO_API_BASEPATH_URL.concat('/solicitaCobroInicial'),
			ppSolicitudCobroDTO
		);
	}

	async ticketeraCobroInicial(reservaId, codigoDescuento) {
		const ppSolicitudCobroDTO = {
			ticketera: reservaId,
			codigoDescuento: codigoDescuento,
		};
		return await SkiAndNightApiService.post(
			PASARELAPAGO_API_BASEPATH_URL.concat('/ticketera/solicitaCobroInicial'),
			ppSolicitudCobroDTO
		);
	}

	async solicitaCobroSucesivo(reservasIds, applyDraft, codigoDescuento, pagoUnico) {
		const ppSolicitudCobroDTO = {
			reservasIds,
			applyDraft,
			codigoDescuento,
			pagoUnico,
		};
		return await SkiAndNightApiService.post(
			PASARELAPAGO_API_BASEPATH_URL.concat('/solicitaCobroSucesivo'),
			ppSolicitudCobroDTO
		);
	}

	async solicitaContinuarSinPagar(reservasIds) {
		const ppSolicitudCobroDTO = {
			reservasIds,
		};
		return await SkiAndNightApiService.post(
			PASARELAPAGO_API_BASEPATH_URL.concat('/solicitaContinuarSinPagar'),
			ppSolicitudCobroDTO
		);
	}

	async getInfoPago(orderCode, success) {
		return await SkiAndNightApiService.get(
			PASARELAPAGO_API_BASEPATH_URL.concat('/infoPago' + `?orderCode=${orderCode}` + `&success=${success}`)
		);
	}

	async generarPagoBack(url, version, parametros, firma) {
		var bodyFormData = new FormData();
		bodyFormData.append('version', version);
		bodyFormData.append('parametros', parametros);
		bodyFormData.append('firma', firma);
		return await axios({
			method: 'post',
			url: url,
			data: bodyFormData,
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	}
}

export default new PasarelaPagoApiService();
