<template>
	<carousel :items-to-show="6.5" wrap-around :autoplay="4000" :breakpoints="breakpoints">
		<slide v-for="slide in listaFotos" :key="slide">
			<div class="img-carousel" :style="`background-image: url(${slide})`" />
		</slide>
	</carousel>
</template>

<script>
	import { Carousel, Slide } from 'vue3-carousel';
	export default {
		components: { Carousel, Slide },
		props: {
			listaFotos: {
				type: Array,
				default: () => [],
			},
		},
		setup() {
			return {
				breakpoints: {
					0: {
						itemsToShow: 1,
						snapAlign: 'center',
					},
					500: {
						itemsToShow: 1.4,
						snapAlign: 'center',
					},
					600: {
						itemsToShow: 1.9,
						snapAlign: 'center',
					},
					// 700px and up
					800: {
						itemsToShow: 2.5,
						snapAlign: 'center',
					},
					// 1024 and up
					1024: {
						itemsToShow: 3,
						snapAlign: 'start',
					},
					1300: {
						itemsToShow: 4,
						snapAlign: 'start',
					},
					1500: {
						itemsToShow: 5,
						snapAlign: 'start',
					},

					1800: {
						itemsToShow: 5.5,
						snapAlign: 'start',
					},
				},
			};
		},
	};
</script>

<style lang="scss" scoped>
	.img-carousel {
		border-radius: var(--bs-border-radius-xxl);
		width: 300px;
		height: 200px;
		background-size: cover;
		background-position: center;
	}
</style>
