<template>
	<template v-if="grupo && storeGlobal.valores.img_logo_agencia">
		<div class="espaciado container container-pdf m-2">
			<div class="row">
				<div class="col-md-8 px-2">
					<div class="card">
						<div class="text-primary m-3">
							<div class="title mb-2">{{ grupo.viaje.nombre }}</div>
							<div class="sub-title">{{ $t('alojamiento.key') }}</div>
							<div class="description">{{ grupo.habitacion.alojamiento.nombre }}</div>
						</div>

						<div class="card-body text-primary py-0">
							<div class="row mb-2">
								<div class="col-12 text-right">
									<div class="row">
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.fechaIni') }}</div>
											<div class="my-2 text-description">
												{{ grupo.viaje.fechaInicio }}
											</div>
										</div>
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.fechaFin') }}</div>
											<div class="my-2 text-description">
												{{ grupo.viaje.fechaFin }}
											</div>
										</div>
										<div class="col-auto">
											<div class="my-2 text-title">{{ $t('reserva.bono.direccion') }}</div>
											<div class="my-2 text-description">
												{{ grupo.habitacion.alojamiento.direccion }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-12">
									<div class="text-description mb-1">{{ $t('reserva.bono.informacionGeneral') }}</div>
									<div class="col-md-12 row text-right">
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.checkIn') }}</div>
											<div class="my-2 text-description">{{ grupo.habitacion.alojamiento.checkIn }}</div>
										</div>
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.checkOut') }}</div>
											<div class="my-2 text-description">{{ grupo.habitacion.alojamiento.checkOut }}</div>
										</div>
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.regimen') }}</div>
											<div class="my-2 text-description">{{ grupo.habitacion.regimen }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 px-2">
					<div class="ticket-body text-primary">
						<div class="row mb-2 m-3">
							<div class="col-12 pb-2">
								<p class="mb-0 text-description">
										{{ grupo.reservas[0].precioBasico }}€
								</p>
								<p class="mb-0 text-title">{{ $t('reserva.bono.impuestosIncluidos') }}</p>
								<p class="mb-0 text-title">
									{{ $t('reserva.bono.incluidoPaqueteBasico') }}
								</p>
							</div>
							<div class="col-12">
								<p class="mb-0 text-title">{{ $t('reserva.bono.titular') }}:</p>
								<template v-for="(reserva, idx) in grupo.reservas" :key="idx">
									<p class="text-description mt-1 mb-0">{{ reserva.nombreUsuario }}</p>
								</template>

								<p class="mb-0 text-title mt-2">{{ $t('reserva.bono.email') }}:</p>
								<p class="text-description">
									<template v-for="(pagador, idx) in grupo.pagadores" :key="idx">
										<p class="text-description mt-1 mb-0">{{ pagador }}</p>
									</template>
								</p>
							</div>
						</div>
						<div class="text-muted powered-by m-3 text-center">
							Powered by <img class="" style="width: 68px" src="@/assets/img/logo_mygocu.png" alt="" />
						</div>
					</div>
				</div>
				<div class="col-md-12 pt-2 px-2">
					<div class="card">
						<div class="row p-3">
							<div class="col-3 text-center align-content-center">
								<img :src="storeGlobal.valores.img_logo_agencia" alt="" class="img-logo" />
							</div>
							<div class="col-9">
								<img :src="grupo.habitacion.alojamiento.imagen" alt="" class="img-experiencia" />
							</div>
						</div>

						<div class="card-body">
							<p class="text-terms">{{ $t('general.terminosCondiciones') }}</p>
							<p class="text-terms-description">
								{{ $t('footer.terminosCondiciones.bono.descripcion') }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script setup>
	import { appGlobalStore } from '@/store_pinia/app';

	const storeGlobal = appGlobalStore();

	defineProps({
		// RGrupoDTO
		grupo: {
			type: Object,
			default: null,
		},
	});

</script>

<style lang="scss">
	.espaciado {
		margin: 9rem 1rem 1rem 1.5rem;
	}

	.container-pdf {
		background: var(--bs-white);

		.card {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			// box-shadow: 0px 1px 4px 0px rgba(0, 17, 63, 0.1);
			height: 100%;
		}

		.title {
			font-size: 16px;
			font-weight: 700;
		}

		.sub-title {
			font-size: 18px;
			font-weight: 800;
		}

		.description {
			font-size: 18px;
			font-weight: 400;
		}

		.ticket-body {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			// box-shadow: 0px 1px 4px 0px rgba(0, 17, 63, 0.1);
			height: 100%;
		}

		.text-title {
			font-size: 11px;
			font-weight: 400;
		}

		.text-description {
			font-size: 11px;
			font-weight: 600;
			margin-bottom: 8px;
		}

		.powered-by {
			font-size: 9px;
			font-weight: 400;
		}

		.text-terms {
			font-size: 9px;
			font-weight: 600;
		}

		.text-terms-description {
			font-size: 8px;
			font-weight: 400;
		}

		.img-logo {
			max-width: 90%;
			max-height: 150px;
		}

		.img-experiencia {
			width: 100%;
		}
	}
</style>
