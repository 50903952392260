<template>
	<!-- Sección cabecera del festival -->
	<template v-if="festival">
		<template v-if="!movil">
			<div
				class="d-flex flex-column align-items-center justify-content-center festival-img-fondo pb-3"
				:style="`background-image: url(${festival.imagenDesktop})`">
				<template v-if="festival.mostrarDatosCabecera">
					<p
						class="fs-2 titulo text-primary mt-5"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.titulo) }}
					</p>
					<p
						class="fs-6 subtitulo"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.subtitulo) }}
					</p>
					<p
						class="fecha-festival"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ dateFromToFestival }}
					</p>
				</template>
				<button v-if="festival.mostrarConseguirEntrada" class="btn btn-primary" @click="goEntradaFestival()">
					{{ $t('alojamiento.consigueEntrada') }}
				</button>
				<button
					v-if="festival.mostrarConseguirEntradaConAlojamiento"
					class="btn btn-primary mt-2"
					@click="goBuscadorFestival()">
					{{ $t('alojamiento.todasExperiencias') }}
				</button>
				<button v-if="uri === 'apresskimoodfestival'" class="btn btn-primary" @click="goMoodFestival()">
					{{ $t('alojamiento.todasExperiencias') }}
				</button>
				<button v-if="uri === 'PolarSoundFestival'" class="btn btn-primary mt-2" @click="goAbonoFestival()">
					{{ $t('alojamiento.consigueAbono') }}
				</button>
			</div>

			<carousel
				:items-to-show="pantalla_grande ? 5.3 : 4.5"
				:model-value="middlePhotoIndex"
				wrap-around
				:autoplay="4000"
				class="imagenes-secundarias-festival">
				<slide v-for="imagenCarrusel in festival.imagenesCarrusel" :key="imagenCarrusel">
					<div
						class="mt-5 mx-2 img-carousel justify-content-center"
						:style="`background-image: url(${imagenCarrusel})`"></div>
				</slide>
			</carousel>
		</template>
		<template v-if="movil">
			<div
				class="d-flex flex-column align-items-center justify-content-center pb-4 festival-img-fondo text-center"
				:style="`background-image: url(${festival.imagenMobile})`">
				<div v-if="festival.mostrarDatosCabecera">
					<template v-if="festival.titulo">
						<p
							class="fs-2 titulo text-primary mt-5"
							:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
							{{ capitalize(festival.titulo) }}
						</p>
					</template>
					<template v-if="festival.subtitulo">
						<p
							class="fs-6 subtitulo"
							:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
							{{ capitalize(festival.subtitulo) }}
						</p>
					</template>
					<template v-if="festival.fechaInicio">
						<p
							class="fecha-festival"
							:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
							{{ formatDatesFullMonth(festival.fechaInicio) }} -
							{{ formatDatesFullMonthAndYear(festival.fechaFin) }}
						</p>
					</template>
				</div>

				<button v-if="festival.mostrarConseguirEntrada" class="btn btn-primary" @click="goEntradaFestival()">
					{{ $t('alojamiento.consigueEntrada') }}
				</button>
				<button
					v-if="festival.mostrarConseguirEntradaConAlojamiento"
					class="btn btn-primary mt-2"
					@click="goBuscadorFestival()">
					{{ $t('alojamiento.todasExperiencias') }}
				</button>
				<button v-if="uri === 'apresskimoodfestival'" class="btn btn-primary" @click="goMoodFestival()">
					{{ $t('alojamiento.todasExperiencias') }}
				</button>
				<button v-if="uri === 'PolarSoundFestival'" class="btn btn-primary mt-2" @click="goAbonoFestival()">
					{{ $t('alojamiento.consigueAbono') }}
				</button>
			</div>
			<carousel
				:items-to-show="movil ? 2.1 : 4.5"
				:model-value="middlePhotoIndex"
				:autoplay="4000"
				class="imagenes-secundarias-festival">
				<slide v-for="imagenCarrusel in festival.imagenesCarrusel" :key="imagenCarrusel">
					<div
						class="mt-0 mx-2 img-carousel justify-content-center"
						:style="`background-image: url(${imagenCarrusel})`"></div>
				</slide>
			</carousel>
		</template>

		<div class="festivales-detalle">
			<!--Datos del festival-->
			<div class="container">
				<!-- Sección datos del festival -->
				<div class="row gx-0">
					<template v-if="movil">
						<div class="d-flex flex-column">
							<p class="ubicacion-festival mt-4 mb-0">
								{{ festival.ubicacion }}
							</p>
							<button
								class="btn btn-link d-flex align-items-center d-sm-none mb-4 p-0"
								data-bs-toggle="modal"
								data-bs-target="#mapaModalFestival">
								<i class="fa-solid fa-location-pin" />
								<span class="ver-mapa mx-1">{{ $t('alojamiento.verMapa') }}</span>
							</button>
						</div>
					</template>

					<div class="col-12 col-sm-6">
						<div class="me-0 me-sm-5 mb-5">
							<iframe width="100%" :height="movil ? 250 : 350" :src="festival.video" />
						</div>
					</div>

					<div class="d-flex flex-column col-12 col-sm-6">
						<template v-if="!movil">
							<div class="d-flex align-items-center mb-4">
								<p class="ubicacion-festival">
									{{ festival.ubicacion }}
								</p>
								<button
									class="btn btn-link d-none d-sm-flex align-items-center py-0 ms-4"
									data-bs-toggle="modal"
									data-bs-target="#mapaModalFestival">
									<i class="fa-solid fa-location-pin" />
									<span class="mx-1">{{ $t('alojamiento.verMapa') }}</span>
								</button>
							</div>
						</template>
						<div class="d-flex justify-content-between align-items-center">
							<div
								id="mapaModalFestival"
								class="modal fade"
								tabindex="-1"
								aria-labelledby="mapaModalFestivalLabel"
								aria-hidden="true">
								<div class="modal-dialog">
									<div class="modal-content">
										<div class="modal-header">
											<h5 id="mapaModalFestivalLabel" class="modal-title">{{ $t('alojamiento.ubicacion') }}</h5>
											<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
										</div>
										<div class="modal-body">
											<GMapMap :center="posicion" :zoom="8" map-type-id="terrain" style="min-height: 60vh">
												<GMapMarker :position="posicion" :clickable="true" :draggable="false" />
											</GMapMap>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p id="descripcion" v-sanitize-html="festival.descripcion" class="descripcion-festival" />
					</div>
				</div>
				<template v-if="viajesAux && viajesAux.length > 0">
					<div class="mb-5">
						<div class="d-flex justify-content-between align-items-center">
							<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.mejoresOfertas') }}</p>
							<a
								class="text-end align-self-center cursor-pointer text-decoration-underline fs-6 fs-lg-2"
								@click="goBuscadorFestival"
								>{{ $t('alojamiento.todasExperiencias') }}</a
							>
						</div>
						<div v-if="!isMobile" class="d-md-block">
							<div class="row gy-5 mt-1">
								<div v-for="viaje in viajesAux" :key="viaje.id" class="col-6 col-lg-4 col-xl-3">
									<trip-card
										:tipos-viaje="buscador.filtrosViajes.tiposGrupo"
										:viaje-tarjeta="viaje"
										@unirse-habitacion="showUnirseHabitacionModal = true" />
								</div>
							</div>
						</div>
						<div v-else>
							<CarouselViajesCatalogo
								:lista-viajes="viajesAux"
								:tipos-viaje="buscador.filtrosViajes.tiposGrupo"
								class="viaje-area__viajes-mobile" />
						</div>
					</div>
				</template>

				<!-- Sección line up del festival -->
				<template v-if="festival.artistas && festival.artistas.length > 0">
					<base-carousel
						:items-carousel="festival.artistas"
						:is-loading="false"
						:autoplay-time="5000"
						:max-elements="4"
						class="mx-auto px-2"
						style="max-width: 1440px">
						<template #title>
							<div class="d-flex justify-content-between align-items-center">
								<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.lineUp') }}</p>
							</div>
						</template>
						<template #itemCarousel="itemCarouselProps">
							<div class="d-flex flex-column gap-3">
								<img class="img-carousel-lineup" :src="itemCarouselProps.data.imagenArtista" alt="" />
								<iframe
									style="border-radius: var(--bs-border-radius-xl); padding: 0 20px"
									:src="itemCarouselProps.data.urlCancion"
									width="100%"
									height="102"
									frameBorder="0"
									allowfullscreen=""
									allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
									loading="lazy" />
							</div>
						</template>
					</base-carousel>
				</template>

				<!-- Sección sponsors del festival -->
				<!-- <Sponsors /> -->
				<!-- <div class="mb-5">
            <p class="fs-3 titulo-sponsors mb-4 m-0">Sponsors y colaboradores</p>
            <div class="row align-items-center">
              <div class="col-6 col-md d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/jgermeister.png')}`" alt="">
              </div>
              <div class="col-6 col-md d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/estrella-damm.png')}`" alt="">
              </div>
              <div class="col-6 col-md d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/la-moraleja-cf.png')}`" alt="">
              </div>
              <div class="col-6 col-md-2 d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/unievento.png')}`" alt="">
              </div>
              <div class="col-6 col-md d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/Aroma-King.png')}`" alt="">
              </div>
              <div class="col-6 col-md-2 d-flex justify-content-center my-2">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/adam-foods.png')}`" alt="">
              </div>
              <div class="col-12 col-md-2 d-flex justify-content-center my-5">
                <img class="img-sponsor-festival"  :src="`${require('@/assets/sponsors/red-bull.png')}`" alt="">
              </div>
            </div>
          </div> -->
			</div>
		</div>
	</template>

	<template v-else>
		<Spinner />
	</template>
</template>

<script>
	import EventosApiService from 'src/services/api/skiandnight/EventosApiService';
	import { useRoute } from 'vue-router';

	export default {
		beforeCreate: async function () {
			const currentRoute = await useRoute();
			const metaData = await EventosApiService.getFestivalSeo(currentRoute.params.uri);
			if (metaData['titulo'] && metaData['descripcion']) {
				document.title = metaData.titulo;
				document.querySelector("[name='description']").remove();
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = metaData.descripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		},
	};
</script>
<script setup>
	// import { useRoute } from "vue-router";
	import Spinner from '@/components/SpinnerLoading.vue';
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import { festivalesStore } from '@/store_pinia/festivales';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import { capitalize, titleCase } from '@/helpers/filters';
	import { GruposReservaFormularioUnirse } from '@/router/RouterNames';
	// import Sponsors from "@/components/SponsorsSection.vue";
	import { formatDatesFullMonth, formatDatesFullMonthAndYear, getFullYearDate } from '@/helpers/dateUtiles';
	import { FestivalesCatalogo } from 'src/router/RouterNames';
	import { Carousel, Slide } from 'vue3-carousel';
	import router from 'src/router';
	import CarouselViajesCatalogo from 'src/components/Carousel/CarouselViajesCatalogo.vue';
	import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
	import {
		filtrarViajesPorFestival,
		obtenerIdAreaPorNombre,
		obtenerIdEstacionPorNombre,
		obtenerIdGrupoPorNombre,
	} from '@/helpers/viajesGrupos';
	import { viajesGrupos } from '@/store_pinia/viajesGrupo';
	import { buscadorGrupos } from '@/store_pinia/buscadorGrupos';
	import { filtroGruposStore } from '@/store_pinia/filtros/filtroGrupos';
	import { appGlobalStore } from '@/store_pinia/app';
	import TripCard from '@/components/card/TripCard/TripCard.vue';

	const festival = ref();
	const store_pinia_festivales = festivalesStore();
	const route = useRoute();

	const uri = route.params.uri;

	const isMobile = ref(window.innerWidth <= 1033);

	const middlePhotoIndex = computed(() => {
		if (festival.value.imagenesCarrusel) {
			return Math.floor(festival.value.imagenesCarrusel.length / 2);
		} else {
			return 0;
		}
	});

	const handleResize = () => {
		isMobile.value = window.innerWidth <= 1033;
	};
	onMounted(() => {
		window.addEventListener('resize', handleResize);
	});

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});

	store_pinia_festivales.setFestival(uri).then(() => {
		festival.value = store_pinia_festivales.festival;
	});

	const posicion = computed(() => {
		return {
			lat: festival.value.latitud,
			lng: festival.value.longitud,
		};
	});

	const dateFromToFestival = computed(() => {
		const dateFromFestival = formatDatesFullMonth(festival.value.fechaInicio);
		const dateToFestival = formatDatesFullMonth(festival.value.fechaFin);
		const yearFinishFestival = getFullYearDate(festival.value.fechaFin);
		return `${titleCase(dateFromFestival)} - ${titleCase(dateToFestival)} ${yearFinishFestival}`;
	});

	const viajesGruposStore = viajesGrupos();
	const buscador = buscadorGrupos();
	const viajesGrupoFiltroStore = filtroGruposStore();
	const storeGlobal = appGlobalStore();

	const viajesAux = ref([]);
	const getListaViajesGrupoCatalogo = async (area = null, destino, nombreGrupo) => {
		const listaFiltroDestino = buscador.filtrosViajes.destino;
		const listaFiltroGrupos = buscador.filtrosViajes.tiposGrupo;

		const idArea = obtenerIdAreaPorNombre(area, listaFiltroDestino);
		const idEstacion = obtenerIdEstacionPorNombre(destino, listaFiltroDestino);
		const idGrupo = obtenerIdGrupoPorNombre(nombreGrupo, listaFiltroGrupos);
		const fechaInicioViaje = route.query.fechaInicio;
		const fechaFinViaje = route.query.fechaFin;
		const nombreFestival = route.query.festival;

		ViajesGrupoApiService.getViajes(idArea, idEstacion, idGrupo, fechaInicioViaje, fechaFinViaje)
			.then(listaViajesTotal => {
				viajesGruposStore.setViajesGruposTotal(listaViajesTotal).then(() => {
					viajesGrupoFiltroStore.setViajesFiltradosInicial(filtrarViajesPorFestival(nombreFestival, listaViajesTotal));
					viajesGrupoFiltroStore.setFiltroFestivales([{ nombreFestival: festival.value.titulo }]);
					viajesGrupoFiltroStore.aplicarFiltros();

					const todosLosViajes = [].concat.apply([], Object.values(viajesGrupoFiltroStore.viajesFiltradosAplicado));

					viajesAux.value = todosLosViajes;
				});
			})
			.finally(() => {
				storeGlobal.loadingGlobal = false;
			});
	};
	buscador.setFilterInformation().then(() => {
		getListaViajesGrupoCatalogo(null, route.query.lugarExperiencia, route.query.grupo);
	});
	storeGlobal.loadingGlobal = true;

	buscador.setFilterInformation().then(() => {
		getListaViajesGrupoCatalogo(null, route.query.lugarExperiencia, route.query.grupo);
	});

	const goEntradaFestival = () => {
		router.push({ name: FestivalesCatalogo, params: { uri: uri } });
	};

	const goAbonoFestival = () => {
		router.push({
			path: '/grupos/viajes/4548',
			query: {
				codigo: 'ABONOSPOLAR2024',
			},
		});
	};

	const goMoodFestival = () => {
		router.push({
			name: GruposReservaFormularioUnirse,
			params: {
				codigoGrupo: 'uu-65973de1b56cb',
				viajeId: '3934',
			},
			query: {
				codigo: route.query.codigo,
			},
		});
	};

	const goBuscadorFestival = () => {
		router.push({
			name: 'GruposCatalogo',
			query: {
				festival: festival.value.titulo,
			},
		});
	};

	const pantalla_grande = computed(() => {
		return window.screen.availWidth > 1536;
	});

	const movil = computed(() => {
		return window.screen.availWidth <= 575;
	});
</script>

<style lang="scss" scoped>
	.btn-secondary {
		font-size: 0.9rem;
		border-radius: var(--bs-border-radius-xl);
	}

	.festivales-detalle {
		background-color: white;
		min-height: 40vmax;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}

	@media (max-width: 768px) {
		.festivales-detalle {
			min-height: 75vmax;
		}
	}

	.festival-img-fondo {
		min-height: 90vh;
		background-size: cover;
		background-position: top;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.imagenes-secundarias-festival {
		margin-top: -11vmax;
	}

	@media (max-width: 575px) {
		.festival-img-fondo {
			min-height: 65vh;
		}
	}

	.titulo-descripcion {
		font-weight: 700;
		font-size: 1.2rem;
		opacity: 0.7;
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.descripcion-festival {
		font-size: 0.95rem;
	}

	.ubicacion-festival {
		font-size: 1.3rem;
		margin: 0px;
		font-weight: 700;
		opacity: 0.7;
	}

	.titulo {
		font-weight: 800;
	}

	.subtitulo {
		font-weight: 800;
	}

	.img-carousel {
		border-radius: var(--bs-border-radius-xl);
		width: 280px;
		height: 180px;
		background-size: cover;
		padding-right: 100px;
		background-position: center;
	}

	@media (max-width: 575px) {
		.img-carousel {
			width: 180px;
			height: 130px;
			margin-top: 60px;
		}
	}

	.titulo-lineup {
		font-weight: 700;
	}

	.fecha-festival {
		font-weight: 800;
	}

	.titulo-sponsors {
		font-weight: 700;
	}

	.img-carousel-lineup {
		width: 100%;
		padding: 20px;
	}

	.ver-mapa {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	@media (min-width: 576px) {
		//a partir de sm
		.festivales-detalle {
			padding-top: 5rem;
		}
	}

	@media (min-width: 1537px) {
	}
</style>
../../../router/RouterNames../../../router../../../services/api/skiandnight/EventosApiService
