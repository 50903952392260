<template>
	<div class="row d-flex flex-column flex-md-row">
		<div
			v-for="(servicios, subtipo) in serviciosBySubtipo"
			:key="subtipo"
			class="col-12 mb-3"
			:class="getClassServicio()">
			<div class="input-group mb-1">
				<label
					v-if="categoriaServicios.categoria === 'seguro'"
					:for="'subtipo-' + subtipo"
					class="w-100 text-primary mb-2 fw-bold">
					{{ $t('servicios.seguro.subtipo.' + subtipo) }}
				</label>
				<select
					:id="'subtipo-' + subtipo"
					v-model="serviciosBySubtipoSeleccionados[subtipo]"
					class="form-select selector-servicio text-primary"
					:disabled="!isServicioChangeable(reserva, serviciosBySubtipoSeleccionados[subtipo])"
					@change="serviciosBySubtipoSeleccionados[subtipo].justSelected = true">
					<option
						v-for="servicio in servicios.filter(s => s.activo)"
						:key="servicio.id"
						:value="servicio"
						:disabled="!isServicioSelectable(reserva, servicio)">
						<!-- PROVISIONAL: Comentado a peticion -->
						<!-- <span v-if="!servicio.nulo && servicio.tipo !== 'seguro' && servicio.tipo !== 'festival'">
							{{ servicio.numDias }}
							<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span> -->
						{{ servicio.nombre }}
						<span>
							<span v-if="!servicio.nulo && subtipo !== 'consumicion' && subtipo !== 'complemento'">
								( {{ $t('general.del') }}
								{{ dateMonthNumbers(servicio.fechaInicio) }}
								{{ $t('general.al') }}
								{{ dateMonthNumbers(servicio.fechaFin) }})
							</span>
							<span v-if="servicio.precio > 0">(+</span>
							<span v-if="servicio.precio">
								<span v-if="servicio.precio < 0">(</span>
								{{ servicio.precio }}€)
							</span>
						</span>
					</option>
				</select>
			</div>

			<div class="row">
				<div class="row text-primary mb-2 description-text">
					<template v-if="serviciosBySubtipoSeleccionados[subtipo] && !serviciosBySubtipoSeleccionados[subtipo].nulo">
						<div class="col-8">
							{{ serviciosBySubtipoSeleccionados[subtipo].descripcion }}
						</div>
					</template>
					<div v-if="categoriaServicios.categoria === 'seguro'" class="col text-end">
						<button-open-pdf
							button-text="Ver Poliza"
							:id-servicio="serviciosBySubtipoSeleccionados[subtipo].id"
							:id-viaje="viajeId"
							class="btn-poliza" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { firstLetterCapital } from '@/helpers/languageUtils';
	import { isServicioSelectable, isServicioChangeable } from '@/helpers/serviciosUtils';
	import { ref, reactive, watch, onBeforeMount } from 'vue';
	import buttonOpenPdf from '../../../components/buttons/buttonOpenPdf.vue';
	import { useRoute } from 'vue-router';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();

	const reserva = storeHabitacion.getReservaByIdx(props.idxReserva);

	const serviciosBySubtipoSeleccionados = reactive({});

	const subtipos = reactive([]);

	const serviciosBySubtipo = reactive({});

	const sumaServicios = ref(0);

	// todo: get this from pinia store
	const viajeId = useRoute().params.viajeId;

	function addServiciosToReserva() {
		subtipos.map(subtipo => {
			storeHabitacion.addServicioToReserva(props.idxReserva, serviciosBySubtipoSeleccionados[subtipo]);
		});
		emits('servicioSeleccionado', { precio: sumaServicios.value, ...serviciosBySubtipoSeleccionados });
	}

	function getClassServicio() {
		if (serviciosBySubtipo) {
			if (Object.keys(serviciosBySubtipo).length === 1) {
				return 'col-md-12';
			} else if (Object.keys(serviciosBySubtipo).length === 2) {
				return 'col-md-6';
			}
			return 'col-md-4';
		}
	}

	function init() {
		// All the subtipos are gathered
		props.categoriaServicios.servicios.map(({ subtipo }) => {
			if (subtipos.indexOf(subtipo) === -1) {
				subtipos.push(subtipo);
			}
		});

		// The servicios are gathered by subtipo
		subtipos.forEach(subtipo => {
			serviciosBySubtipo[subtipo] = props.categoriaServicios.servicios.filter(servicio => subtipo === servicio.subtipo);
			let categoriaSubtipo = `${props.categoriaServicios.categoria}${firstLetterCapital(subtipo)}`;
			let serviciosSubtipo = serviciosBySubtipo[subtipo];
			let reservaCurrentServicio = reserva.servicios[categoriaSubtipo];
			// If the booking does not have any service of this category needs to be initialised
			if (reservaCurrentServicio === undefined) {
				let servicioPaqueteBasico = serviciosSubtipo.find(({ paqueteBasico }) => paqueteBasico);
				if (servicioPaqueteBasico) {
					serviciosBySubtipoSeleccionados[subtipo] = servicioPaqueteBasico;
				} else {
					serviciosBySubtipoSeleccionados[subtipo] = serviciosSubtipo.find(({ nulo }) => nulo);
				}
			} else {
				// Otherwise the current value is load
				serviciosBySubtipoSeleccionados[subtipo] = reservaCurrentServicio;
			}
		});
		addServiciosToReserva();
	}

	watch(serviciosBySubtipoSeleccionados, () => {
		sumaServicios.value = subtipos.reduce(
			(accum, subtipo) => accum + serviciosBySubtipoSeleccionados[subtipo].precio,
			0
		);
		addServiciosToReserva();
	});

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.btn-poliza {
		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}
</style>
