<template>
	<template v-if="storeGlobal.isSeaAndNightUrl">
		<div>
			<BannerTopVideoPage is-home class="position-relative" />
			<div>
				<CardsViajesSelector :grupos-viajes="tiposViajesGrupo" class="mt-5 mb-3" />
				<MejoresExperienciasCarousel :experiencias-list="experiencias.festivales" />

				<PorqueReservar2 />
			</div>
		</div>
	</template>
	<template v-else>
		<div>
			<BannerTopPage>
				<div class="home-view banner-home row container text-white">
					<div class="col-12 mt-5">
						<h2 class="banner-home__title text-white relative-position">
							{{ $t('home.banner.title1') }}
							<br />
							{{ $t('home.banner.title2') }}
						</h2>
						<div class="banner-home__subtitle">
							{{ $t('home.banner.subtitle') }}
						</div>
					</div>
				</div>
			</BannerTopPage>

			<div class="container">
				<CardsViajesGrupo :grupos-viajes="tiposViajesGrupo" />

				<MejoresOfertasCarousel :viajes-destacados-list="viajesDestacados" />

				<EmbedYoutube />

				<ApresSkiCarousel :apres-skis-list="apresSkis" />

				<PorqueReservar />

				<Sponsors :sponsors="sponsorsList" />
			</div>
		</div>
	</template>
</template>

<script setup>
	import { computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import Cookies from 'js-cookie';
	import viajesGrupoStoreModule from '@/store_pinia/viajes/viajesGrupo';
	import apresSkiStoreModule from '@/store_pinia/viajes/apresSki';
	import miscellaneousStoreModule from '@/store_pinia/miscellaneous';
	import { festivalesStore } from '@/store_pinia/festivales';
	import { appGlobalStore } from '@/store_pinia/app';
	import BannerTopPage from '@components/BannerTopPage.vue';
	import BannerTopVideoPage from '@components/BannerTopVideoPage.vue';
	import ApresSkiCarousel from '@components/Carousel/ApresSkiCarousel.vue';
	import MejoresExperienciasCarousel from '@components/Carousel/MejoresExperienciasCarousel.vue';
	import MejoresOfertasCarousel from '@components/Carousel/MejoresOfertasCarousel.vue';
	import CardsViajesGrupo from '@components/home/CardsViajesGrupo.vue';
	import CardsViajesSelector from '@components/home/CardsViajesSelector.vue';
	import EmbedYoutube from '@components/misc/EmbedYoutube.vue';
	import Sponsors from '@components/SponsorsSection.vue';
	import PorqueReservar from '@components/misc/PorqueReservar.vue';
	import PorqueReservar2 from '@components/misc/PorqueReservar2.vue';
	import UsuarioApiService from '@/services/api/skiandnight/UsuarioApiService';

	const route = useRoute();
	const storeGlobal = appGlobalStore();
	const apresSkiStore = apresSkiStoreModule();
	const miscellaneousStore = miscellaneousStoreModule();
	const viajesGrupoStore = viajesGrupoStoreModule();
	const experiencias = festivalesStore();

	const apresSkis = computed(() => apresSkiStore.getApresSki);
	const viajesDestacados = computed(() => viajesGrupoStore.getViajesDestacados);
	const tiposViajesGrupo = computed(() => viajesGrupoStore.getTiposViajesGrupo);
	const sponsorsList = computed(() => miscellaneousStore.getSponsors);

	onMounted(() => {
		if (route.query.comercialId) {
			UsuarioApiService.addAccesoLink(route.query.comercialId);
			Cookies.set('comercialId', route.query.comercialId, { expires: 2 });
		}
	});
</script>

<style>
	.home-view .banner-home {
		max-width: 800px;
		margin: auto;
		padding-top: 56px;
	}

	.home-view .banner-home__title {
		margin-bottom: 1rem;
		font-size: 40px !important;
	}
</style>
