<template>
	<div>
		<div class="container-buscador">
			<p class="mb-0 p-0">
				<span class="fw-bold">{{ $t('searcher.destiny.title') }}:</span>
				{{ valoresFiltroBuscadorParaTi.destino.nombre }}
			</p>
			<p class="mb-0 p-0">
				<span class="fw-bold">{{ $t('alojamiento.fechaAlojamiento') }} :</span>
				{{ formatArrayDates(valoresFiltroBuscadorParaTi.fechasAlojamiento) }}
			</p>
			<p class="mb-0 p-0">
				<span class="fw-bold">{{ $t('general.fecha') }} de Forfait :</span>
				{{ formatArrayDates(valoresFiltroBuscadorParaTi.fechasForfait) }}
			</p>
			<p class="mb-0 p-0">
				<span v-sanitize-html="getCantidadPersonasHTML(valoresFiltroBuscadorParaTi.habitaciones)" />
				<span>
					{{ valoresFiltroBuscadorParaTi.habitaciones.length }}
					{{ $t('general.habitacion') }}
					<span v-if="valoresFiltroBuscadorParaTi.habitaciones.length > 1"> es </span>
				</span>
			</p>
		</div>
	</div>

	<!-- Modal -->
</template>

<script>
	import { defineComponent, computed } from 'vue';
	import { buscadorStore } from '@/store_pinia/buscador';

	export default defineComponent({
		setup() {
			const store_buscador = buscadorStore();

			const valoresFiltroBuscadorParaTi = computed(() => store_buscador.filtrosSeleccionadosBuscador);

			function getDayName(dateStr, locale) {
				var date = new Date(dateStr);
				return date.toLocaleDateString(locale, { weekday: 'long' });
			}

			const formatArrayDates = listaDates => {
				//   let nameList = ["lu", "ma", "mi", "ju", "vi", "sa", "do"];
				let monthList = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'dic'];
				let formatDates = '';
				listaDates.forEach((date, index) => {
					let dateAux = `${getDayName(date, 'es-Es')}, ${new Date(date).getDate()} ${
						monthList[new Date(date).getMonth()]
					}`;

					if (listaDates.length - 1 == index) {
						formatDates += ` -  ${dateAux}`;
					} else {
						formatDates += dateAux;
					}
				});

				return formatDates;
			};

			const getCantidadPersonasHTML = listaHabitaciones => {
				let cantidadAdultos = 0;
				let cantidadNinos = 0;
				listaHabitaciones.forEach(({ habitacion }) => {
					habitacion.forEach(edad => {
						if (edad > 17) {
							cantidadAdultos++;
						} else {
							cantidadNinos++;
						}
					});
				});

				if (cantidadNinos > 0) {
					return `<span> ${cantidadAdultos} adultos y ${cantidadNinos} niños ,</span>`;
				}

				return `<span> ${cantidadAdultos} adultos ,</span>`;
			};

			return {
				valoresFiltroBuscadorParaTi,
				formatArrayDates,
				getCantidadPersonasHTML,
			};
		},
	});
</script>

<style scoped>
	.container-buscador {
		font-size: 11px;
		border-radius: var(--bs-border-radius-lg);
	}
</style>
