<template>
	<div class="tabla-rooming">
		<input
			v-model="searchQuery"
			type="text"
			class="search-input mb-3"
			:placeholder="$t('rooming.tabla.buscarNombre')" />
		<table class="table table-hover">
			<thead>
				<tr>
					<th class="sortable" @click="sortTable('nombre')">
						{{ t('rooming.tabla.nombre') }} <i :class="getSortIcon('nombre')" class="sort-icon"></i>
					</th>
					<th class="sortable d-none d-md-table-cell" @click="sortTable('apellidos')">
						{{ t('rooming.tabla.apellidos') }} <i :class="getSortIcon('apellidos')" class="sort-icon"></i>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="3">
						<div class="section-toggle" @click="toggleSinRepartir()">
							<i :class="isSinRepartirVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
							<span class="ms-2 section-title"
								>{{ t('rooming.tabla.sinRepartir') }} ({{ filteredSinRepartir.length }}/{{
									props.usuarios.length
								}})</span
							>
						</div>
					</td>
				</tr>
				<tr
					v-for="user in sortedSinRepartir"
					v-show="isSinRepartirVisible"
					:key="user.id"
					:class="{
						clickable: editMode,
					}"
					@click="seleccionarUsuario(user)">
					<td class="d-md-none">
						<div class="user-info">
							<span>{{ user.nombre }} {{ user.apellidos }}</span>
						</div>
					</td>
					<td class="d-none d-md-table-cell">{{ user.nombre }}</td>
					<td class="d-none d-md-table-cell">{{ user.apellidos }}</td>
				</tr>
				<tr>
					<td colspan="3">
						<div class="section-toggle" @click="toggleRepartidos()">
							<i :class="isRepartidosVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
							<span class="ms-2 section-title"
								>{{ t('rooming.tabla.repartidos') }} ({{ filteredRepartidos.length }}/{{ props.usuarios.length }})</span
							>
						</div>
					</td>
				</tr>
				<tr
					v-for="user in sortedRepartidos"
					v-show="isRepartidosVisible"
					:key="user.id"
					:class="{
						nonclickable: !editMode,
						'repartido-row': true,
					}">
					<td class="d-md-none">
						<div class="user-info">
							<span>{{ user.nombre }} {{ user.apellidos }}</span>
						</div>
					</td>
					<td class="d-none d-md-table-cell">{{ user.nombre }}</td>
					<td class="d-none d-md-table-cell">{{ user.apellidos }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup>
	import { ref, computed, nextTick } from 'vue';
	import { useI18n } from 'vue-i18n';

	const { t } = useI18n();

	const props = defineProps({
		usuarios: {
			type: Array,
			required: true,
		},
		isEditing: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const emit = defineEmits(['seleccionar-usuario']);

	const searchQuery = ref('');
	const sortKey = ref('');
	const sortOrders = ref({
		nombre: 1,
		apellidos: 1,
	});

	const editMode = computed(() => {
		return props.isEditing;
	});

	const sinRepartir = computed(() => {
		return props.usuarios.filter(user => !user.asignado);
	});

	const repartidos = computed(() => {
		return props.usuarios.filter(user => user.asignado);
	});

	const filteredSinRepartir = computed(() => {
		return sinRepartir.value.filter(user => {
			const fullName = `${user.nombre.toLowerCase()}`;
			return fullName.includes(searchQuery.value.toLowerCase());
		});
	});

	const filteredRepartidos = computed(() => {
		return repartidos.value.filter(user => {
			const fullName = `${user.nombre.toLowerCase()}`;
			return fullName.includes(searchQuery.value.toLowerCase());
		});
	});

	const sortedSinRepartir = computed(() => {
		return [...filteredSinRepartir.value].sort((a, b) => {
			const order = sortOrders.value[sortKey.value] || 1;
			if (a[sortKey.value] < b[sortKey.value]) return -1 * order;
			if (a[sortKey.value] > b[sortKey.value]) return 1 * order;
			return 0;
		});
	});

	const sortedRepartidos = computed(() => {
		return [...filteredRepartidos.value].sort((a, b) => {
			const order = sortOrders.value[sortKey.value] || 1;
			if (a[sortKey.value] < b[sortKey.value]) return -1 * order;
			if (a[sortKey.value] > b[sortKey.value]) return 1 * order;
			return 0;
		});
	});

	const isSinRepartirVisible = ref(true);
	const isRepartidosVisible = ref(true);

	function toggleSinRepartir() {
		isSinRepartirVisible.value = !isSinRepartirVisible.value;
	}

	function toggleRepartidos() {
		isRepartidosVisible.value = !isRepartidosVisible.value;
	}

	function getSortIcon(key) {
		if (sortKey.value === key) {
			return sortOrders.value[key] === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down';
		}
		return 'fa fa-arrow-up text-transparent';
	}

	function sortTable(key) {
		if (sortKey.value === key) {
			sortOrders.value[key] = sortOrders.value[key] * -1;
		} else {
			sortKey.value = key;
			sortOrders.value[key] = 1;
		}
	}

	function seleccionarUsuario(user) {
		if (!user.asignado && editMode.value == true) {
			emit('seleccionar-usuario', user);
			nextTick();
		}
	}
</script>

<style scoped lang="scss">
	.tabla-rooming {
		width: 100%;
	}

	.search-input {
		width: 100%;
		height: 30px;
		text-align: left;
		border-radius: var(--bs-border-radius-xl);
		background: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
		border: none;
		padding-left: 10px;
		margin-bottom: 10px;
		color: rgba(var(--bs-primary-rgb), 0.6);
		font-weight: normal;

		@media (max-width: 768px) {
			font-size: 13px;
		}
	}

	.search-input::placeholder {
		color: rgba(var(--bs-primary-rgb), 0.6);
		opacity: 0.68;
	}

	.sortable {
		cursor: pointer;
		color: rgba(var(--bs-primary-rgb), 0.6);
		opacity: 0.68;
		font-weight: 600;

		@media (max-width: 768px) {
			font-size: 13px;
		}
	}

	.clickable {
		cursor: pointer;
	}

	.nonclickable {
		cursor: not-allowed;
	}

	.repartido-row {
		background-color: transparent !important;
		opacity: 0.5;
	}

	.section-toggle {
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		color: rgba(var(--bs-primary-rgb), 0.6);
		opacity: 0.68;
		font-weight: 600;
	}

	.section-title {
		font-size: 16px;
		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	.section-toggle i {
		margin-right: 10px;
	}

	.card-alojamiento::-webkit-scrollbar {
		width: 8px;
	}

	.card-alojamiento::-webkit-scrollbar-thumb {
		border-radius: var(--bs-border-radius-sm);
		background: rgba(var(--bs-primary-rgb), 0.2);
	}

	td {
		color: var(--bs-primary) !important;
	}

	th {
		color: rgba(var(--bs-primary-rgb), 0.6);
		opacity: 0.68;
		font-weight: 600;
	}

	.sort-icon {
		font-size: 12px;
	}

	@media (max-width: 768px) {
		.sortable.d-md-none,
		.d-none.d-md-table-cell {
			display: none !important;
		}

		.user-info span {
			display: block;
			@media (max-width: 768px) {
				font-size: 13px;
			}
		}

		.user-info .d-block {
			color: rgba(var(--bs-primary-rgb), 0.6);
			font-size: 12px;
		}
	}
</style>
