<template>
	<div class="mis-reservas">
		<h1 class="text-center mb-5">¡Bienvenid@, {{ informacionUsuario.usuario.nombre }} !</h1>
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6 mt-3 px-4">
					<div class="row align-content-between h-100 section-card p-4">
						<div class="col-12">
							<h3 class="text-center">¿{{ $t('reserva.disponeCodReserva') }}?</h3>
							<p class="pt-2 text-center">
								{{ $t('reserva.msjAmigoReservado') }}
							</p>
						</div>
						<a href="/es/usuario/codigo_reserva" class="btn btn-blue">{{ $t('botones.unirse').ToUpperCase() }}:</a>
					</div>
				</div>
				<div class="col-12 col-md-6 mt-3 px-4">
					<div class="row align-content-between h-100 section-card p-4">
						<div>
							<h3 class="text-center">¿{{ $t('reserva.msjFiesta') }}?</h3>
							<p class="pt-2 pb-2 text-center">
								{{ $t('reserva.msjNoFiesta') }}
							</p>
						</div>
						<a href="#" class="btn btn-blue" data-toggle="modal" data-target="#reservarFiestaModal">
							{{ $t('botones.reservaPackFiesta').ToUpperCase() }}:
						</a>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<h3 class="text-center mt-4 mb-4">
					{{ $t('reserva.proximosViajes') }}
				</h3>
				<div class="table-responsive">
					<table class="table table-striped">
						<thead>
							<tr>
								<th style="width: 10%" class="text-center">Detalles/Pago</th>
								<th style="width: 35%">
									{{ $t('general.experiencia') }}
								</th>
								<th style="width: 15%">Nombre del grupo</th>
								<th style="width: 5%" class="text-center">
									{{ $t('reserva.precio') }}
								</th>
								<th style="width: 15%" class="text-center">
									{{ $t('reserva.pendientePago') }}
								</th>
								<th style="width: 10%" class="text-center">
									{{ $t('subtitulo.codigoReserva') }}
								</th>
								<th style="width: 10%" class="text-center">
									{{ $t('general.cancelacion') }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="7" />
							</tr>
							<tr class="cancelada">
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2844/recuperar" role="button">
											{{ $t('botones.recuperar').toUpperCase() }}
										</a>
									</div>
								</td>
								<td>
									<div>Andorra 4 Diciembre - 8 Diciembre</div>
								</td>
								<td><div>PRUEBA23</div></td>
								<td class="text-center">
									<div>647.00€*</div>
								</td>
								<td class="text-center">
									<div>467.00€</div>
								</td>
								<td class="text-center">
									<div>ear5f8def61a6946</div>
								</td>
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2844/recuperar" role="button" :title="$t('reserva.recuperarReserva')">
											{{ $t('botones.recuperar').toUpperCase() }}
										</a>
									</div>
								</td>
							</tr>

							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2846" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td><div>16 Diciembre - 18 Diciembre</div></td>
								<td><div>CAMBIO HABITA</div></td>
								<td class="text-center">
									<div>637.00€*</div>
								</td>
								<td class="text-center">
									<div>0.00€</div>
								</td>
								<td class="text-center">
									<div>HgI5fa84443edb50</div>
								</td>
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2846/cancelar" role="button" :title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>

							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2845" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td>
									<div>Andorra 4 Diciembre - 8 Diciembre</div>
								</td>
								<td><div>cambiooooo</div></td>
								<td class="text-center">
									<div>1,070.90€*</div>
								</td>
								<td class="text-center">
									<div>-13,179.10€</div>
								</td>
								<td class="text-center">
									<div>gem5fa7dd3504fa3</div>
								</td>
								<td class="text-center">
									<div>
										<a href="/es/usuario/reserva/2845/cancelar" role="button" :title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<small> * A este precio habría que sumarle el incremento por plazas libres en caso de que aplique. </small>
				</div>
			</div>
			<div class="row mt-5 mb-5">
				<h3 class="text-center mt-4 mb-4">{{ $t('reserva.proximosViajes') }} a medida</h3>

				<div class="table-responsive">
					<table class="table table-striped">
						<thead>
							<tr>
								<th class="text-center">Detalles/Pago</th>
								<th style="width: 35%">
									{{ $t('general.experiencia') }}
								</th>
								<th class="text-center">
									{{ $t('reserva.precio') }}
								</th>
								<th class="text-center">
									{{ $t('reserva.pendientePago') }}
								</th>
								<th class="text-center">
									{{ $t('general.cancelacion') }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/23" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>PIRINEO ARAGONES 18 Enero - 20 Enero</div>
								</td>
								<td class="relleno text-center">
									<div>293.00€</div>
								</td>
								<td class="relleno text-center">
									<div>0.00€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/23"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/28" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>ANDORRA 16 Marzo - 18 Marzo</div>
								</td>
								<td class="relleno text-center">
									<div>296.00€</div>
								</td>
								<td class="relleno text-center">
									<div>0.00€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/28"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/29" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>ANDORRA 16 Marzo - 18 Marzo</div>
								</td>
								<td class="relleno text-center">
									<div>373.00€</div>
								</td>
								<td class="relleno text-center">
									<div>261.10€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/29"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/30" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>ANDORRA 16 Marzo - 18 Marzo</div>
								</td>
								<td class="relleno text-center">
									<div>246.00€</div>
								</td>
								<td class="relleno text-center">
									<div>0.00€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/30"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/36" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>ANDORRA 23 Diciembre - 25 Diciembre</div>
								</td>
								<td class="relleno text-center">
									<div>494.00€</div>
								</td>
								<td class="relleno text-center">
									<div>345.80€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/36"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/37" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>ANDORRA 23 Diciembre - 26 Diciembre</div>
								</td>
								<td class="relleno text-center">
									<div>542.00€</div>
								</td>
								<td class="relleno text-center">
									<div>379.40€</div>
								</td>
								asdasd
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/37"
											role="button"
											:title="$t('botones.cancelarReserva')">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="7" />
							</tr>
							<tr>
								<td class="relleno text-center">
									<div>
										<a class="" href="/es/usuario/reserva_custom/48" role="button">
											{{ $t('reserva.miViaje').toUpperCase() }}
										</a>
									</div>
								</td>
								<td class="relleno">
									<div>SIERRA NEVADA 13 Enero - 17 Enero</div>
								</td>
								<td class="relleno text-center">
									<div>1,040.00€</div>
								</td>
								<td class="relleno text-center">
									<div>728.00€</div>
								</td>
								<td class="relleno text-center">
									<div>
										<a
											class=""
											href="/es/usuario/confirmar_cancelar_reserva_externa/48"
											role="button"
											title="Cancelar reserva">
											{{ $t('botones.cancelar') }}
										</a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { computed } from '@vue/reactivity';
	import { useStore } from 'vuex';
	export default {
		name: 'MisReservas',
		components: {},
		setup() {
			const store = useStore();

			const informacionUsuario = computed(() => store.state.login);
			return {
				informacionUsuario,
			};
		},

		data() {
			return {};
		},

		mounted() {},
	};
</script>

<style lang="scss" scoped>
	.mis-reservas {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)),
			url('~@/assets/fotos/bola-cristal-background.webp');
		min-height: 80vh;
		background-size: cover;
		background-position: center;
		color: var(--bs-primary);
		font-weight: 900;
		padding-top: 160px;
	}

	.section-card {
		background-color: #ffffff;
		h2 {
			font-weight: bold;
		}
		p {
			margin: 24px 0;
		}
		button {
			margin: 0 auto;
			display: block;
		}
	}

	//TODO esto deben ser clases globales
	.btn {
		border-radius: 0;
		display: block;
		text-align: center;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 0.875rem;
		line-height: 1.5;
		width: fit-content;
		margin: 0 auto;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out;
	}

	.btn-blue {
		background-color: var(--bs-primary);
		color: #fff;
	}
</style>
