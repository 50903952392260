<template>
	<div
		id="destinosModalParaTi"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="destinosModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header pb-1">
					<p id="destinosModalLabel" class="modal-title fs-5">
						{{ $t('searcher.destiny.title') }}
					</p>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body pt-0">
					<div v-if="filtrosBuscador" class="column justify-content-around py-2">
						<div v-for="(area, index) in filtrosBuscador.areas" :key="index">
							<div
								class="fw-bold text-primary text-clickable"
								data-bs-dismiss="modal"
								@click="setDestino(area.nombre, area.id, -1)">
								{{ titleCase(area.nombre) }}
							</div>
							<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
								<div
									:id="lugarExperiencia.id"
									class="buscador-subtitulo p-1 text-clickable"
									:value="lugarExperiencia.nombre"
									data-bs-dismiss="modal"
									@click="setDestino(lugarExperiencia.nombre, area.id, lugarExperiencia.id)">
									{{ titleCase(lugarExperiencia.nombre) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		id="buscador-para-ti"
		class="container buscador text-start justify-content-center"
		:class="landing ? 'width-buscador-sin-forfait' : 'width-buscador-con-forfait'">
		<div class="row justify-content-between align-items-center w-100">
			<div class="buscador-col section-search p-0" :class="landing ? 'col-3' : 'col-2'">
				<div class="dropdown">
					<div id="destiny" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="buscador-titulo" :class="errors.destino ? 'error' : ''">
							{{ $t('searcher.destiny.title') }}
						</div>
						<div class="buscador-subtitulo text-muted" :class="errors.destino ? 'error' : ''">
							<template v-if="filtrosSeleccionadosBuscador.destino.nombre">
								{{ titleCase(filtrosSeleccionadosBuscador.destino.nombre) }}
							</template>
							<template v-else>
								{{ $t('searcher.destiny.subtitle') }}
							</template>
						</div>
					</div>
					<ul class="dropdown-menu dropdown-destino px-4" style="min-width: 320px" aria-labelledby="destiny">
						<div v-if="filtrosBuscador" class="row justify-content-start py-2">
							<div v-for="(area, index) in filtrosBuscador.areas" :key="index" class="col-6">
								<div class="fw-bold text-primary text-clickable" @click="setDestino(area.nombre, area.id, -1)">
									{{ titleCase(area.nombre) }}
								</div>
								<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
									<div
										class="buscador-subtitulo text-clickable"
										@click="setDestino(lugarExperiencia.nombre, area.id, lugarExperiencia.id)">
										{{ titleCase(lugarExperiencia.nombre) }}
									</div>
								</div>
							</div>
						</div>
					</ul>
				</div>
			</div>

			<div class="buscador-col section-search" :class="landing ? 'col-4 px-0' : 'col-3 ps-3'">
				<div id="datesAccomodation" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<div class="buscador-titulo" :class="errors.fechasAlojamiento ? 'error' : ''">
						{{ $t('searcher.datesAccomodation.title') }}
					</div>
					<div class="buscador-subtitulo text-muted" :class="errors.fechasAlojamiento ? 'error' : ''">
						<template v-if="filtrosSeleccionadosBuscador.fechasAlojamiento">
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[0]) }}
							-
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[1]) }}
						</template>
						<template v-else>
							{{ $t('searcher.datesAccomodation.subtitle') }}
						</template>
					</div>
				</div>
				<ul class="dropdown-menu p-3" aria-labelledby="datesAccomodation">
					<div class="position-relativa">
						<Datepicker
							v-model="filtrosSeleccionadosBuscador.fechasAlojamiento"
							locale="es"
							inline
							range
							multi-calendars
							auto-apply
							:min-date="tomorrowAux" />
					</div>
				</ul>
			</div>

			<div v-if="!landing" class="col-3 buscador-col section-search ps-3">
				<div id="datesForfait" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<div class="buscador-titulo" :class="errors.fechasForfait ? 'error' : ''">
						{{ $t('searcher.datesForfait.title') }}
					</div>
					<div class="buscador-subtitulo" :class="errors.fechasForfait ? 'error' : ''">
						<span v-if="filtrosSeleccionadosBuscador.fechasForfait" class="text-muted">
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[0]) }}
							-
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[1]) }}
						</span>
						<span v-else class="text-muted">
							{{ $t('searcher.datesForfait.subtitle') }}
						</span>
					</div>
				</div>
				<ul class="dropdown-menu p-3" aria-labelledby="datesForfait">
					<div>
						<Datepicker
							v-model="filtrosSeleccionadosBuscador.fechasForfait"
							:min-date="new Date(filtrosSeleccionadosBuscador.fechasAlojamiento[0])"
							:max-date="new Date(filtrosSeleccionadosBuscador.fechasAlojamiento[1])"
							locale="es"
							inline
							range
							multi-calendars
							auto-apply />
					</div>
				</ul>
			</div>

			<div class="buscador-col p-0" :class="landing ? 'col-3 p-0' : 'col-2 ps-3'">
				<div
					id="rooms"
					class=""
					role="button"
					aria-expanded="false"
					data-bs-toggle="modal"
					data-bs-target="#habitacionesBuscadorModal">
					<div class="buscador-titulo text-primary" :class="errors.habitaciones ? 'error' : ''">
						<span v-if="filtrosSeleccionadosBuscador.habitaciones">
							{{ filtrosSeleccionadosBuscador.habitaciones.length }}
						</span>
						{{ $t('searcher.rooms.title') }}
					</div>
					<div class="buscador-subtitulo text-muted" :class="errors.habitaciones ? 'error' : ''">
						<template v-if="filtrosSeleccionadosBuscador.habitaciones">
							{{ cantidadPersonas.adultos }}
							{{ $t('general.personas.plural.adulto') }} ,
							{{ cantidadPersonas.ninos }}
							{{ $t('general.personas.plural.nino') }}
						</template>
						<template v-else>
							{{ $t('searcher.rooms.subtitle') }}
						</template>
					</div>
				</div>
			</div>

			<div class="col-auto buscador-col" :class="landing ? 'px-2' : 'ps-4'" @click="goToViajesAMedida()">
				<div class="goTo lupa hvr-grow pr-0">
					<i class="fa-solid fa-magnifying-glass fa-2x text-primary bg-secondary p-3 rounded-circle pointer" />
				</div>
			</div>
		</div>
	</div>

	<div class="container mobile-buscador text-start justify-content-center box-shadow-buscador">
		<div class="mobile-buscador">
			<div class="input-group mb-3 d-flex flex-column">
				<label for="" class="title-buscador-mobile text-primary">
					{{ $t('searcher.destiny.title') }}
				</label>
				<div
					class="bg-white p-2 rounded-2 d-flex justify-content-between pointer align-items-center"
					data-bs-target="#destinosModalParaTi"
					data-bs-toggle="modal"
					data-bs-dismiss="modal"
					:class="errors.destino ? 'error_select' : ''">
					<span v-if="filtrosSeleccionadosBuscador.destino.nombre" class="title-opcion-mobile">
						{{ titleCase(filtrosSeleccionadosBuscador.destino.nombre) }}
					</span>
					<span v-else class="title-opcion-mobile">{{ $t('searcher.destiny.subtitle') }}</span>
					<i class="fa-solid fa-chevron-down text-primary" />
				</div>
			</div>

			<div class="input-group mb-3 d-flex flex-column">
				<label for="" class="title-buscador-mobile text-primary">
					{{ $t('searcher.datesAccomodation.title') }}
				</label>
				<div
					class="bg-white p-2 rounded-2 d-flex justify-content-between pointer align-items-center"
					data-bs-toggle="modal"
					data-bs-target="#fechasAlojamientoModalMovil"
					:class="errors.fechasAlojamiento ? 'error_select' : ''">
					<span v-if="filtrosSeleccionadosBuscador.fechasAlojamiento" class="title-opcion-mobile">
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[0]) }}
						-
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[1]) }}
					</span>
					<span v-else class="title-opcion-mobile">
						{{ $t('searcher.datesAccomodation.subtitle') }}
					</span>
					<i class="fa-solid fa-chevron-down text-primary" />
				</div>
				<div
					id="fechasAlojamientoModalMovil"
					class="modal fade"
					tabindex="-1"
					aria-labelledby="datesAccomodationLabelMovil"
					aria-hidden="true">
					<div class="modal-dialog modal-dialog">
						<div class="modal-content">
							<div class="modal-header pb-1">
								<p id="datesAccomodationLabelMovil" class="modal-title fs-6">
									{{ $t('searcher.datesAccomodation.title') }}
								</p>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									style="font-size: 0.7rem" />
							</div>
							<div class="modal-body d-flex justify-content-center p-0">
								<Datepicker
									v-model="filtrosSeleccionadosBuscador.fechasAlojamiento"
									locale="es"
									inline
									range
									multi-calendars
									auto-apply
									:min-date="tomorrowAux" />
							</div>
							<div class="modal-footer">
								<button class="btn btn-primary" data-bs-dismiss="modal">
									{{ $t('botones.aceptar') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!landing" class="input-group mb-3 d-flex flex-column">
				<label for="" class="title-buscador-mobile text-primary">
					{{ $t('searcher.datesForfait.title') }}
				</label>
				<div
					class="bg-white p-2 rounded-2 d-flex justify-content-between pointer align-items-center"
					data-bs-toggle="modal"
					data-bs-target="#fechasForfaitModalMovil"
					:class="errors.fechasForfait ? 'error_select' : ''">
					<span v-if="filtrosSeleccionadosBuscador.fechasForfait" class="title-opcion-mobile">
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[0]) }}
						-
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[1]) }}
					</span>
					<span v-else class="title-opcion-mobile">
						{{ $t('searcher.datesForfait.subtitle') }}
					</span>
					<i class="fa-solid fa-chevron-down text-primary" />
				</div>
			</div>

			<div
				id="fechasForfaitModalMovil"
				class="modal fade"
				tabindex="-1"
				aria-labelledby="datesForfaitLabelMovil"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header pb-1">
							<p id="datesForfaitLabelMovil" class="modal-title fs-6">
								{{ $t('searcher.datesForfait.title') }}
							</p>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								style="font-size: 0.7rem" />
						</div>
						<div class="modal-body d-flex justify-content-center p-0">
							<Datepicker
								v-model="filtrosSeleccionadosBuscador.fechasForfait"
								locale="es"
								inline
								range
								multi-calendars
								auto-apply />
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" data-bs-dismiss="modal">
								{{ $t('botones.aceptar') }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				class="input-group mb-3 d-flex flex-column"
				aria-expanded="false"
				data-bs-toggle="modal"
				data-bs-target="#habitacionesBuscadorModal">
				<label for="" class="title-buscador-mobile text-primary">
					<span v-if="filtrosSeleccionadosBuscador.habitaciones">
						{{ filtrosSeleccionadosBuscador.habitaciones.length }}
					</span>
					{{ $t('searcher.rooms.title') }}
				</label>
				<div
					class="bg-white p-2 rounded-2 d-flex justify-content-between pointer align-items-center"
					:class="errors.habitaciones ? 'error_select' : ''">
					<span v-if="filtrosSeleccionadosBuscador.habitaciones" class="title-opcion-mobile">
						{{ cantidadPersonas.adultos }} adultos , {{ cantidadPersonas.ninos }} niños
					</span>
					<span v-else class="title-opcion-mobile">{{ $t('searcher.rooms.subtitle') }}</span>
					<i class="fa-solid fa-chevron-down text-primary" />
				</div>
			</div>

			<div class="input-group d-flex flex-column align-items-center">
				<button class="btn btn-mobile btn-primary w-50" @click="goToViajesAMedida()">
					{{ $t('general.buscar') }}
				</button>
			</div>
		</div>
	</div>

	<div
		id="habitacionesBuscadorModal"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="habitacionesBuscadorModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<ModalHabitacionesBuscador @habitaciones="setHabitaciones" />
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-primary d-none d-lg-block"
						data-bs-dismiss="modal"
						@click="validateHabitaciones()">
						{{ $t('botones.aceptar') }}
					</button>
					<button
						type="button"
						class="btn btn-primary d-block d-lg-none"
						data-bs-dismiss="modal"
						@click="validateHabitaciones()">
						{{ $t('botones.aceptar') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import Datepicker from '@vuepic/vue-datepicker';
	import { ref, computed, watch } from 'vue';
	import { useRoute, useRouter } from 'vue-router';

	import { Buscador } from '@/router/RouterNames';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { formatDateWithWeekDay } from '@/helpers/dateUtiles';
	import { titleCase } from '@/helpers/filters';

	import ModalHabitacionesBuscador from '../modales/ModalHabitacionesBuscador.vue';

	const store_buscador = buscadorStore();
	const route = useRoute();
	const router = useRouter();
	const landing = route.path == '/';
	const today = new Date();
	const tomorrowAux = new Date();
	tomorrowAux.setDate(today.getDate() + 1);

	const filtrosBuscador = computed(() => store_buscador.filtrosBuscador);
	store_buscador.setFiltros();

	const filtrosSeleccionadosBuscador = computed(() => store_buscador.filtrosSeleccionadosBuscador);

	const errors = ref({
		destino: false,
		fechasAlojamiento: false,
		fechasForfait: false,
		habitaciones: false,
	});

	const cantidadPersonas = computed(() => {
		let cantidad = {
			ninos: 0,
			adultos: 0,
		};
		store_buscador.filtrosSeleccionadosBuscador.habitaciones.map(({ habitacion }) => {
			habitacion.map(edad => {
				if (edad == 30) cantidad.adultos++;
				else {
					cantidad.ninos++;
				}
			});
		});
		return cantidad;
	});

	const setDestino = (nombre, areaId, estacionId) => {
		store_buscador.setDestino(nombre, areaId, estacionId);
	};
	const setHabitaciones = habitacion => {
		store_buscador.setHabitaciones(habitacion);
	};

	const validateHabitaciones = () => {
		if (store_buscador.filtrosSeleccionadosBuscador.habitaciones === null) {
			store_buscador.setHabitaciones([{ habitacion: [30, 30] }]);
		}
	};

	function getDays(start, last) {
		const date1 = new Date(start);
		const date2 = new Date(last);

		const oneDay = 1000 * 60 * 60 * 24;

		const diffTime = date2.getTime() - date1.getTime();

		const diffDays = Math.round(diffTime / oneDay);
		return diffDays;
	}

	const fechaALojamiento = computed(() => filtrosSeleccionadosBuscador.value.fechasAlojamiento);

	watch(fechaALojamiento, () => {
		if (filtrosSeleccionadosBuscador.value.fechasForfait) {
			let fechaInicio = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[0]);
			let fechafin = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]);
			let yesterday = new Date();

			if (getDays(fechaInicio, fechafin) > 2) {
				yesterday = new Date(fechafin.getTime());
				yesterday.setDate(fechafin.getDate() - 1);
				store_buscador.setFechasForfait(new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)), yesterday);
			} else {
				store_buscador.setFechasForfait(
					new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)),
					filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]
				);
			}
		}
	});
	const goToViajesAMedida = () => {
		if (landing && filtrosSeleccionadosBuscador.value.fechasAlojamiento != null) {
			let fechaInicio = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[0]);

			store_buscador.setFechasForfait(
				new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)),
				filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]
			);
		}

		if (!checkNoErrors()) {
			return;
		}

		router.push({
			name: Buscador,
			query: {
				destino: JSON.stringify(filtrosSeleccionadosBuscador.value.destino),
				fechasAlojamiento: JSON.stringify(filtrosSeleccionadosBuscador.value.fechasAlojamiento),
				fechasForfait: JSON.stringify(filtrosSeleccionadosBuscador.value.fechasForfait),
				habitaciones: JSON.stringify(filtrosSeleccionadosBuscador.value.habitaciones),
			},
		});
	};

	const checkNoErrors = () => {
		errors.value.destino = filtrosSeleccionadosBuscador.value.destino.nombre == null ? true : false;

		errors.value.fechasAlojamiento = filtrosSeleccionadosBuscador.value.fechasAlojamiento == null ? true : false;

		errors.value.fechasForfait = filtrosSeleccionadosBuscador.value.fechasForfait == null ? true : false;

		errors.value.habitaciones = filtrosSeleccionadosBuscador.value.habitaciones == null ? true : false;

		return !(
			errors.value.destino ||
			errors.value.fechasAlojamiento ||
			errors.value.fechasForfait ||
			errors.value.habitaciones
		);
	};
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/main.scss';
	@import '~@/assets/scss/styles.scss';

	.error {
		color: red;
	}

	.error_select {
		border: 1px solid red;
	}

	.text-clickable:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	.no-home {
		border-radius: var(--bs-border-radius-xl);
	}

	.section-search {
		border-right: 1.5px solid var(--bs-primary);
	}

	.mobile-buscador {
		display: none;

		.title-buscador-mobile {
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.title-opcion-mobile {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: var(--bs-primary);
		}

		.btn-mobile {
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.buscador {
		display: flex;
		height: 80px;
		background-color: rgba(255, 255, 255, 0.7);
		box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
		border-radius: var(--bs-border-radius-xl);
		padding: 0.2rem 2rem;
		border-radius: var(--bs-border-radius-xxl);
		color: var(--bs-primary);
	}

	.width-buscador-sin-forfait {
		max-width: 780px;
	}

	.width-buscador-con-forfait {
		width: 1050px;
	}

	.buscador-subtitulo {
		position: relative;
	}

	.buscador-titulo {
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: 0.15px;
		padding-top: 0;
	}

	.dropdown-destino {
		width: max-content;
	}

	.clear-filter {
		position: absolute;
		top: -30px;
		right: 10px;
	}

	.dropdown-groups {
		width: 300px;
	}

	.error {
		color: red;
	}

	.mensaje-navidad {
		top: 0;
		right: -230px;

		.card {
			border-radius: var(--bs-border-radius-xl);
			max-width: 250px;
		}
	}

	.error_select {
		border: 1px solid red;
	}

	.circulo-seleccionado {
		margin-left: auto;
		margin-right: 10%;
		text-align: end;
		background-color: var(--bs-secondary);
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.circulo-seleccion {
		margin-left: auto;
		margin-right: 10%;
		text-align: end;
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	@media only screen and (max-width: 989px) {
		.buscador {
			display: none;
		}

		.container {
			background-color: rgba(255, 255, 255, 0.7);
		}
		.mobile-buscador {
			display: block;
			width: 100%;
			padding: 15px;
			border-radius: var(--bs-border-radius-xxl);
		}
		.buscador {
			width: 100%;
			height: auto;
			border-radius: var(--bs-border-radius-xl);
			padding: 10px;
		}

		.box-shadow-buscador {
			box-shadow: 0 0 7px 0 rgba(var(--bs-primary-rgb), 0.5);
		}
	}

	.text-muted {
		font-size: 0.8rem;
	}

	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 900px;
		}
	}
</style>
