<template>
	<div class="tarjeta mx-auto">
		<div class="container-background">
			<img
				:src="evento.imagenEvento"
				alt=""
				height="150"
				width="300"
				style="border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0; opacity: 0.5" />

			<h5 class="card-title">
				<i class="fa-solid fa-calendar fa-xl" style="color: var(--bs-primary)" />
				<span class="mx-3 text-primary fw-bolder">
					{{ formatDates(evento.fechaInicio) }} -
					{{ formatDates(evento.fechaFin) }}
				</span>
			</h5>

			<img :src="festival.icono" class="icon" />
		</div>
		<div class="container text-center fondo-blanco">
			<div class="row mb-3">
				<div class="col titulo fw-bolder text-primary">
					{{ evento.nombre }}
				</div>
			</div>
			<div class="row gx-0 iconos px-3">
				<template v-for="ticket in evento.paquetesTicket" :key="ticket">
					<div v-if="ticket.activo" class="col-6 text-center">
						<div class="icono-event d-flex align-items-center justify-content-center">
							<img
								v-if="ticket.tipo == 'entrada' && ticket.subtipo == 'full'"
								src="@/assets/icons_evento/mountain-fill.webp"
								alt="mountain-fill"
								width="40" />
							<img
								v-if="ticket.tipo == 'entrada' && ticket.subtipo == 'days'"
								src="@/assets/icons_evento/mountain.webp"
								alt="mountain-fill"
								width="40" />
							<img
								v-if="ticket.tipo == 'consumicion'"
								src="@/assets/icons_evento/drink.webp"
								alt="mountain-fill"
								width="40" />
						</div>
						<div style="font-size: 0.8rem" class="text-center text-primary">
							<p class="mb-3 fw-bold">
								{{ ticket.titulo }}
							</p>
						</div>
					</div>
				</template>
			</div>

			<div class="row justify-content-center align-items-center">
				<button
					class="col-auto boton-modal boton-modal-naranja mb-3 ms-0"
					@click="goFestivalServicios(festival.uri, evento.id)">
					Compra tu entrada
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent, ref } from 'vue';
	import SpinnerVue from '../SpinnerLoading.vue';
	import {
		getImagenIconoPorTipoYSubtipo,
		getImagenDinamicaPorNombre,
	} from '../../services/utils/RecursosDinamicosUtils';
	import router from '../../router';
	import { FestivalesServicios } from '../../router/RouterNames';

	export default defineComponent({
		components: SpinnerVue,
		props: {
			viajeTarjeta: {
				type: Object,
				required: true,
			},
			isLogged: {
				type: Boolean,
				required: true,
			},
			idOfertaViaje: {
				type: Number,
				required: true,
			},
			idViajeVotado: {
				type: Number,
				required: true,
			},
			evento: {
				type: Object,
				default: null,
			},
			festival: {
				type: Object,
				default: null,
			},
		},
		emits: ['inicioSesion', 'votacionSeleccionada'],
		setup(props) {
			const services = ref(false);

			const goFestivalServicios = () => {
				router.push({
					name: FestivalesServicios,
					params: {
						uri: props.festival.uri,
						eventoId: props.evento.id,
					},
				});
			};

			const showServices = () => {
				services.value = !services.value;
			};

			const error = ref(false);
			/**
			 * formatea fecha
			 * @param _date fecha a formatear
			 * @returns {string} day month
			 */
			const formatDates = _date => {
				const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'agos', 'sep', 'oct', 'nov', 'dic'];
				const parsedDateFromISO = new Date(_date);
				const day = parsedDateFromISO.getDate();
				const month = months[parsedDateFromISO.getMonth()];
				return day + ' ' + month;
			};

			/**
			 * Devuelve el numero de dias entre dos fechas
			 * @param inicio
			 * @param fin
			 * @returns {number}
			 */
			const numberOfDays = (inicio, fin) => {
				var d1 = new Date(inicio);
				var d2 = new Date(fin);
				return (d2 - d1) / (1000 * 3600 * 24);
			};

			const listaIncluyesPrincipal = computed(() => {
				let listaIncluyesPrincipalAux = [];
				if (props.viajeTarjeta) {
					listaIncluyesPrincipalAux = props.viajeTarjeta.incluyes.filter(
						({ nombre }) => nombre === 'Forfait' || nombre === 'Alojamiento'
					);
				}
				return listaIncluyesPrincipalAux;
			});

			return {
				formatDates,
				numberOfDays,
				showServices,
				services,
				getImagenIconoPorTipoYSubtipo,
				getImagenDinamicaPorNombre,
				listaIncluyesPrincipal,
				error,
				goFestivalServicios,
			};
		},
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/styles.scss';

	.tarjeta {
		margin-bottom: 1rem;
		border-radius: var(--bs-border-radius-xxl);
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		width: 300px;
	}

	.icon {
		position: absolute;
		margin: auto;
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		color: var(--bs-primary);
		transition: 0.3s;
		width: 99px;
		border-radius: 50%;
	}

	.container-background {
		position: relative;
		z-index: 1;
		.card-title {
			margin: auto;
			position: absolute;
			top: 80px;
			left: 0;
			bottom: 0;
			right: 0;
			text-align: center;
		}
	}

	.iconos {
		padding-bottom: 0.7rem;
	}

	.icono-event {
		min-height: 40px;
	}

	.fondo-blanco {
		padding-top: 1.3rem;
		padding-bottom: 1rem;
		border-radius: var(--bs-border-radius-xxl);
		position: relative;
		/* bottom: 5%; */
		background-color: white;
		padding-left: 0.4rem;
		padding-right: 0.4rem;
		z-index: 2;
		margin-top: -19px;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 1.4rem;
		font-weight: 900;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.boton-modal {
		cursor: pointer;
		font-size: 0.7rem;
		font-weight: 700;
		padding: 0.5rem 1.5rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.2s ease;
		border: 0;
	}

	.boton-modal-naranja {
		margin-left: 1rem;
		background-color: var(--bs-secondary);
		color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.boton-modal-naranja:hover:enabled {
		transform: scale(1.1);
		background-color: var(--bs-primary);
		border-color: var(--bs-primary);
	}

	.boton-modal-naranja:active:enabled {
		outline: 2px solid rgba(33, 36, 68, 0.5);
	}

	.boton-modal:disabled {
		opacity: 0.75;
	}

	@media only screen and (min-width: 962px) {
		.tarjeta {
			width: 300px;
			border-radius: var(--bs-border-radius-xl);
		}

		.fondo-blanco {
			border-radius: var(--bs-border-radius-xl);
		}
	}
</style>
