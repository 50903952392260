<template>
	<div class="gracias">
		<div class="container seccion-gracias">
			<div class="row justify-content-center text-center">
				<div class="col principal">¡{{ $t('reserva.graciasReserva') }}!</div>
			</div>
			<div class="row justify-content-center text-center espacio">
				<div class="col-auto">
					<div class="col secundario">
						{{ $t('reserva.codViaje') }}
					</div>
					<div id="textToBecopied" class="num-reserva">
						{{ codigoViaje }}
						<button class="btn" @click="copyToClipboard()">
							<img src="~@/assets/icons/copy-to-clipboard.webp" width="20" alt="" />
						</button>
					</div>
				</div>
			</div>
			<div class="row espacio justify-content-center text-center">
				<div class="col secundario">
					{{ $t('reserva.mensajeExperiencia') }}
					<br class="desktop" />
					{{ $t('reserva.msjAnadirExtras') }}
				</div>
			</div>

			<div class="row justify-content-center text-center espacio">
				<div class="col-auto">
					<button class="btn-primary" @click="goToMisReservas">
						{{ $t('reserva.misReservas') }}
					</button>
				</div>
				<div class="col-auto">
					<button class="btn-primary" @click="goToReservaResumen">
						{{ $t('reserva.anadirExtras') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { MisReservas, GruposReservaResumen } from '@/router/RouterNames';
	import { useRoute, useRouter } from 'vue-router';
	import { copyToClipboard } from '@/services/utils/AccionesUtils.js';

	const route = useRoute();
	const router = useRouter();

	function goToReservaResumen() {
		router.push({ name: GruposReservaResumen });
	}
	function goToMisReservas() {
		router.push({ name: MisReservas });
	}

	const codigoViaje = route.params.codigoViaje;
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/styles.scss';

	#textToBecopied {
		overflow: hidden;
	}

	.margin-bottom-1 {
		margin-bottom: 1rem;
	}
	.desktop {
		display: none;
	}

	.gracias {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)),
			url('~@/assets/fotos/bola-cristal-background.webp');
		min-height: 80vh;
		background-size: cover;
		background-position: center;
		color: var(--bs-primary);
		font-weight: 900;
	}

	.seccion-gracias {
		padding-top: 10rem;
	}

	.principal {
		font-size: 1.1rem;
		margin-bottom: 0.5rem;
	}

	.secundario {
		font-size: 0.8rem;
	}

	.num-reserva {
		font-size: 1.3rem;
		border-radius: var(--bs-border-radius);
		padding: 0.6rem;
		background-color: #51515160;
	}

	.espacio {
		margin-bottom: 1rem;
	}

	@media only screen and (min-width: 962px) {
		.desktop {
			display: inline;
		}
		.gracias {
			padding-top: 8rem;
		}

		.principal {
			font-size: 1.5rem;
		}
		.secundario {
			font-size: 1rem;
		}
		.num-reserva {
			font-size: 2rem;
		}
	}
</style>
