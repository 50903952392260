<template>
	<div
		class="post"
		@click="
			$router.push({
				name: 'BlogLandingPost',
				params: {
					blogUri: dataCard.uri,
				},
			})
		">
		<div :style="{ backgroundImage: 'url(' + dataCard.imagen + ')' }" class="banner-img" />
		<div class="text-post">
			<div class="d-flex justify-content-between pb-2">
				<span class="text-muted">
					<!-- {{ new Date(dataCard.fechaCreacion).toLocaleDateString() }} -->

					{{ formatDatesFullMonthAndYear(dataCard.fechaCreacion) }}
				</span>

				<span class="text-muted">
					{{ dataCard.categorias[0] }}
				</span>
			</div>
			<h3 class="fw-bolder text-primary">
				{{ dataCard.titulo }}
			</h3>
			<p class="m-0 resumen">
				{{ dataCard.resumen }}
			</p>
			<div class="btn btn-link px-0 fw-bolder">LEER MÁS</div>
		</div>
		<hr class="movil" />
	</div>
</template>

<script>
	import { formatDatesFullMonthAndYear } from '@/helpers/dateUtiles';
	export default {
		props: {
			dataCard: {
				type: Object,
				default: null,
			},
		},
		setup() {
			return {
				formatDatesFullMonthAndYear,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.banner-img {
		height: 288px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.default-background {
		background-image: url('../../../assets/fotos/telesilla.webp');
	}
	.post {
		cursor: pointer;
	}

	.resumen {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.text_banner {
		background-color: rgb(33, 36, 68, 0.7);
		width: 100%;
	}

	.text-post {
		margin-top: 1rem;
	}

	@media screen and (max-width: 900px) {
		.text-post {
			padding: 0.15rem 0.15rem 0 0.15rem;
		}
	}
</style>
