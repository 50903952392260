import { defineStore } from 'pinia';
import HomeApiService from '@/services/api/skiandnight/HomeApiService';

const miscellaneous = defineStore('miscellaneous', {
	state: () => ({
		sponsors: null,
	}),
	getters: {
		getSponsors(state) {
			if (state.sponsors) return state.sponsors;
			this.setSponsors();
		},
	},
	actions: {
		setSponsors() {
			HomeApiService.getEsponsors().then(response => (this.sponsors = response));
		},
	},
});

export default miscellaneous;
