<template>
	<div class="">
		<div class="fondo" />
		<div class="text-center container">
			<div class="row align-items-center justify-items-center">
				<div class="cuadro">
					<div class="titulo margin-bottom-small">
						{{ $t('login.establecerContrasenia') }}
					</div>

					<div class="col-12">
						<input
							id="password"
							v-model="password"
							type="password"
							name="password"
							:placeholder="$t('home.form.personalInformation.newpassword')" />
					</div>

					<div class="col-12">
						<input
							id="passwordConfirm"
							v-model="passwordConfirm"
							type="password"
							name="passwordConfirm"
							:placeholder="$t('login.confirmPassword')" />
					</div>

					<div v-if="formError" class="col-12 error">
						{{ formError }}
					</div>
					<div v-if="usuarioApiErrorMessage" class="col-12 error">
						{{ usuarioApiErrorMessage }}
					</div>
					<div class="col-12" @click="botonRestablecer">
						<button class="btn">
							{{ $t('botones.establecer').toUpperCase() }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="wrapper" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import * as AlertUtility from '@/services/utils/AlertUtils';

	export default {
		data() {
			return {
				codigo: this.$route.params.codigo,
				passwordConfirm: null,
				password: null,
				formError: null,
			};
		},
		computed: {
			...mapState({
				solicitudRestablecerSuccess: state => state.usuario.solicitudRestablecerSuccess,
			}),
			...mapGetters({
				usuarioApiErrorMessage: 'usuario/getErrorMessage',
			}),
		},
		watch: {
			solicitudRestablecerSuccess: function () {
				if (this.solicitudRestablecerSuccess) {
					const text = 'Se ha establecido la contraseña con éxito';
					AlertUtility.alertSuccessWithCallback(text, () => this.$router.push({ name: 'home' }));
				}
			},
		},
		methods: {
			...mapActions({
				restablecer: 'usuario/restablecerPassword',
			}),
			async botonRestablecer() {
				if (this.checkForm()) {
					const password = {
						password: this.password,
						passwordConfirm: this.passwordConfirm,
					};
					await this.restablecer({
						codigo: this.codigo,
						password: password,
					});
				}
			},
			checkForm() {
				if (!this.password || !this.passwordConfirm) {
					this.formError = 'Rellene todos los campos';
					return false;
				}
				this.formError = null;
				return true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.titulo {
		font-size: 1.4rem;
		font-weight: bold;
	}

	.fondo {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)),
			url('~@/assets/fotos/bola-cristal-background.webp');
		padding-top: 10rem;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.cuadro {
		padding-top: 2rem;
		padding-bottom: 2rem;
		border-radius: var(--bs-border-radius-xl);
		max-width: 30rem;
		margin: auto;
		color: #fff;
	}

	input {
		width: 80%;
		padding: 0.4rem 1rem 0.4rem 1rem;
		font-size: 0.8rem;
		border-radius: var(--bs-border-radius-lg);
		margin-bottom: 1rem;
		color: var(--bs-primary);
	}

	.btn {
		background-color: #fff;
		border-radius: var(--bs-border-radius-xl);
		padding: 0.4rem 2rem 0.4rem 2rem;
		font-size: 0.8rem;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	}

	.wrapper {
		padding-bottom: 10rem;
	}

	.error {
		font-size: 0.6rem;
		color: red;
		margin-bottom: 0.5rem;
	}

	.margin-bottom-small {
		margin-bottom: 1rem;
	}
</style>
