<template>
	<template v-if="serviciosDisponibles">
		<div class="col card-services">
			<div class="title-card">
				<div class="text">
					<i v-if="selector != 'festival'" :class="informacionServicio.icono" />
					<template v-else>
						<img src="@/assets/servicios/ActividadFestival.webp" height="40" width="40" alt="" class="me-2" />
					</template>
					<span>{{ informacionServicio.titulo }}</span>
				</div>
				<div class="d-flex">
					<div v-if="servicioSeleccionado">
						<span v-if="servicioSeleccionado.precio" class="text-secondary mx-2">
							<span v-if="servicioSeleccionado.precio != 0">
								<span v-if="servicioSeleccionado.precio > 0">+</span>
								{{ servicioSeleccionado.precio }}€
							</span>
						</span>
					</div>
					<span v-if="servicioSeleccionado.paqueteBasico && servicioSeleccionado.precio != 0" class="dot-disabled" />
					<span v-if="servicioSeleccionado.precio != 0 || servicioSeleccionado.paqueteBasico" class="dot" />
				</div>
			</div>

			<div class="body-card px-3">
				<div class="row">
					<p class="mt-3">
						{{ informacionServicio.subtitulo }}
					</p>
					<p v-if="servicioSeleccionado && !servicioSeleccionado.nulo" class="text-muted fw-bold">
						{{ servicioSeleccionado.descripcion }}
					</p>
				</div>

				<div class="row">
					<div class="col">
						<div class="input-group mb-3">
							<select v-model="servicioSeleccionado" class="form-select">
								<option
									v-for="servicio in serviciosDisponibles.filter(s => s.activo)"
									:key="servicio.id"
									:value="servicio">
									<span v-if="!servicio.nulo && selector != 'transporte'">
										{{ servicio.numDias }} <span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
										<span v-else> {{ $t('general.dias') }}</span>
									</span>
									{{ servicio.nombre }}
									<span>
										<span v-if="!servicio.nulo">
											( {{ $t('general.del') }}
											{{ dateMonthNumbers(servicio.fechaInicio) }}
											{{ $t('general.al') }}
											{{ dateMonthNumbers(servicio.fechaFin) }})
										</span>
										<span v-if="servicio.precio > 0">(+</span>
										<span v-if="servicio.precio">
											<span v-if="servicio.precio < 0">(</span>
											{{ servicio.precio }}€)
										</span>
									</span>
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script>
	import { ref, watch } from 'vue';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { selectServiceText } from '@/constants/selectService';

	export default {
		name: 'SelectServiceIndividual',
		props: {
			/**
			 *  El selector de tipo individual son solo validos forfait|restauracion|transporte
			 */
			selector: {
				type: String,
				required: true,
			},

			serviciosDisponibles: {
				type: Array,
				required: true,
			},

			servicioPredefinido: {
				type: Object,
				default: null,
			},
		},
		emits: ['servicioActualizado'],
		setup(props, { emit }) {
			const servicioSeleccionado = ref({ ...props.servicioPredefinido });

			const informacionServicio = selectServiceText[props.selector];

			watch(servicioSeleccionado, () => {
				let objetAux = {};
				objetAux[props.selector] = servicioSeleccionado.value;
				emit('servicioActualizado', objetAux);
			});

			return {
				dateMonthNumbers,
				servicioSeleccionado,
				informacionServicio,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;
			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}
</style>
