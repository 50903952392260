<template>
	<base-carousel
		:items-carousel="apresSkis"
		:is-loading="!apresSkis"
		:autoplay-time="2500"
		:max-elements="3"
		:button-text="$t('general.verTodos')"
		:button-function="goToCatalogoApresSkis">
		<template #title>
			{{ apresText.title }}
		</template>
		<template #itemCarousel="itemCarouselProps">
			<TarjetaHomeMejoresApresSkis :apres-ski="itemCarouselProps.data" />
		</template>
	</base-carousel>
</template>

<script setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { ApresSkisCatalogo } from '@/router/RouterNames';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import TarjetaHomeMejoresApresSkis from '@/components/tarjetas/TarjetaHomeMejoresApresSkis.vue';
	import router from '@/router';

	const { t } = useI18n();

	const props = defineProps({
		apresSkisList: {
			type: Array,
			required: true,
			default: () => [],
		},
	});

	const apresSkis = computed(() => props.apresSkisList);
	const apresText = computed(() => ({
		title: t('home.apres.title'),
		description: t('home.apres.description'),
		button: t('home.apres.button'),
	}));

	const goToCatalogoApresSkis = () => {
		router.push({
			name: ApresSkisCatalogo,
		});
	};
</script>
