<template>
	<div class="tarjeta-reserva-alojamiento col-12 col-sm-10 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
		<div class="container text-center tarjeta-not-found py-4">
			<div class="row">
				<img class="img-not-found" src="../../assets/img/not_found_ilustracion.webp" alt="" />
			</div>
			<div class="d-flex flex-column justify-content-around info-not-found p-md-3 mt-1">
				<div class="title-no">
					{{ title }}
				</div>

				<div class="subtitle-no">
					<i18n-t keypath="reserva.mensajeCompraExp" tag="div" class="text-primary pt-2">
						<template #aqui>
							<a type="button" class="text-secondary" @click="goCatalogo">{{ $t('general.aqui') }}</a>
						</template>
					</i18n-t>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { useRouter } from 'vue-router';
	import { ViajesGrupoCatalogo } from 'src/router/RouterNames';

	defineProps({
		title: {
			type: String,
			default: null,
		}
	});
	const router = useRouter();

	function goCatalogo() {
		router.push({
			name: ViajesGrupoCatalogo,
		});
	}
</script>

<style lang="scss" scoped>
	.cursor-pointer {
		cursor: pointer;
	}

	.back-opaco {
		background-color: rgb(249, 247, 248, 0.5) !important;
	}
	.opaco {
		opacity: 0.5;
	}

	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 800px;
			margin: 1.75rem auto;
		}
	}

	.img-reserva-tarjeta {
		height: auto;
		width: 100%;
		height: 6.5rem;
		border-radius: var(--bs-border-radius-lg);
		object-fit: cover;
		opacity: 0.6;
	}

	.title {
		font-weight: 700;
		margin-bottom: -5px;
	}

	.estrellas {
		padding-left: 13px;
		margin-bottom: -5px;
	}
	.estrella {
		margin-left: -2px;
	}

	.datos {
		font-weight: 500;
		margin-bottom: 15px;
	}

	.texto-cancelada {
		font-size: 0.8rem;
		font-weight: 400;
		margin-top: 0.7rem;
	}

	.texto-rojo {
		color: red;
	}

	.icono-info-reserva {
		width: 1rem;
		margin-right: 3px;
	}

	.icono-info-fiesta {
		width: 1.5rem;
	}

	.texto-destino {
		font-size: 0.85rem;
		font-weight: 400;
	}

	.enlace-mas-info {
		cursor: pointer;
		font-size: 0.7rem;
		font-weight: 400;
		text-decoration: none;
		transition: 0.3s ease-in-out;
	}

	.enlace-mas-info:hover {
		color: var(--bs-secondary);
		font-size: 0.7rem;
		font-weight: 400;
	}

	.tarjeta-reserva-alojamiento {
		min-width: 17.5rem;
		border-radius: var(--bs-border-radius-xl);
		/* background-color: rgb(154, 202, 154); */
		background-color: #f9f7f8;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
	}

	.contenido {
		height: 100%;
		padding: 1rem 0.8rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.resumen-reserva {
		background-color: #fff;
		width: 100%;
		padding: 0.3rem;
		margin: 0rem;
		border-radius: var(--bs-border-radius);
	}

	.destino {
		font-size: 0.7rem;
		color: #515151;
	}

	.info-personas {
		padding-left: 13px;
		margin: 10px 5px;
	}

	.personitas {
		margin-left: -13px;
	}

	.icono-fiesta {
		width: 25px;
		margin-left: -10px;
		margin-right: 7px;
	}

	.info-habitacion-numero {
		font-size: 0.7rem;
		font-weight: 400;
		color: var(--bs-primary);
	}

	.info-habitacion-numero-personas {
		font-size: 0.5rem;
	}

	.info-dias {
		font-size: 0.6rem;
		font-weight: 400;
		color: var(--bs-primary);
		gap: 5px;
		margin-bottom: 10px;
		margin-left: 5px;
	}

	.title-info-dias {
		font-size: 0.6rem;
		font-weight: 600;
		color: var(--bs-primary);
	}

	.ver-mapa {
		font-size: 0.7rem;
		color: #515151;
		text-decoration: underline;
	}

	.total-reserva {
		font-weight: 600;
		font-size: 0.7rem;
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 0.3rem -0.3rem -0.3rem -0.3rem;
		color: var(--bs-secondary);
		background-color: var(--bs-primary);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.pendiente-pago {
		font-weight: 600;
		font-size: 0.7rem;
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 0.3rem -0.3rem -0.3rem -0.3rem;
		color: var(--bs-primary);
		background-color: white;
		border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.info-extra {
		font-size: 0.6rem;
		font-weight: 500;
		color: white;
		margin: 0;
	}

	.info-extra-content {
		box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
		background-color: var(--bs-primary);
	}

	.tarjeta-not-found {
		height: 100%;
	}

	.info-not-found {
		height: 40%;
	}

	.title-no {
		font-size: 1.1rem;
		font-weight: 700;
	}

	.subtitle-no {
		font-size: 1rem;
		font-weight: 400;
		padding-bottom: 1rem;
	}

	.span-aqui {
		color: var(--bs-secondary);
		text-decoration: underline;
	}

	.boton {
		cursor: pointer;
		font-size: 0.6rem;
		font-weight: 700;
		padding-top: 0.35rem;
		padding-bottom: 0.35rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.3s ease;
		border: 0;
		width: 100px;
		text-align: center;
	}

	.boton2 {
		cursor: pointer;
		font-size: 0.6rem;
		font-weight: 700;
		padding-top: 0.35rem;
		padding-bottom: 0.35rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.3s ease;
		border: 0;
		width: 70px;
		text-align: center;
	}

	.boton-no-fondo {
		background-color: #f9f7f8;
		color: var(--bs-primary);
	}

	.boton-no-fondo:hover {
		text-decoration: underline;
	}

	.boton-no-fondo:active {
		color: #080a1d;
	}

	.boton-Tarjeta {
		background-color: var(--bs-secondary);
		color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.boton-Tarjeta:hover {
		transform: scale(1.1);
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}

	.boton-Tarjeta:active {
		outline: 2px solid var(--bs-secondary);
	}
</style>
