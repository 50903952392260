<template>
	<div v-if="sponsors && storeGlobal.permisos.sponsors.showed">
		<div v-if="sponsorList.length > 0" class="sponsors-section px-2" style="margin: 35px 0">
			<h2>{{ $t('sponsors.title') }}</h2>
			<div class="d-flex justify-content-between py-2 w-100" style="overflow: auto">
				<a v-for="esponsor in sponsorList" :key="esponsor" :href="'http://' + esponsor.url" target="_blank">
					<img
						:src="esponsor.logo"
						:alt="esponsor.nombre"
						:title="esponsor.nombre"
						style="width: auto; height: 90px; max-width: 150px; object-fit: contain; margin: 10px" />
				</a>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { appGlobalStore } from '@/store_pinia/app';

	const storeGlobal = appGlobalStore();

	const props = defineProps({
		sponsors: {
			type: Array,
			required: true,
			default: () => [],
		},
	});

	const sponsorList = computed(() => props.sponsors);
</script>
