<template>
	<div v-if="storeGlobal.valores != {}" class="banner-top-page__container">
		<div :class="backgroundBanner.class" :style="{ 'background-image': 'url(' + backgroundBanner.url + ')' }">
			<div v-if="showBuscador" class="container py-3 pt-5 pt-md-3">
				<div class="mt-5">
					<BuscadorParaTi />
				</div>
			</div>

			<div class="container">
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup>
	import BuscadorParaTi from '@/components/buscadores/BuscadorParaTi.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import { computed } from 'vue';

	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		isHome: Boolean,
		viajesGrupoCatalogo: Boolean,
		showBuscador: {
			type: Boolean,
			default: () => true,
		},
	});

	const storeGlobal = appGlobalStore();

	const backgroundBanner = computed(() => {
		if (props.isHome) {
			return {
				class: 'home_background',
				url: storeGlobal.valores.img_home,
			};
		} else if (props.viajesGrupoCatalogo)
			return {
				class: 'viajes-grupo-catalogo__background',
				url: storeGlobal.valores.img_catalogo_fondo,
			};
		return '';
	});
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/san.scss';

	.banner-top-page__container {
		.home_background {
			padding-top: 14rem;
			padding-bottom: 5rem;
			background-position: 50%;
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}

		.viajes-grupo-catalogo__background {
			padding-top: 10rem;
			padding-bottom: 5rem;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}
	}
</style>
