<template>
	<div class="notification-container" :class="showCookies || cleancookies ? 'd-none' : ''">
		<div :class="showCookies || cleancookies ? 'd-none' : 'notification'">
			<p class="fw-bolder fs-4">
				{{ $t('cookies.title') }}
			</p>
			<p>
				{{ $t('cookies.description') }}
				<button
					type="button"
					class="btn btn-link px-0 fw-bold"
					data-bs-toggle="modal"
					data-bs-target="#politicaCookiesModal">
					{{ $t('cookies.politicas') }}
				</button>
			</p>
			<div
				id="politicaCookiesModal"
				class="modal fade"
				tabindex="-1"
				aria-labelledby="politicaCookiesModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
					<div class="modal-content">
						<div class="modal-header">
							<p id="politicaCookiesModalLabel" class="modal-title fs-5">
								{{ $t('cookies.politicas') }}
							</p>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
						</div>
						<div class="modal-body">
							<div id="accordionExample" class="accordion">
								<div class="accordion-item">
									<p id="headingQueSonCookies" class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="false"
											aria-controls="collapseTwo">
											{{ $t('cookies.preguntas.1') }}
										</button>
									</p>
									<div
										id="collapseOne"
										class="accordion-collapse collapse"
										aria-labelledby="headingQueSonCookies"
										data-bs-parent="#accordionExample">
										<div class="accordion-body">
											<p>
												{{ $t('cookies.respuestas.1.bloque1') }}
											</p>

											<p>
												{{ $t('cookies.respuestas.1.bloque2') }}
											</p>
											<p>
												{{ $t('cookies.respuestas.1.bloque3') }}
											</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<p id="headingAutorizacionUsoCookies" class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwo"
											aria-expanded="false"
											aria-controls="collapseTwo">
											{{ $t('cookies.preguntas.2') }}
										</button>
									</p>
									<div
										id="collapseTwo"
										class="accordion-collapse collapse"
										aria-labelledby="headingAutorizacionUsoCookies"
										data-bs-parent="#accordionExample">
										<div class="accordion-body">
											{{ $t('cookies.respuestas.2.bloque1') }}
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<p id="headTipoCookies" class="accordion-header fs-2">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree">
											{{ $t('cookies.preguntas.3') }}
										</button>
									</p>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										aria-labelledby="headTipoCookies"
										data-bs-parent="#accordionExample">
										<div class="accordion-body">
											<p>
												{{ $t('cookies.respuestas.3.bloque1') }}
											</p>

											<p>
												{{ $t('cookies.respuestas.3.bloque2') }}
											</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<p id="EliminarCookiesHeading" class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFour"
											aria-expanded="false"
											aria-controls="collapseThree">
											{{ $t('cookies.preguntas.4') }}
										</button>
									</p>
									<div
										id="collapseFour"
										class="accordion-collapse collapse"
										aria-labelledby="EliminarCookiesHeading"
										data-bs-parent="#accordionExample">
										<div class="accordion-body">
											<p>
												{{ $t('cookies.respuestas.4.title') }}
											</p>

											<ul>
												<li>
													<a
														href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11"
														target="_blank">
														{{ $t('cookies.respuestas.4.bloque1') }}
													</a>
												</li>
												<li>
													<a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">
														{{ $t('cookies.respuestas.4.bloque2') }}
													</a>
												</li>
												<li>
													<a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank">
														{{ $t('cookies.respuestas.4.bloque3') }}
													</a>
												</li>
												<li>
													<a href="https://support.apple.com/es-es/HT201265" target="_blank">
														{{ $t('cookies.respuestas.4.bloque4') }}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="button d-flex justify-content-center">
				<div class="btn btn-secondary mx-2" @click="acceptCookies('all')">
					{{ $t('cookies.aceptar') }}
				</div>
				<div v-if="!mostrarCookiesExtra" class="btn btn-primary mx-2" @click="mostrarCookiesExtra = true">
					<span>{{ $t('cookies.gestionar') }}</span>
				</div>
				<div
					v-else
					class="btn btn-primary mx-2"
					@click="
						mostrarCookiesExtra = false;
						acceptCookies('some');
					">
					<span>{{ $t('botones.aceptarSeleccion') }}</span>
				</div>
			</div>

			<div v-if="mostrarCookiesExtra" class="d-flex justify-content-around mt-4 form-cookies">
				<div class="form-check">
					<input id="flexCheckDefault" class="form-check-input" type="checkbox" checked disabled />
					<label class="form-check-label" for="flexCheckDefault">
						{{ $t('cookies.extras.necesarias') }}
					</label>
				</div>
				<div class="form-check">
					<input id="flexCheckDefault" class="form-check-input" type="checkbox" value="" />
					<label class="form-check-label" for="flexCheckDefault">
						{{ $t('cookies.extras.analiticas') }}
					</label>
				</div>
				<div class="form-check">
					<input id="flexCheckDefault" class="form-check-input" type="checkbox" value="" />
					<label class="form-check-label" for="flexCheckDefault">
						{{ $t('cookies.extras.marketing') }}
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue';

	const cleancookies = ref(false);
	const mostrarCookiesExtra = ref(false);

	const showCookies = computed(() => localStorage.getItem('accept-cookies') == 'all');

	const acceptCookies = parameter => {
		localStorage.setItem('accept-cookies', parameter);
		cleancookies.value = true;
	};
</script>

<style lang="scss" scoped></style>
