<template>
	<teleport to="body">
		<modal-base :show="modalActive" hide-submit :title="$t('reserva.misQRs')" @on-close="close">
			<template #body>
				<div class="text-end">
					<button type="button" class="btn btn-secondary" @click="exportToPDF">
						<i class="fa fa-arrow-down fa-2xs" /> {{ $t('reserva.bono.descargarBono') }}
					</button>
				</div>
				<div id="pdfContentId" ref="pdfContent" class="mb-5 mx-2">
					<!--El componente se utiliza fuera del bucle cuando tiene tiene la flag has-accommodation
						El bono de alojamiento se genera uno por habitacion, se reutiliza el componente por que tienen el
						disenio y el contenido es similar, con la flag se controla los datos a mostrar
					-->
					<template v-if="grupo.habitacion">
						<QrReservaAlojamiento :grupo="grupo"/>
					</template>

					<template v-for="(reserva, rIndex) in reservasActivas" :key="rIndex">
						<template v-for="(servicio, sIndex) in reserva.servicios.filter(s => !s.nulo)" :key="sIndex">
							<QrReservaServicios :grupo="grupo" :reserva="reserva" :servicio="servicio" />
						</template>
					</template>
				</div>
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase';
	import html2pdf from 'html2pdf.js';
	import { computed, ref } from 'vue';
	import QrReservaServicios from 'src/components/modales/Reserva/QrReservaServicios.vue';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import QrReservaAlojamiento from '@/components/modales/Reserva/QrReservaAlojamiento';

	const props = defineProps({
		modalActive: {
			type: Boolean,
			default: null,
		},
		// RGrupoDTO
		grupo: {
			type: Object,
			required: true,

		},
	});

	const emit = defineEmits(['close-modal']);

	const pdfContent = ref();

	const reservasActivas = computed( () =>
		props.grupo.reservas.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA)
	);

	function exportToPDF() {
		html2pdf()
			.set({
				margin: 3,
				filename: 'document.pdf',
				image: { type: 'jpeg', quality: 1 },
				pagebreak: { mode: 'avoid-all', after: '.espaciado' },
				html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
				jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
			})
			.from(pdfContent.value)
			.save();
	}

	function close() {
		emit('close-modal');
	}
</script>

<style lang="scss">
	.espaciado {
		margin: 9rem 1rem 1rem 1.5rem;
	}

	.container-pdf {
		background: var(--bs-white);

		.card {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			height: 100%;
		}

		.title {
			font-size: 16px;
			font-weight: 700;
		}

		.sub-title {
			font-size: 18px;
			font-weight: 800;
		}

		.description {
			font-size: 18px;
			font-weight: 400;
		}

		.ticket-body {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			height: 100%;
		}

		.text-title {
			font-size: 11px;
			font-weight: 400;
		}

		.text-description {
			font-size: 11px;
			font-weight: 600;
		}

		.powered-by {
			font-size: 9px;
			font-weight: 400;
		}

		.text-terms {
			font-size: 9px;
			font-weight: 600;
		}

		.text-terms-description {
			font-size: 8px;
			font-weight: 400;
		}
	}
</style>
