<template>
	<div class="mb-4 position-relative">
		<div class="card shadow card-servicio">
			<div class="card-body">
				<div class="row">
					<div class="col-9">
						<div class="row" :class="!servicio.activo ? 'text-muted' : 'text-primary'">
							<div class="col-2 d-flex justify-content-center align-items-center">
								<img
									v-if="servicio.tipo == 'consumicion' && servicio.activo"
									src="../../../assets/festivales/consumicion.svg"
									:alt="servicio.titulo"
									width="40" />
								<img
									v-if="servicio.tipo == 'consumicion' && !servicio.activo"
									src="../../../assets/festivales/consumicion-disabled.svg"
									:alt="servicio.titulo"
									width="40" />
								<img
									v-if="servicio.tipo == 'entrada' && servicio.subtipo == 'days' && !servicio.activo"
									src="../../../assets/festivales/entrada-disabled.svg"
									:alt="servicio.titulo"
									width="40" />
								<img
									v-if="servicio.tipo == 'entrada' && servicio.subtipo == 'days' && servicio.activo"
									src="../../../assets/festivales/entrada.svg"
									:alt="servicio.titulo"
									width="40" />
								<img
									v-if="servicio.tipo == 'entrada' && servicio.subtipo == 'full' && servicio.activo"
									src="../../../assets/festivales/entrada_full_activo.svg"
									:alt="servicio.titulo"
									width="40" />
								<img
									v-if="servicio.tipo == 'entrada' && servicio.subtipo == 'full' && !servicio.activo"
									src="../../../assets/festivales/entrada_full-disabled.svg"
									:alt="servicio.titulo"
									width="40" />
							</div>
							<div class="col-10">
								<p class="fw-bold mb-0 fs-6">
									{{ servicio.titulo }}
								</p>
								<p class="text-muted mb-0" style="font-size: 14px">
									{{ servicio.subtitulo }}
								</p>
							</div>
						</div>
					</div>
					<div
						class="col-2 fw-bold d-flex justify-content-end align-items-center fs-5"
						:class="servicio.activo ? 'text-secondary' : 'text-muted'">
						<span v-if="servicio.precio > 0">{{ servicio.precio }}€</span>
					</div>
					<div class="col-1 d-flex align-items-center justify-content-center px-0">
						<button
							class="btn btn-primary bg-transparent border-0 text-primary p-0"
							type="button"
							:disabled="!servicio.activo"
							@click="isOpen = !isOpen">
							<i class="fa-solid fa-chevron-down" :class="isOpen ? 'icon-active' : 'icon-inactive'" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<transition>
			<div v-if="isOpen && listaServicios" class="card mt-2">
				<div class="card-body">
					<div class="row">
						<div :class="servicio.tipo != 'consumicion' ? 'col-6' : 'colo-12'">
							{{ servicio.descripcion }}
						</div>
						<div v-if="servicio.tipo != 'consumicion'" class="col-6">
							<button
								class="btn btn-light rounded-circle shadow-sm text-primary me-2"
								:disabled="cantidadServicio(servicio) == 0"
								@click="removeCantidadServicio(servicio)">
								<i class="fa-solid fa-minus" />
							</button>
							{{ cantidadServicio(servicio) }}
							<button
								class="btn btn-light rounded-circle shadow-sm text-primary ms-2"
								:disabled="cantidadServicio(servicio) == 10"
								@click="addCantidadServicio(servicio)">
								<i class="fa-solid fa-plus" />
							</button>
						</div>
					</div>
					<div class="mt-3">
						<template v-for="ticket in servicio.tickets" :key="ticket.id">
							<div class="row">
								<div class="col">
									<div class="row">
										<div class="col-6">
											<div v-if="servicio.tipo != 'consumicion'" class="form-check">
												<input
													:id="ticket.nombre + ticket.id"
													class="form-check-input p-2 rounded-circle"
													type="checkbox"
													:disabled="serviciosSeleccionados(ticketServicio, servicio.numOpciones, ticket)"
													@click="addToServices(ticket, servicio)" />
												<label class="form-check-label fw-bold fs-6 text-primary" :for="ticket.nombre + ticket.id">
													{{ ticket.nombre }}
													<span v-if="ticket.cupos < 50 && servicio.tipo != 'consumicion'" class="ms-5 text-danger">
														<i class="fa-solid fa-circle-info text-danger" />
														Quedan pocos tickets
													</span>
												</label>
											</div>
											<div v-else class="form">
												{{ ticket.nombre }}
												<span v-if="ticket.cupos < 50 && servicio.tipo != 'consumicion'" class="ms-5 text-danger">
													<i class="fa-solid fa-circle-info text-danger" />
													Quedan pocos tickets
												</span>
											</div>
											<p class="text-primary">
												<template v-if="ticket.fechaInicio">
													<span v-if="ticket.fechaInicio == ticket.fechaFin" class="me-4">
														{{ formatDatesFullMonth(convertStringToDate(ticket.fechaInicio)) }}
													</span>
													<span v-else>
														{{ formatDatesFullMonth(convertStringToDate(ticket.fechaInicio)) }}
														{{ $t('general.al') }}
														{{ formatDatesFullMonth(convertStringToDate(ticket.fechaFin)) }}
													</span>
												</template>
												<template v-if="servicio.tipo == 'consumicion'">
													<div class="text-secondary">+{{ ticket.precio }}€</div>
												</template>
											</p>
										</div>
										<div v-if="servicio.tipo == 'consumicion'" class="col-6">
											<button
												class="btn btn-light rounded-circle shadow-sm text-primary me-2"
												:disabled="cantidadServicioConsumicion(servicio, ticket) == 0"
												@click="removeCantidadServicio(servicio, ticket)">
												<i class="fa-solid fa-minus" />
											</button>
											{{ cantidadServicioConsumicion(servicio, ticket) }}
											<button
												class="btn btn-light rounded-circle shadow-sm text-primary ms-2"
												:disabled="cantidadServicioConsumicion(servicio, ticket) == ticket.cupos"
												@click="addCantidadServicio(servicio, ticket)">
												<i class="fa-solid fa-plus" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { computed, defineComponent, ref } from 'vue';
	import { convertStringToDate, formatDatesFullMonth } from 'src/helpers/dateUtiles';
	import { festivalesStore } from 'src/store_pinia/festivales';
	import { firstLetterCapital } from 'src/helpers/languageUtils';

	export default defineComponent({
		props: {
			servicio: {
				type: Object,
				default: null,
			},
		},
		setup() {
			const isOpen = ref(false);

			const ticketServicio = ref([]);

			const store = festivalesStore();

			const listaServicios = computed(() => store.reservaServiciosFestivales);

			const addCantidadServicio = (servicio, ticket) => {
				store.addCantidad(servicio, ticket);
			};

			const removeCantidadServicio = (servicio, ticket) => {
				store.removeCantidad(servicio, ticket);
			};

			const addToServices = (ticket, servicio) => {
				let indexServicio = ticketServicio.value.findIndex(servicioListado => {
					if (!servicioListado) return;
					return ticket.id === servicioListado.id;
				});

				if (indexServicio == -1) {
					ticketServicio.value.push(ticket);
				} else {
					delete ticketServicio.value[indexServicio];
				}

				store.updateTicketsServicios(servicio, ticketServicio.value);
			};

			const cantidadServicio = servicio => {
				return listaServicios.value[
					`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
				].cantidad;
			};

			const cantidadServicioConsumicion = (servicio, ticket) => {
				let index = listaServicios.value[
					`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
				].tickets.findIndex(({ id }) => id == ticket.id);
				if (index == -1) return 0;
				let cantidad =
					listaServicios.value[
						`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
					].tickets[index].cantidad;
				if (cantidad == undefined) return 0;
				return cantidad;
			};

			const serviciosSeleccionados = (listaServicio, numOpciones, ticket) => {
				let valoresAceptados = 0;
				listaServicio.forEach(servicio => {
					if (servicio != null) valoresAceptados++;
				});
				return valoresAceptados == numOpciones && !listaServicio.some(servicio => servicio.id == ticket.id);
			};

			return {
				serviciosSeleccionados,
				isOpen,
				convertStringToDate,
				formatDatesFullMonth,
				listaServicios,
				firstLetterCapital,
				addCantidadServicio,
				removeCantidadServicio,
				cantidadServicio,
				ticketServicio,
				addToServices,
				cantidadServicioConsumicion,
			};
		},
	});
</script>

<style scoped>
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.5s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.card-servicio {
		border-radius: var(--bs-border-radius-xl);
		background-color: #f9f7f8;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}
</style>
../../../helpers/dateUtiles../../../store_pinia/festivales../../../helpers/languageUtils
