<template>
	<div class="container superior">
		<div class="row titulo">
			<div class="col">
				<h2>FAQS</h2>
			</div>
		</div>
		<div v-for="pregunta in faqsInformation" :key="pregunta.id" class="row">
			<div class="col">
				<h3>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						:href="`#collapse` + pregunta.id"
						role="button"
						aria-expanded="false"
						aria-controls="collapseOne">
						{{ pregunta.pregunta }}
					</a>
				</h3>
				<div :id="'collapse' + pregunta.id" class="collapse">
					<div class="card card-body">
						<p>
							{{ pregunta.respuesta }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

.
<script setup>
	import HomeApiService from 'src/services/api/skiandnight/HomeApiService';
	import { ref } from 'vue';

	const faqsInformation = ref([]);

	HomeApiService.getFaqs().then(res => (faqsInformation.value = res));
</script>

<style scoped>
	.superior {
		padding-top: 7rem;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 1.5rem;
		margin-bottom: 2rem;
		font-weight: 900;
	}

	.btn {
		width: 100%;
		text-align: start;
		padding-left: 2rem;
		background-image: linear-gradient(72deg, #313056 31%, #313056 31%, #30356b 91%);
	}

	.collapse {
		margin-bottom: 1rem;
	}

	.spanned {
		font-weight: 900;
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}

		.titulo {
			font-size: 2rem;
		}
	}
</style>
../../../services/api/skiandnight/HomeApiService
