import { defineStore } from 'pinia';

const ordernarViajes = (listaViajes, queryGrupo) => {
	let viajes = {};
	if (listaViajes) {
		listaViajes.map(viaje => {
			viaje.tipos.forEach(tipo => {
				if (queryGrupo) {
					if (queryGrupo == tipo.nombre) {
						if (!viajes[tipo.descripcion]) {
							viajes[tipo.descripcion] = [];
						}
						viajes[tipo.descripcion].push(viaje);
					}
				} else {
					if (!viajes[tipo.descripcion]) {
						viajes[tipo.descripcion] = [];
					}
					viajes[tipo.descripcion].push(viaje);
				}
			});
		});
		return viajes;
	}
};

export const filtroGruposStore = defineStore('filtroGrupos', {
	//Para crear un nuevo tipo de filtrado es necesario:

	state: () => ({
		filtrosGrupos: {
			lugaresExperiencia: [],
			precios: [],
			festivales: [],
			//1. Crear una nueva key que conservara los valores del filtro seleccionado
		},
		viajesFiltradosInicial: [],
		viajesFiltradosAplicado: [],
	}),

	actions: {
		restartStore() {
			this.filtrosGrupos = {
				lugaresExperiencia: [],
				festivales: [],
				precios: [],
				//2. Agregar la key del filtro en su valor inicial para poder reiniciarlo
			};
			this.viajesFiltradosInicial = [];
			this.viajesFiltradosAplicado = [];
		},

		setViajesFiltradosInicial(listaViajes) {
			this.restartStore();
			this.viajesFiltradosInicial = listaViajes;
		},
		setFiltroLugaresExperiencia(listaLugaresExperiencia) {
			this.filtrosGrupos.lugaresExperiencia = listaLugaresExperiencia;
		},
		setFiltroPrecios(listaPrecio) {
			this.filtrosGrupos.precios = listaPrecio;
		},
		setFiltroFestivales(listaFestivales) {
			this.filtrosGrupos.festivales = listaFestivales;
		},
		aplicarFiltros(queryGrupo = null) {
			//Control si en caso se aplique un solo filtro no se ejecutara el control de solo mostrar los repetidos en
			// la variable de viajesSengunFiltro
			let cantidadFiltros = 0;
			Object.keys(this.filtrosGrupos).forEach(key => this.filtrosGrupos[key].length > 0 && cantidadFiltros++);
			// let aplicaSoloUnFiltro = cantidadFiltros == 1

			let viajesFiltradosAux = {
				filtradoLugaresExperiencia: [],
				filtradoPrecio: [],
				filtradoFestivales: [],
				//3.Crear una nueva key que conservara la lista de viajes filtrado por ese filtro en particular
			};

			//4. Agregar la logica de filtrado para el filtro en particular y almacenarlo en la key creada en viajesFiltradosAux
			// Filtrado por lugares de experiencia
			if (this.filtrosGrupos.lugaresExperiencia.length > 0) {
				viajesFiltradosAux.filtradoLugaresExperiencia = this.viajesFiltradosInicial.filter(viaje => {
					return this.filtrosGrupos.lugaresExperiencia.some(({ codigo }) => {
						return viaje.lugarExperiencia.codigo === codigo;
					});
				});
			}

			// Filtrado por precio
			if (this.filtrosGrupos.precios.length > 0) {
				const [minPrecio, maxPrecio] = this.filtrosGrupos.precios;
				viajesFiltradosAux.filtradoPrecio = this.viajesFiltradosInicial.filter(viaje => {
					return viaje.precioDesde >= minPrecio && viaje.precioDesde <= maxPrecio;
				});
			}

			// Filtrado por festivales (NO BORRAR pendiente actualizacion del componente)
			// if (this.filtrosGrupos.festivales.length > 0) {
			// 	viajesFiltradosAux.filtradoFestivales = this.viajesFiltradosInicial.filter(viaje => {
			// 		return this.filtrosGrupos.festivales.some(({ nombreFestival }) => {
			// 			return viaje.festival === nombreFestival;
			// 		});
			// 	});
			// }

			//5. Concatenar el valor de la nueva key de viajesFiltradosAux
			let concatViajes = viajesFiltradosAux.filtradoLugaresExperiencia.concat(viajesFiltradosAux.filtradoPrecio);

			let viajesSegunFiltro = [];
			let auxLista = [];

			switch (cantidadFiltros) {
				case 1:
					viajesSegunFiltro = concatViajes;
					break;
				case 2:
					viajesSegunFiltro = concatViajes.filter((viaje, index) => concatViajes.indexOf(viaje) !== index);
					break;
				case 3:
					auxLista = concatViajes.filter((viaje, index) => concatViajes.indexOf(viaje) !== index);
					auxLista = auxLista.filter((viaje, index) => auxLista.indexOf(viaje) !== index);
					viajesSegunFiltro = auxLista;
					break;
				default:
					viajesSegunFiltro = this.viajesFiltradosInicial;
			}

			this.viajesFiltradosAplicado = ordernarViajes(viajesSegunFiltro, queryGrupo);
		},
	},
});
