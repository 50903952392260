<template>
	<div
		v-if="
			(action == 'Cancel' && informacionReserva.estado != 'CANCELADA') ||
			(action == 'Recover' && informacionReserva.estado == 'CANCELADA') ||
			action == 'Pay'
		"
		class="card_pago text-primary w-100">
		<div class="pt-2 px-3">
			<div class="pb-2">
				<label
					class="w-100 d-flex align-items-center justify-content-between text-end"
					:class="{ 'fw-bold': informacionReserva.nombreUsuario }">
					<span class="text-primary">{{ informacionReserva.nombreUsuario }}</span>
				</label>
			</div>
			<hr class="line-divider mt-0" />
		</div>
		<div class="col-12">
			<div class="p-3 pt-0 m-0 fw-normal text-primary text-detalle">
				<template v-if="action == 'Cancel' && informacionReserva.estado != 'CANCELADA'">
					<template v-if="informacionReserva.totalPagado != informacionReserva.precioTotal">
						<div class="row w-100">
							<div class="col">
								{{ $t('reserva.totalPagado') }}
							</div>
							<div class="col-auto text-secondary fw-bold">{{ informacionReserva.totalPagado }} €</div>
						</div>
						<div class="row w-100">
							<div class="col">
								<i18n-t keypath="reserva.gastoCancelacion" tag="div">
									<template #fechaHoy>
										<span>{{ todayDate }}</span>
									</template>
								</i18n-t>
							</div>
							<div class="col-auto text-secondary fw-bold">{{ gastoCancelacion }} €</div>
						</div>

						<div class="row w-100 pt-2">
							<div class="col">
								{{ $t('reserva.saldoPendiente') }}
							</div>
							<div class="col-auto text-secondary fw-bold">
								{{ calculateTotal(informacionReserva.totalPagado, gastoCancelacion) }} €
							</div>
						</div>
					</template>
					<template v-else>
						<div class="row w-100">
							<div class="col">
								{{ $t('reserva.pagoTotal') }}
							</div>
							<div class="col-auto text-secondary fw-bold">{{ roundNumbers(informacionReserva.totalPagado, 2) }} €</div>
						</div>
						<div class="row w-100">
							<div class="col">
								<i18n-t keypath="reserva.gastoCancelacion" tag="div">
									<template #fechaHoy>
										<span>{{ todayDate }}</span>
									</template>
								</i18n-t>
							</div>
							<div class="col-auto text-secondary fw-bold">{{ gastoCancelacion }} €</div>
						</div>

						<div class="row w-100 pt-2">
							<div class="col">
								{{ $t('reserva.totalDevolver') }}
							</div>
							<div class="col-auto text-secondary fw-bold">
								{{ roundNumbers(informacionReserva.totalPagado - gastoCancelacion, 2) }} €
							</div>
						</div>
					</template>
				</template>
				<template v-else-if="(action == 'Recover' && informacionReserva.estado == 'CANCELADA') || action == 'Pay'">
					<div class="row w-100 pt-2">
						<div class="col fw-bold pb-2">
							{{ $t('reserva.paqueteBasico') }}
						</div>
						<div class="col-auto text-secondary fw-bold">
							{{ roundNumbers(informacionReserva.precioBasico, 2).toFixed(2) }} €
						</div>
					</div>

					<div v-for="incluye in grupo.viaje.incluyes" :key="incluye.id" class="row w-100">
						<div class="col">
							{{ incluye.nombre }}
						</div>
					</div>
					<div v-for="servicio in informacionReserva.servicios" :key="servicio.id" class="row w-100">
						<template v-if="!servicio.nulo">
							<div class="col">
								{{ servicio.nombre }}({{ servicio.numDias }}
								<span v-if="servicio.numDias === 1"> {{ $t('general.dia') }}</span>
								<span v-else> {{ $t('general.dias') }}</span
								>)
							</div>

							<div v-if="servicio.precio !== 0" class="col-auto text-secondary fw-bold">
								<span>{{ servicio.precio.toFixed(2) }} €</span>
							</div>
						</template>
					</div>

					<template
						v-if="
							(informacionReserva.tasasInternas.length > 0 && grupo.viaje.repercutirFee) ||
							informacionReserva.gastos.length > 0
						">
						<div class="col-12 fw-bold text-primary py-2">
							{{ $t('reserva.suplementosGastos') }}
						</div>
						<div v-for="gasto in informacionReserva.gastos" :key="gasto.id" class="row w-100">
							<div class="col">
								{{ gasto.concepto }}
							</div>

							<div class="col-auto text-secondary fw-bold">
								<template v-if="gasto.importe !== 0">
									<span>{{ gasto.importe.toFixed(2) }} €</span>
								</template>
							</div>
						</div>
						<template v-if="grupo.viaje.repercutirFee">
							<div v-for="tasa in informacionReserva.tasasInternas" :key="tasa.id" class="row w-100">
								<div class="col">
									{{ $t(`reserva.${tasa.concepto}`) }}
								</div>

								<div class="col-auto text-secondary fw-bold">
									<template v-if="tasa.importe !== 0">
										<span>{{ tasa.importe.toFixed(2) }} €</span>
									</template>
								</div>
							</div>
						</template>
					</template>

					<template v-if="informacionReserva.complementos && informacionReserva.complementos.length > 0">
						<div class="col-12 fw-bold text-primary py-2">
							{{ $t('reserva.complementos') }}
						</div>
						<div v-for="complemento in informacionReserva.complementos" :key="complemento.id" class="row w-100">
							<div class="col">
								{{ $t(`reserva.gastos.${complemento.concepto}`) }}
							</div>
							<div class="col-auto text-secondary fw-bold">
								<template v-if="complemento.importe !== 0">
									<span>{{ complemento.importe.toFixed(2) }} €</span>
								</template>
							</div>
						</div>
					</template>
					<template v-if="informacionReserva.pagos.length > 0 && !fromMisReservas">
						<div class="col-12 fw-bold text-primary py-2">{{ $t('general.pagos') }}</div>
						<div v-for="p in informacionReserva.pagos" :key="p" class="row w-100">
							<div class="col">{{ p.concepto }}</div>

							<div class="col-auto text-secondary fw-bold">
								<template v-if="p.cantidad !== 0">
									<span>- {{ p.cantidad.toFixed(2) }} €</span>
								</template>
							</div>
						</div>
					</template>
					<hr class="line-divider pt-2" />
					<div v-if="!fromMisReservas" class="row w-100 text-description">
						<div class="col-6">{{ $t('subtitulo.total') }}</div>
						<div class="col text-end">
							{{ Number(informacionReserva.precioTotal - informacionReserva.totalPagado).toFixed(2) }} €
						</div>
					</div>
					<div v-else class="row w-100 text-description">
						<div class="col-6 fw-bold">{{ $t('subtitulo.total') }}</div>
						<div class="col text-end fw-bold text-secondary">
							{{ Number(informacionReserva.precioTotal).toFixed(2) }} €
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref, watch } from 'vue';
	import { normalizeDate } from '@/helpers/dateUtiles';
	import { roundNumbers } from '@/helpers/numberUtils';

	const props = defineProps({
		reserva: {
			type: Object,
			default: () => ({}),
		},
		grupo: {
			type: Object,
			default: () => ({}),
		},
		reset: {
			type: Boolean,
			default: false,
		},

		action: {
			type: String,
			default: '',
		},

		fromMisReservas: {
			type: Boolean,
			default: false,
		},
	});

	const cardOpen = ref(false);
	const addToPay = ref(true);
	const informacionReserva = computed(() => props.reserva);
	const valorReset = computed(() => props.reset);

	const formatDate = date => {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [day, month, year].join('-');
	};

	const todayDate = computed(() => formatDate(new Date()));

	const gastoCancelacion = computed(() => {
		if (!props.grupo.politicaCancelacion) return 0;
		const hoy = normalizeDate(new Date());

		const tramos = props.grupo.politicaCancelacion.tramos || [];
		if (tramos.length === 0) return 0;

		let ultimoTramoFechaFin = normalizeDate(tramos[tramos.length - 1].fechaFin);

		for (let tramo of tramos) {
			const inicio = normalizeDate(tramo.fechaInicio);
			const fin = normalizeDate(tramo.fechaFin);

			if (hoy >= inicio && hoy <= fin) {
				return tramo.porcentaje > 0
					? roundNumbers(Number((informacionReserva.value.precioTotal * tramo.porcentaje) / 100), 2)
					: 0;
			}
		}
		if (hoy > ultimoTramoFechaFin) {
			return roundNumbers(Number(informacionReserva.value.precioTotal), 2);
		}

		return 0;
	});

	function calculateTotal(totalPagado, gastoCancelacion) {
		if (totalPagado < gastoCancelacion) {
			return roundNumbers(gastoCancelacion - totalPagado, 2);
		} else {
			return roundNumbers(totalPagado - gastoCancelacion, 2);
		}
	}

	watch(valorReset, () => {
		cardOpen.value = false;
		addToPay.value = false;
	});
</script>

<style lang="scss" scoped>
	.card_pago {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(33, 36, 68, 0.25);
	}

	.no-rotated {
		transition: 0.3s ease-in-out;
	}
	.rotated {
		transition: 0.3s ease-in-out;
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
	}

	.card-content {
		margin-top: 1em;
		border-radius: var(--bs-border-radius-lg);
		background-color: #f9f7f8;
	}

	.text-detalle {
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
</style>
