import {ESTADOS_RESERVA} from '@/constants/reservaConstants';
import {max} from '@popperjs/core/lib/utils/math';
import currency from 'currency.js';

export function calculateTotalPendiente(viaje, reservas) {
	if (viaje.hasPagoDiferido) return 0;
	let totalPagadoAux = currency(0);
	let precioTotalAux = currency(0);
	reservas
		.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA)
		.forEach(({ totalPagado, precioTotal }) => {
			totalPagadoAux = totalPagadoAux.add(totalPagado);
			precioTotalAux = precioTotalAux.add(precioTotal);
		});

	return max(precioTotalAux.subtract(totalPagadoAux).value, 0);
}

export function occupantsManagedReservations(grupo) {
	return grupo.reservas
		?.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA)
		.map(reserva => {
			const { nombre, apellidos, id } = reserva;
			return { nombre, apellido: apellidos, id };
		});
}

export function occupantsNotManagedReservations(grupo) {
	return grupo.ocupantesNoGestionados?.map(ocupante => {
		const { nombre, apellidos, id } = ocupante;
		return { nombre, apellido: apellidos, done: true, id };
	});
}
