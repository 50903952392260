<template>
	<div class="card-alojamiento">
		<div class="nombre-alojamiento fixed-header">
			<i class="fa fa-home home-icon me-2"></i>
			{{ roomingData.alojamiento }}
			({{ totalIntegrantes }}/{{ totalPlazas }})
		</div>
		<div class="contenido">
			<div class="habitaciones">
				<div v-for="(habitacion, index) in roomingData.habitaciones" :key="index" class="card-habitaciones">
					<CardHabitacion
						:capacidad="habitacion.numPlazas"
						:integrantes="habitacion.integrantes"
						:nombre="index"
						:alojamiento="roomingData"
						:id-habitacion="habitacion.id"
						:es-editando="esEditando(habitacion.id)"
						:expanded-habitacion-id="expandedHabitacionId"
						@agregar-integrante="agregarIntegrante(index)"
						@is-editing="isEditing"
						@eliminar-integrante="eliminarIntegrante"
						@toggle-expand="toggleExpand" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import CardHabitacion from '@/components/card/rooming/CardHabitacion.vue';
	import { computed } from 'vue';

	const props = defineProps({
		roomingData: {
			type: Object,
			default: () => ({ habitaciones: [] }),
		},
		usuarios: {
			type: Array,
			required: true,
		},
		alojamientoEditando: {
			type: String,
			default: '',
		},
		habitacionEditando: {
			type: Number,
			default: 0,
		},
		expandedHabitacionId: {
			type: String,
			default: '',
		},
	});

	const emit = defineEmits(['actualizar', 'is-editing', 'eliminar-integrante', 'toggle-expand']);

	const totalPlazas = computed(() => {
		return (props.roomingData.habitaciones || []).reduce((total, habitacion) => total + habitacion.numPlazas, 0);
	});

	const totalIntegrantes = computed(() => {
		return (props.roomingData.habitaciones || []).reduce(
			(total, habitacion) => total + habitacion.integrantes.length,
			0
		);
	});

	function agregarIntegrante(index) {
		const nuevasHabitaciones = [...props.roomingData.habitaciones];
		const habitacion = nuevasHabitaciones[index];
		if (habitacion.integrantes.length < habitacion.numPlazas) {
			habitacion.integrantes.push(`Nuevo Integrante ${habitacion.integrantes.length + 1}`);
			emit('actualizar', nuevasHabitaciones);
		}
	}

	function isEditing(isEditing, idAlojamiento, idHabitacion) {
		emit('is-editing', isEditing, idAlojamiento, idHabitacion);
	}

	function eliminarIntegrante(idHabitacion, integrante) {
		emit('eliminar-integrante', idHabitacion, integrante);
	}

	function esEditando(idHabitacion) {
		return props.alojamientoEditando === props.roomingData.nombre && props.habitacionEditando === idHabitacion;
	}

	function toggleExpand(habitacionId) {
		const expandedId = `${props.roomingData.nombre}-${habitacionId}`;
		emit('toggle-expand', expandedId);
	}
</script>

<style scoped lang="scss">
	.card-alojamiento {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(var(--bs-primary-rgb), 0.5);
		background: var(--Gris, #f9f7f8);
		margin-bottom: 10px;

		overflow-y: auto;
		position: relative;
	}

	.fixed-header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		background: var(--Gris, #f9f7f8);
		padding: 10px;
		z-index: 1;
		display: flex;
		align-items: center;
		border-bottom: 0.5px solid var(--bs-primary);
	}

	.nombre-alojamiento {
		font-size: 20px;
		font-weight: 600;
		color: var(--bs-primary);
		@media (max-width: 768px) {
			font-size: 13px;
		}
	}

	.home-icon {
		color: var(--bs-primary);
		margin-right: 8px;
		width: 20px;
	}

	.contenido {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 10px;
	}

	.habitaciones {
		width: 100%;
	}

	.card-habitaciones {
		margin-bottom: 10px;
	}

	.card-alojamiento::-webkit-scrollbar {
		width: 8px;
	}

	.card-alojamiento::-webkit-scrollbar-thumb {
		border-radius: var(--bs-border-radius-sm);
		background: rgba(var(--bs-primary-rgb), 0.2);
	}

	:deep(.icono-ocupado) {
		font-size: 14px;
	}
</style>
