<template>
	<div class="row d-flex flex-column flex-md-row">
		<div class="col col-md-3">
			<select
				v-model="currentFirstFilter"
				class="form-select selector-servicio text-primary"
				@change="firstFilterChangeHandler">
				<option :value="null">
					{{ servicioNulo.nombre }}
				</option>
				<option v-for="firstFilter in filtros.firstFilter" :key="firstFilter.id" :value="firstFilter">
					{{ firstFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentFirstFilter" class="col col-md-3 mt-2 mt-md-0">
			<select v-model="currentSecondFilter" class="form-select text-primary" @change="secondFilterChangeHandler">
				<option :value="null" selected disabled>
					{{ $t('filtroModalidades.titulo.seleccionaGama') }}
				</option>
				<option v-for="secondFilter in currentFirstFilter.secondFilter" :key="secondFilter.id" :value="secondFilter">
					{{ secondFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentSecondFilter" class="col col-md-6 mt-2 mt-md-0">
			<select
				v-model="servicioSeleccionado"
				class="form-select text-primary"
				:disabled="!isServicioChangeable(reserva, servicioSeleccionado)"
				@change="servicioSeleccionado.justSelected = true">
				<option :value="null" selected disabled>
					{{ $t('filtroModalidades.titulo.seleccionaServicio') }}
				</option>
				<option
					v-for="servicio in serviciosFiltrados.filter(s => s.activo)"
					:key="servicio.id"
					:value="servicio"
					:disabled="!isServicioSelectable(reserva, servicio)">
					{{ servicio.nombre }} ({{ dateMonthNumbers(servicio.fechaInicio) }} al
					{{ dateMonthNumbers(servicio.fechaFin) }})
					<span v-if="servicio.precio > 0">(+</span>
					<span v-if="servicio.precio">
						<span v-if="servicio.precio < 0">(</span>
						{{ servicio.precio }}€)
					</span>
				</option>
			</select>
		</div>
		<div class="row text-primary mb-2 description-text">
			<template v-if="servicioSeleccionado && !servicioSeleccionado.nulo">
				<div class="col-12">
					{{ servicioSeleccionado.descripcion }}
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { ref, watch, onBeforeMount } from 'vue';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { isServicioSelectable, isServicioChangeable } from '@/helpers/serviciosUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const reserva = storeHabitacion.getReservaByIdx(props.idxReserva);
	const filtros = storeHabitacion.filtros[props.categoriaServicios.categoria];
	const servicioNulo = props.categoriaServicios.servicios.find(({ nulo }) => nulo);
	const currentFirstFilter = ref(null);
	const currentSecondFilter = ref(null);
	const serviciosFiltrados = ref([]);
	const servicioSeleccionado = ref(null);

	function initForPaqueteBasico() {
		let servicioBasico = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico);
		initFiltersForServicio(servicioBasico);
		filterServicios();
		servicioSeleccionado.value = servicioBasico;
	}

	function initForReservaCurrentServicio() {
		let reservaCurrentServicio = reserva.servicios[props.categoriaServicios.categoria];
		if (!reservaCurrentServicio.nulo) {
			initFiltersForServicio(reservaCurrentServicio);
			filterServicios();
		}
		servicioSeleccionado.value = reservaCurrentServicio;
	}

	function initFiltersForServicio(servicio) {
		currentFirstFilter.value = filtros.firstFilter.find(({ id }) => id === servicio.firstFilter.id);
		currentSecondFilter.value = currentFirstFilter.value.secondFilter.find(({ id }) => id === servicio.secondFilter.id);
	}

	function filterServicios() {
		serviciosFiltrados.value = props.categoriaServicios.servicios.filter(servicio => {
			return (
				servicio.firstFilter.id === currentFirstFilter.value.id &&
				servicio.secondFilter.id === currentSecondFilter.value.id &&
				!servicio.nulo
			);
		});
	}

	function addServicioToReserva() {
		if (servicioSeleccionado.value == null) {
			storeHabitacion.addServicioToReserva(props.idxReserva, servicioNulo);
			emits('servicioSeleccionado', servicioNulo);
		} else {
			storeHabitacion.addServicioToReserva(props.idxReserva, servicioSeleccionado.value);
			emits('servicioSeleccionado', servicioSeleccionado.value);
		}
	}

	function firstFilterChangeHandler() {
		servicioSeleccionado.value = null;
		currentSecondFilter.value = null;
	}

	function secondFilterChangeHandler() {
		servicioSeleccionado.value = null;
		if (currentFirstFilter.value && currentSecondFilter.value) {
			filterServicios();
		}
	}

	function init() {
		if (reserva?.servicios[props.categoriaServicios.categoria] === undefined) {
			if (props.categoriaServicios.paqueteBasico) {
				initForPaqueteBasico();
			} else {
				servicioSeleccionado.value = servicioNulo;
			}
		} else {
			initForReservaCurrentServicio();
		}
		addServicioToReserva();
	}

	watch(servicioSeleccionado, () => {
		addServicioToReserva();
	});

	onBeforeMount(() => init());
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}
</style>
