<template>
	<div class="card bg-body" style="width: 300px" @click="goToInfo(oferta.id)">
		<div class="container-background">
			<template v-if="oferta.tipos[0] != undefined">
				<img
					v-if="oferta.tipos[0].nombre == 'familias'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/familia.webp"
					alt="Background viaje familias"
					title="Background viaje familias"
					class="background-image" />

				<img
					v-if="oferta.tipos[0].nombre == 'trabajadores'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/trabajo.webp"
					alt="Background viaje trabajadores"
					title="Background viaje trabajadores"
					class="background-image" />

				<img
					v-if="oferta.tipos[0].nombre == 'colegios'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/colegio.webp"
					alt="Background viaje colegios"
					title="Background viaje colegios"
					class="background-image" />
				<img
					v-if="oferta.tipos[0].nombre == 'universitarios'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/universidad.webp"
					alt="Background viaje universidad"
					title="Background viaje universidad"
					class="background-image" />
				<img
					v-if="oferta.tipos[0].nombre == 'premium'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/premium.webp"
					alt="Background viaje premium"
					title="Background viaje premium"
					class="background-image" />
				<p class="card-title">
					<i class="fa-solid fa-calendar fa-xl" style="color: var(--bs-primary)" />
					<span class="mx-3 text-primary fw-bolder">
						{{ oferta.fechaInicio.slice(8) }}-{{ oferta.fechaInicio.slice(5, 7) }} / {{ oferta.fechaFin.slice(8) }}-{{
							oferta.fechaFin.slice(5, 7)
						}}
					</span>
				</p>

				<img
					v-if="oferta.tipos[0].nombre == 'colegios'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/colegio.svg"
					alt="Icono viaje grupo colegios"
					title="Icono viaje grupo colegios"
					class="icon" />
				<img
					v-if="oferta.tipos[0].nombre == 'familias'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/familia2.svg"
					alt="Icono viaje grupo familias"
					title="Icono viaje grupo familias"
					class="icon" />
				<img
					v-if="oferta.tipos[0].nombre == 'universitarios'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/universidad.svg"
					alt="Icono viaje grupo universitarios"
					title="Icono viaje grupo universitarios"
					class="icon" />
				<img
					v-if="oferta.tipos[0].nombre == 'trabajadores'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/trabajo.svg"
					alt="Icono viaje grupo trabajadores"
					title="Icono viaje grupo trabajadores"
					class="icon" />
				<img
					v-if="oferta.tipos[0].nombre == 'premium'"
					src="~@/assets/home/Desktop/ViajesEnGrupo/premium.svg"
					class="icon"
					title="Icono viaje grupo premium"
					alt="Icono viaje grupo premium" />
			</template>
		</div>

		<div class="container-body p-3">
			<p class="text-primary fw-bold desdeText">
				{{ $t('general.desde') }}
			</p>
			<div class="bg-primary px-3 py-1 mx-auto price" style="width: min-content">
				<p class="card-subtitle my-2 fs-5 text-white">{{ oferta.precio }}€</p>
			</div>
			<p class="mb-0 mt-3 fw-bold">
				{{ $t('general.porPersona') }}
			</p>
			<p class="card-text text-primary fs-4 mt-0 fw-bold title">
				{{ oferta.nombre }}
			</p>

			<span class="mb-3 infor">
				<i class="fa-solid fa-circle-info text-primary" />
				{{ $t('general.masInformacionViaje') }}
			</span>
		</div>
	</div>
</template>

<script>
	import router from '@/router';
	import { GruposInformacionViaje } from '@/router/RouterNames';
	export default {
		props: {
			oferta: {
				type: Object,
				default: null,
			},
		},
		setup() {
			const goToInfo = id => {
				router.push({
					name: GruposInformacionViaje,
					params: { viajeId: id },
				});
			};
			return {
				goToInfo,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.carousel-content {
		max-width: 1440px;
		margin: auto;
	}

	.title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.card {
		cursor: pointer;
		border-radius: var(--bs-border-radius-xl);
		position: relative;
		box-shadow: 2px 5px 5px 1px rgba(0, 0, 0, 0.5);

		.icon {
			position: absolute;
			margin: auto;
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			color: var(--bs-primary);
			transition: 0.3s;
		}

		.infor {
			transition: 0.3s;
		}

		&:hover {
			.infor,
			.icon {
				color: var(--bs-secondary);
			}
		}
	}

	.container-background {
		position: relative;
		width: 300px;
		height: 150px;

		.background-image {
			width: 100%;
			height: 100%;
			border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;
			opacity: 0.5;
			object-fit: cover;
		}

		.card-title {
			margin: auto;
			position: absolute;
			top: 80px;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}

	.container-body {
		position: relative;
		.price {
			margin: auto;
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			border-radius: var(--bs-border-radius-xl);
			box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
		}

		.desdeText {
			margin: auto;
			position: absolute;
			top: -45px;
			left: 0;
			right: 0;

			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.12px;
		}
	}

	.home-card {
		&__image {
			border-radius: 5%;
			mix-blend-mode: normal;
			opacity: 0.8;
			margin: auto;
		}

		&__title {
			font-size: 21px;
			font-weight: 700;
			line-height: 25.6px;
		}

		&__description {
			font-size: 14px;
			line-height: 17px;
			color: #6f6e8e;
		}
	}
</style>
