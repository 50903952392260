export const ESTADOS_RESERVA = {
	BLOQUEADA: 'BLOQUEADA',
	RESERVADA: 'RESERVADA',
	PAGADA: 'PAGADA',
	CANCELADA: 'CANCELADA',
};

export const TIPO_CODIGO_DESCUENTO = {
	PORCENTAJE: 'PORCENTAJE',
};
