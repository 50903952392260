<template>
	<BaseCarousel
		:items-carousel="viajesDestacados"
		:is-loading="!viajesDestacados"
		:max-elements="1.2"
		:navigation-buttons="false">
		<template #title>
			<span class="text-primary">{{ offersTitle }}</span>
		</template>
		<template #itemCarousel="itemCarouselProps">
			<div>
				<TripCard :viaje-tarjeta="itemCarouselProps.data" hide-services :tipos-viaje="itemCarouselProps.data.tipos" />
			</div>
		</template>
	</BaseCarousel>
</template>

<script setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import TripCard from '@/components/card/TripCard/TripCard.vue';

	const { t } = useI18n();

	const props = defineProps({
		viajesDestacadosList: {
			type: Array,
			required: true,
			default: () => null,
		},
	});

	const viajesDestacados = computed(() => props.viajesDestacadosList);

	const offersTitle = computed(() => t('mejoresOfertas.titulo'));
</script>
