<template>
	<div class="wrapper">
		<div class="container">
			<div class="row">
				<div class="span4" />
				<div class="span4">
					<img class="center-block" src="~@/assets/img/not_found_ilustracion.webp" alt="" />
				</div>
				<div class="titulo">Ups...</div>
				<div class="titulo text-primary">
					{{ mensaje }}
				</div>
				<div class="span4" />
				<div class="d-flex justify-content-center">
					<router-link :to="{ name: 'home' }" class="btn-primary">
						{{ $t('botones.volver').toLocaleUpperCase() }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			mensaje: {
				type: String,
				default: function () {
					return '';
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/styles.scss';

	.center-block {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.wrapper {
		padding-top: 4rem;
		padding-bottom: 2rem;
	}

	.img-wrap {
		padding: 20px;
		width: 200px;
		height: 150px;
		position: relative;
		display: inline-block;
		overflow: hidden;
		margin: 0;
	}

	.titulo {
		font-size: 1rem;
		font-weight: 700;
		text-align: center;
	}

	img {
		max-width: 100%;
		height: auto;
		width: auto;
	}
</style>
