<template>
	<div class="p-3">
		<div class="row">
			<div class="col-12 col-lg-6">
				<select-service-individual
					selector="forfait"
					:servicios-disponibles="informacionMiembro.servicios.forfait"
					:servicio-predefinido="informacionMiembro.serviciosPredefinidos.forfait"
					@servicio-actualizado="actualizarServicio($event)" />
			</div>
			<div class="col-12 col-lg-6">
				<select-service-individual
					selector="restauracion"
					:servicios-disponibles="informacionMiembro.servicios.restauracion"
					:servicio-predefinido="informacionMiembro.serviciosPredefinidos.restauracion"
					@servicio-actualizado="actualizarServicio($event)" />
			</div>
		</div>

		<div class="row">
			<select-service-individual
				selector="transporte"
				:servicios-disponibles="informacionMiembro.servicios.transporte"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.transporte"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>
		<div class="row px-md-2">
			<select-service-subtypes
				selector="seguro"
				:servicios-disponibles="informacionMiembro.servicios.seguro"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.seguro"
				:id-viaje="idViaje"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>
		<div class="row px-md-2">
			<select-service-subtypes
				selector="actividad"
				:servicios-disponibles="informacionMiembro.servicios.actividad"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.actividad"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>
		<div class="row px-md-2">
			<select-service-subtypes
				selector="festival"
				:servicios-disponibles="informacionMiembro.servicios.festival"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.festival"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>

		<div class="row px-md-2">
			<select-service-subfilter
				selector="clases"
				:servicios-disponibles="informacionMiembro.servicios.clases"
				:servicios-filtro="informacionMiembro.filtros"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.clases"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>

		<div class="row px-md-2">
			<select-service-subfilter
				selector="alquiler"
				:servicios-disponibles="informacionMiembro.servicios.alquiler"
				:servicios-filtro="informacionMiembro.filtros"
				:servicio-predefinido="informacionMiembro.serviciosPredefinidos.alquiler"
				@servicio-actualizado="actualizarServicio($event)" />
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import SelectServiceSubtypes from '@/components/select/selectService/SelectServiceSubtypes.vue';
	import SelectServiceIndividual from '@/components/select/selectService/SelectServiceIndividual.vue';
	import SelectServiceSubfilter from '@/components/select/selectService/SelectServiceSubfilter.vue';

	export default defineComponent({
		components: {
			SelectServiceSubtypes,
			SelectServiceIndividual,
			SelectServiceSubfilter,
		},
		props: {
			indexPersona: {
				type: String,
				default: null,
			},
			informacionMiembro: {
				type: Object,
				default: null,
			},
			idViaje: {
				type: Number,
				default: null,
			},
		},
		emits: ['serviciosPersonaActualizado'],
		setup(props, { emit }) {
			let serviciosPersona = {};

			serviciosPersona['persona' + props.indexPersona] = {};

			const actualizarServicio = $event => {
				let keys = Object.keys($event);
				keys.map(key => {
					if ($event[key] == null) return;
					serviciosPersona['persona' + props.indexPersona][key] = $event[key];
				});
				emit('serviciosPersonaActualizado', serviciosPersona);
			};
			return { actualizarServicio };
		},
	});
</script>

<style scoped></style>
