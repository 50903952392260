<template>
	<div v-if="grupo">
		<div class="card-usuario">
			<div class="cardReserva-image">
				<div class="cardReserva-image__header">
					<div class="cardReserva-image__header__background">
						<img v-if="grupo.viaje.imagenExperiencia" :src="grupo.viaje.imagenExperiencia" />
						<img v-else src="@/assets/img/fondo_mygocu.png">
						<div v-if="showQrBonosButton" class="cardReserva-qr">
							<div class="col-auto text-end qr-content" @click="showModalQr = true">
								<QrIcon />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="cardReserva-image__body">
				<div class="cardReserva">
					<div class="cardReserva-body">
						<p class="text-primary title">
							{{ grupo.viaje.nombre }}
						</p>
						<div style="display: flex; align-items: center" class="text-primary sub-title">
							<LocationIcon :size="'20'" />
							<span class="px-2" style="text-align: -webkit-left">{{ grupo.viaje.lugarExperiencia }}</span>
						</div>

						<div
							class="my-2 text-primary sub-title pb-2"
							style="text-align: -webkit-left">
							<strong>{{ $t('searcher.date.title') }}:</strong>
							<span class="px-2" style="text-align: -webkit-left">{{ experienceDateRange }}</span>
						</div>
						<div class="shadow cardReserva-body___content">
						<div class="px-3 pt-3">
							<div class="text-primary text-bold row pointer">
								<div class="col fw-bold text-secondary pb-2" style="text-align: left">
									<span>
										{{ capitalizeFirstLetter($t('general.personas.plural.key')) }}
										({{ occupants.length }}<template v-if="hasHabs">/{{ grupo.habitacion.numPlazas }}</template>)
									</span>
								</div>

								<div class="col-3 text-end" @click="toggleCollapse">
									<button class="btn bg-transparent border-0">
										<i class="fa-solid fa-chevron-up" :class="isCollapsed ? 'icon-active' : 'icon-inactive'" />
									</button>
								</div>
							</div>
						</div>
						<transition name="collapse">
							<div v-show="!isCollapsed" class="collapse-content pb-2">
								<CardOccupancy
									:total-occupancies="hasHabs ? grupo.habitacion.numPlazas : -1"
									:embedded-display="true"
									:occupants-info="occupants"
									:plazas-libres-repercutidas="hasHabs ? grupo.habitacion.plazasLibresRepercutidas : false"/>
							</div>
						</transition>
						</div>
						<div class="text-bold text-secondary bg-primary text-total" style="padding: 13px 18px">
							<div class="row">
								<div class="col-7" style="text-align: left">{{ $t('subtitulo.importeTotal') }}</div>
								<div class="col text-end">{{ totalPrice }} €</div>
							</div>
						</div>
						<div
							class="cardReserva-footer text-bold text-primary bg-white text-total"
							style="padding: 13px 18px">
							<div class="row">
								<div class="col-7">{{ $t('reserva.pendientePago') }}</div>

								<div class="col text-end">{{ pricePendingPayment }} €</div>
							</div>
						</div>
					</div>
					<div class="mx-3 pb-4 text-center">
						<div class="row">
							<div
								class="col btn btn-primary w-100 m-0 mx-2"
								@click="goToDetalle">
								{{ t('botones.verDetalles') }}
							</div>
							<div
								v-if="showPagarButton"
								class="col btn btn-primary w-100 m-0 mx-2"
								@click="showModalPagar = true">
								{{ t('botones.pagar') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ModalPagarReserva :grupo="grupo" :modal-active="showModalPagar" @close-modal="showModalPagar = false" />

		<ModalQrReserva
			:grupo="grupo"
			:modal-active="showModalQr"
			@close-modal="showModalQr = false" />
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import CardOccupancy from '@/modules/viajesGrupos/components/CardOccupancy.vue';
	import { useRouter } from 'vue-router';
	import ModalPagarReserva from 'src/components/modales/Reserva/ModalPagarReserva';
	import { useI18n } from 'vue-i18n';
	import { capitalizeFirstLetter } from '@/helpers/filters';
	import { fomatDatesHyphen, convertStringToDate } from '@/helpers/dateUtiles';
	import QrIcon from 'src/components/Icons/QrIcon.vue';
	import { UsuarioProximasReservasDetalle } from '@/router/RouterNames';
	import LocationIcon from 'src/components/Icons/LocationIcon.vue';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import ModalQrReserva from 'src/components/modales/Reserva/ModalQrReserva';
	import {
		calculateTotalPendiente,
		occupantsManagedReservations,
		occupantsNotManagedReservations
	} from '@/services/utils/GrupoUtils';
	import currency from 'currency.js';

	const props = defineProps({
		grupo: {
			type: Object,
			required: true
		}
	});

	const { t } = useI18n();
	const router = useRouter();
	const isCollapsed = ref(true);

	const showModalPagar = ref(false);
	const showModalQr = ref(false);

	const pricePendingPayment = computed(() => calculateTotalPendiente(props.grupo.viaje, props.grupo.reservas));

	const showPagarButton = computed( () =>
		props.grupo.reservas.some(({ estado }) => estado !== ESTADOS_RESERVA.CANCELADA)
		&& pricePendingPayment.value > 0 && !props.grupo.viaje.hasPagoDiferido
	);

	const showQrBonosButton = computed( () =>
		props.grupo.reservas.some(reserva => reserva.servicios?.some(service => service.codigoValidacion && !service.nulo))
		&& props.grupo.reservas.some(({ estado }) => estado !== ESTADOS_RESERVA.CANCELADA)
		&& pricePendingPayment.value <= 0
	);

	const experienceDateRange = computed(() =>
		`${fomatDatesHyphen(convertStringToDate(props.grupo.viaje.fechaInicio))} - ${fomatDatesHyphen(convertStringToDate(props.grupo.viaje.fechaFin))}`
	);

	const totalPrice = computed( () =>
		props.grupo.reservas.reduce((acc, { precioTotal }) => acc.add(precioTotal), currency(0)).value
	);

	const hasHabs = computed( () => props.grupo.habitacion && !props.grupo.habitacion.isComun);

	const occupants = computed(() => {
		const ocupantesGestionados = occupantsManagedReservations(props.grupo) || [];
		const ocupantesNoGestionados = occupantsNotManagedReservations(props.grupo) || [];
		return [...ocupantesNoGestionados, ...ocupantesGestionados];
	});

	function toggleCollapse() {
		isCollapsed.value = !isCollapsed.value;
	}

	function goToDetalle() {
		router.push({ name: UsuarioProximasReservasDetalle, params: { grupoId: props.grupo.id } });
	}

</script>
<style lang="scss" scoped>
	.cardReserva-image {
		width: 100%;
		border-radius: var(--bs-border-radius-xl);
		height: 153px;

		&__footer {
			font-size: 0.8em;
		}

		.cardReserva-image__header {
			position: relative;
			height: 153px;

			&__background {
				height: 153px;
				overflow: hidden;
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;

				img {
					height: 153px;
					width: 100%;
					object-fit: cover;
				}
			}

			&__share {
				position: absolute;
				top: 10px;
				right: 10px;
			}

			.cardReserva-qr {
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
		}

		.cardReserva-image__body {
			margin-top: -1rem;
		}
	}

	.cardReserva {
		top: -33px;
		position: relative;
		background-color: #f9f7f8 !important;
		border: 0px;
		border-radius: var(--bs-border-radius-xl) !important;
		box-shadow: 0px 0px 10px 0px rgba(33, 36, 68, 0.15), 0px 1px 2px 1px rgba(33, 36, 68, 0.15) !important;

		.cardReserva-body {
			padding: 21px;

			&___content {
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0 !important;
			}

			.title {
				font-size: 22px;
				font-weight: 700;
				text-align: left;
				max-width: 220px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			.sub-title {
				color: #515151;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.cardReserva-footer {
			border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
			border-top: 0px;

			.rounded-circle {
				width: 30px;
				height: 30px;
				padding: 5px;
				margin-left: 12px;
			}
		}
	}

	.collapse-content {
		font-size: 15px;
		font-weight: 400;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.text-bold {
		font-size: 15px;
		font-weight: 700 !important;
	}

	.btn-link {
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.12px;
	}

	.text-description {
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.12px;
	}

	.text-total {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.12px;
	}

	.pl-1 {
		padding-left: 20px;
	}

	.btn-primary {
		font-size: 14px;
		font-weight: 500;
		place-content: center;
	}

	@media (max-width: 991.98px) {
		.text-bold,
		.btn-primary {
			font-size: 12px;
		}

		.text-description,
		.text-total,
		.btn-link {
			font-size: 11px;
		}
	}

	@media (min-width: 991.98px) {
		.card-usuario {
			max-width: 290px;
		}
	}

	.card-usuario {
		min-width: 270px;
		max-height: 90vh;
	}
</style>
