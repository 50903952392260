<template>
	<div v-if="storeGlobal.translations != {}" id="desktop-navbar" :class="{ fondoHeader: minimizedHeader }" class="header">
		<!-- <BannerPublicidad/> -->
		<div class="header-main">
			<div class="d-flex w-100 align-items-center">
				<div class="col-3 d-flex align-items-center justify-content-center h-100">
					<router-link v-show="isValidUrl || minimizedHeader" :to="{ name: 'home' }">
						<img
							id="desktop-img-logo"
							:class="{ onScrollIcon: minimizedHeader }"
							class="img-logo pointer py-2"
							alt="Logo"
							title="Logo"
							:src="storeGlobal.valores.img_logo_agencia" />
					</router-link>
				</div>
				<div class="col padding-right content-header">
					<div class="row align-items-center justify-content-end w-100">
						<div v-if="storeGlobal.permisos.rrss.showed" class="col-auto">
							<div class="hvr-grow social-icon">
								<a :href="instagram" target="_blank" class="bg-white bg-opacity-25 p-2 rounded-circle shadow" aria-label="Instragram link">
									<i class="fa-brands fa-instagram fa-lg" />
								</a>
							</div>
						</div>
						<div v-if="storeGlobal.permisos.rrss.showed" class="col-auto">
							<div class="hvr-grow social-icon">
								<a :href="tiktok" target="_blank" class="bg-white bg-opacity-25 p-2 rounded-circle shadow" aria-label="TikTok link">
									<i class="fa-brands fa-tiktok fa-lg" />
								</a>
							</div>
						</div>

						<div v-if="storeGlobal.permisos.contacto.showed" class="col-auto">
							<div class="caja-info shadow">
								L-V de 15:30-20:30
							</div>
						</div>
						<div class="col-auto">
							<template v-if="windowLocation == 'https://skiandnight.com'">
								<a href="https://wa.me/+34910054547" target="_blank">
									<div class="caja-info shadow pointer hvr-grow social-icon">
										<i class="fa-brands fa-whatsapp fa-xl"/>
										<span class="mx-2">
											{{ phone }}
										</span>
									</div>
								</a>
							</template>
							<template v-else-if="windowLocation.includes('bodosound')">
								<a href="https://wa.me/+34636561081" target="_blank">
									<div class="caja-info shadow pointer hvr-grow social-icon">
										<i class="fa-brands fa-whatsapp fa-xl" />
										<span class="mx-2">
											+34 636 561 081
										</span>
									</div>
								</a>
							</template>
							<template v-else>
								<div class="caja-info shadow social-icon">
									<i class="fa-solid fa-phone fa-xl"/>
									<span class="mx-2">
										+34 910 053 388
									</span>
								</div>
							</template>
						</div>
						<div class="col-auto">
							<div class="dropdown">
								<button
									id="lanDropdownMobile"
									class="btn caja-info shadow pointer hvr-grow social-icon"
									type="button"
									aria-label="Language button dropdown"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<img :src="languages[locale].icon" width="20" height="20" alt="" />
								</button>
								<ul class="dropdown-menu p-3 shadow-lg" aria-labelledby="lanDropdown">
									<div class="">
										<div v-for="a in availableLanguages" :key="a">
											<li
												@click="
													seleccionLang(a);
												">
												<a href="#" class="dropdown-item">
													<img :src="languages[a].icon" width="20" height="20" alt="" />
													<span class="mx-1">
														{{ $t('langs.' + languages[a].code) }}
													</span>
												</a>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</div>

						<div class="col-auto pe-0">
							<div class="dropdown icon-perfil-container">
								<button
									id="userDropdown"
									class="icon-perfil"
									:class="isUsuarioLogged ? ' border border-secondary' : ''"
									type="button"
									aria-label="User dropdown button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span v-if="isUsuarioLogged" class="text-secondary fs-6 fw-bolder rounded-circle">
										{{ nombreUsuario[0].toUpperCase() }}
									</span>
									<i v-else class="fa-regular fa-user fa-lg" />
								</button>

								<ul id="userDrop" class="dropdown-menu p-3 shadow-lg" aria-labelledby="userDropdown">
									<div class="">
										<div>
											<li>
												<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
													<u>
														{{ $t('header.user.title') }}
													</u>
												</p>
											</li>
											<template v-if="!isUsuarioLogged">
												<li>
													<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.login') }}</span>
												</li>
												<li>
													<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.signIn') }}</span>
												</li>
											</template>

											<template v-else>
												<li>
													<router-link
														class="dropdown-item"
														:to="{
															name: UsuarioProximasReservas,
														}">
														{{ $t('reserva.misReservas') }}
													</router-link>
												</li>
												<li v-if="hasRoleGestorRooming">
													<router-link
														class="dropdown-item"
														:to="{
															name: UsuarioRooming,
														}">
														{{ $t('rooming.miRooming') }}
													</router-link>
												</li>
												<!-- <li>
													<router-link
														class="dropdown-item"
														:to="{ name: 'home' }"
														>Mis Clientes</router-link
													>
												</li>
												 <li>
													<router-link
														class="dropdown-item"
														:to="{ name: 'home' }"
														>Mis buses</router-link
													>
												</li> -->
												<li>
													<router-link class="dropdown-item" :to="{ name: 'perfil' }">
														{{ $t('general.miPerfil') }}
													</router-link>
												</li>
												<li v-if="rolesUsuario.includes(rolComercial)">
													<router-link
														class="dropdown-item"
														:to="{
															name: 'MisClientes',
														}">
														{{ $t('subtitulo.misViajeros') }}
													</router-link>
												</li>
												<li class="dropdown-item" @click="botonLogout()">
													{{ $t('general.cerrarSesion') }}
												</li>
											</template>
										</div>
									</div>
								</ul>
							</div>
						</div>
					</div>
					<div :class="{ onScrollHide: minimizedHeader }" class="row nav justify-content-end align-items-center">
						<div v-if="storeGlobal.permisos.filtro_viajes.showed" class="col-auto px-0">
							<div class="dropdown">
								<button
									id="skiDropdown"
									class="btn bg-transparent border-0 shadow-none item-header"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span
										v-if="storeGlobal.translations[locale]"
										class="mx-3">
										{{ storeGlobal.translations[locale].header.ski }}
									</span>
								</button>
								<ul class="dropdown-menu p-3 shadow-lg" style="width: max-content" aria-labelledby="skiDropdown">
									<div class="d-flex justify-content-around">
										<div class="row">
											<div class="col">
												<div v-if="storeGlobal.permisos.viajes.grupos.showed">
													<li>
														<p
															class="fw-bold border-bottom-2 px-3 py-1 fs-5 item-title text-decoration-underline">
															{{ $t('header.ski.groups.title') }}
														</p>
													</li>
													<template v-for="grupo in listaGruposHeader" :key="grupo.nombre">
														<li>
															<router-link
																:to="{
																	name: ViajesGrupoCatalogo,
																	query: {
																		grupo: grupo.nombre,
																	},
																}"
																class="dropdown-item"
																href="#">
																{{ grupo.descripcion }}
															</router-link>
														</li>
													</template>
												</div>
											</div>
											<div class="col">
												<ul v-if="storeGlobal.permisos.viajes.buscador.showed">
													<p
														class="fw-bold border-bottom-2 pe-3 py-1 fs-5 item-title text-decoration-underline">
														{{ $t('general.viajeAMedida') }}
													</p>
													<div v-if="filtrosBuscador" class="row justify-content-start py-2">
														<div v-for="(area, index) in filtrosBuscador.areas" :key="index" class="col-6">
															<div
																class="fw-bold text-clickable"
																@click="setDestino(area.nombre, area.id, -1)">
																{{ capitalizeFirstLetter(area.nombre) }}
															</div>
															<div
																v-for="lugarExperiencia in area.lugaresExperiencia"
																:key="lugarExperiencia.nombre"
																role="button">
																<div
																	class="buscador-subtitulo text-clickable"
																	@click="setDestino(lugarExperiencia.nombre, area.id, lugarExperiencia.id)">
																	{{ capitalizeFirstLetter(lugarExperiencia.nombre) }}
																</div>
															</div>
														</div>
													</div>
												</ul>
											</div>
										</div>
									</div>
								</ul>
							</div>
						</div>
						<div class="col-auto px-0">
							<div class="dropdown">
								<button
									v-if="
										(storeGlobal.permisos.night.festivales.showed || storeGlobal.permisos.night.apreski.showed) &&
										storeGlobal.translations[locale]
									"
									id="nightDropdown"
									class="btn bg-transparent border-0 shadow-none item-header"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span class="mx-3">
										{{ storeGlobal.translations[locale].header.night }}
									</span>
								</button>
								<ul class="dropdown-menu p-3 shadow-lg" aria-labelledby="nightDropdown">
									<div class="d-flex">
										<div v-if="storeGlobal.permisos.night.festivales.showed">
											<li>
												<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
													<u>
														{{ $t('header.night.festivals.title') }}
													</u>
												</p>
											</li>
											<!-- <li class="ps-3">Proximamente...</li> -->
											<li v-for="festival in festivales" :key="festival" data-bs-dismiss="modal">
												<a href="#" class="dropdown-item" @click="goToFestival(festival.uri)">
													{{ capitalizeFirstLetter(festival.titulo) }}
												</a>
											</li>
										</div>

										<div v-if="storeGlobal.permisos.night.apreski.showed">
											<li>
												<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
													<u>
														{{ $t('header.night.apres.title') }}
													</u>
												</p>
											</li>
											<li class="row justify-content-between">
												<template v-for="lugarExperiencia in apresSkiHeader.slice(0, 4)" :key="lugarExperiencia.id">
													<div class="ps-3 fw-bold text-primary">
														{{ capitalize(lugarExperiencia.lugarExperiencia) }}

														<div v-for="apre in lugarExperiencia.apresSkis" :key="apre.id" class="dropdown-item">
															<router-link
																:to="{
																	name: 'ApresSkisDetalle',
																	params: {
																		apresSkiId: apre.id,
																	},
																}">
																{{ apre.nombre }}
															</router-link>
														</div>
													</div>
												</template>
											</li>
											<li class="list-group-item border-0" data-bs-dismiss="modal">
												<router-link
													class="dropdown-item fw-bold text-primary"
													:to="{
														name: 'ApresSkisCatalogo',
													}">
													{{ $t('botones.verTodos') }}
												</router-link>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</div>
						<div v-if="storeGlobal.permisos.nosotros.showed" class="col-auto px-0">
							<div class="dropdown">
								<button
									id="usDropdown"
									class="btn bg-transparent border-0 shadow-none item-header"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span class="mx-3 text-uppercase">
										{{ $t('header.us.title') }}
									</span>
								</button>
								<ul class="dropdown-menu shadow-lg" aria-labelledby="usDropdown">
									<div class="">
										<div>
											<li>
												<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
													<u>Ski & Night</u>
												</p>
											</li>
											<li>
												<router-link
													class="dropdown-item"
													:to="{
														name: 'QuienesSomos',
													}">
													{{ $t('header.us.whoWeAre') }}
												</router-link>
											</li>
											<li>
												<router-link
													class="dropdown-item"
													:to="{
														name: 'TrabajaConNosotros',
													}">
													{{ $t('header.us.work') }}
												</router-link>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</div>

						<div v-if="storeGlobal.permisos.blog.showed" class="col-auto px-0">
							<button class="btn bg-transparent border-0 shadow-none item-header" type="button">
								<router-link :to="{ name: 'BlogLanding' }">
									<span class="mx-3 text-uppercase"> Blog </span>
								</router-link>
							</button>
						</div>
					</div>
				</div>

				<div class="col justify-content-end m-auto mobile-buttons">
					<button
						class="btn-primary bg-transparent hvr-grow btn-menu"
						type="button"
						data-bs-toggle="modal"
						data-bs-target="#mobile-menu">
						<i class="fa-solid fa-bars fa-lg" />
					</button>
					<div class="dropdown mx-2 icon-perfil-container">
						<button
							id="userDropdownMobile"
							class="icon-perfil"
							:class="isUsuarioLogged ? ' border border-secondary' : ''"
							type="button"
							aria-label="User dropdown button"
							data-bs-toggle="dropdown"
							aria-expanded="false">
							<span v-if="isUsuarioLogged" class="text-secondary fs-5 fw-bolder rounded-circle">
								{{ nombreUsuario[0] }}
							</span>

							<i v-else class="fa-regular fa-user fa-sm" />
						</button>

						<ul id="userDropMobile" class="dropdown-menu p-3 shadow-lg" aria-labelledby="userDropdownMobile">
							<div class="">
								<div>
									<li>
										<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
											<u>{{ $t('header.user.title') }}</u>
										</p>
									</li>
									<template v-if="!isUsuarioLogged">
										<template v-if="!isUsuarioLogged">
											<li>
												<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.login') }}</span>
											</li>
											<li>
												<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.signIn') }}</span>
											</li>
										</template>
									</template>

									<template v-else>
										<li>
											<router-link
												class="dropdown-item"
												:to="{
													name: UsuarioProximasReservas,
												}">
												{{ $t('reserva.misReservas') }}
											</router-link>
										</li>
										<li v-if="hasRoleGestorRooming">
											<router-link
												class="dropdown-item"
												:to="{
													name: UsuarioRooming,
												}">
												{{ $t('rooming.miRooming') }}
											</router-link>
										</li>
										<li v-if="rolesUsuario.includes(rolComercial)">
											<router-link class="dropdown-item" :to="{ name: 'MisClientes' }">
												{{ $t('subtitulo.misViajeros') }}
											</router-link>
										</li>
										<!--<li>
											<router-link class="dropdown-item" :to="{ name: 'home' }"
												>Mis buses</router-link
											>
										</li> -->
										<li>
											<router-link class="dropdown-item" :to="{ name: 'perfil' }">
												{{ $t('general.miPerfil') }}
											</router-link>
										</li>
										<li class="dropdown-item" @click="botonLogout()">
											{{ $t('general.cerrarSesion') }}
										</li>
									</template>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isValidResumenReservas($route)" class="text-primary bg-primary py-2 resumen-reserva">
			<div class="row justify-content-between">
				<div class="col-7 col-md-5 mx-3">
					<!-- Button trigger modal -->
					<button
						type="button"
						class="btn btn-primary btn-reserva"
						data-bs-toggle="modal"
						data-bs-target="#staticBackdrop">
						{{ $t('botones.verReserva') }}
					</button>
				</div>
				<div class="col-2 col-md-5 d-flex align-items-center justify-content-end mx-3">
					<div class="precio text-secondary text-end me-3">
						<h3 class="my-0">
							<span v-if="$route.name === 'BuscadorServicios'"> {{ totalReservaHoteles }}€ </span>
							<span v-else-if="$route.name === 'FestivalesServicio'"> {{ calcularTotalFestival }} € </span>
							<span v-else class="fw-bold">{{ calculaPrecioTotalReservas(storeHabitacion.getResumenReservas) }}€</span>
						</h3>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal -->
	<div
		v-if="festivales"
		id="mobile-menu"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="mobile-menu"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<p class="modal-title fw-bold fs-5">Menu</p>
				</div>
				<div class="modal-body">
					<ul class="list-group list-group-flush">
						<div v-if="storeGlobal.permisos.filtro_viajes.showed" id="ski" class="accordion accordion-flush">
							<div class="accordion-item">
								<p v-if="storeGlobal.translations[locale]" id="skiHeading" class="accordion-header">
									<button
										class="accordion-button collapsed text-primary"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#Skicollapse"
										aria-expanded="false"
										aria-controls="Skicollapse">
										{{ storeGlobal.translations[locale].header.ski }}
									</button>
								</p>
								<div
									id="Skicollapse"
									class="accordion-collapse collapse"
									aria-labelledby="skiHeading"
									data-bs-parent="#ski">
									<div class="accordion-body">
										<div id="groups" class="accordion accordion-flush">
											<div v-if="storeGlobal.permisos.viajes.grupos.showed" class="accordion-item">
												<p id="flush-groupsheading" class="accordion-header">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#group-collapseOne"
														aria-expanded="false"
														aria-controls="group-collapseOne">
														{{ $t('header.ski.groups.title') }}
													</button>
												</p>
												<div
													id="group-collapseOne"
													class="accordion-collapse collapse"
													aria-labelledby="groupsheading"
													data-bs-parent="#groups">
													<div class="accordion-body">
														<ul class="list-group list-group-flush">
															<template v-for="grupo in listaGruposHeader" :key="grupo.nombre">
																<li class="list-group-item" data-bs-dismiss="modal">
																	<router-link
																		:to="{
																			name: ViajesGrupoCatalogo,
																			query: {
																				grupo: grupo.nombre,
																			},
																		}"
																		class="dropdown-item">
																		{{ grupo.descripcion }}
																	</router-link>
																</li>
															</template>
														</ul>
													</div>
												</div>
											</div>

											<div v-if="storeGlobal.permisos.viajes.buscador.showed" class="accordion-item">
												<p class="accordion-header">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#group-collapseMedida"
														aria-expanded="false"
														aria-controls="group-collapseMedida">
														{{ $t('general.viajeAMedida') }}
													</button>
												</p>
												<div
													id="group-collapseMedida"
													class="accordion-collapse collapse"
													aria-labelledby="groupsheading"
													data-bs-parent="#groups">
													<div class="accordion-body">
														<ul class="list-group list-group-flush">
															<div v-if="filtrosBuscador" class="row justify-content-start py-2">
																<div v-for="(area, index) in filtrosBuscador.areas" :key="index">
																	<div
																		class="dropdown-item"
																		data-bs-dismiss="modal"
																		@click="setDestino(area.nombre, area.id, -1)">
																		{{ capitalizeFirstLetter(area.nombre) }}
																	</div>
																	<ul
																		v-for="lugarExperiencia in area.lugaresExperiencia"
																		:key="lugarExperiencia.nombre"
																		role="button"
																		class="list-group list-group-flush">
																		<li
																			class="dropdown-item"
																			data-bs-dismiss="modal"
																			@click="setDestino(lugarExperiencia.nombre, area.id, lugarExperiencia.id)">
																			{{ capitalizeFirstLetter(lugarExperiencia.nombre) }}
																		</li>
																	</ul>
																</div>
															</div>
														</ul>
													</div>
												</div>
											</div>
											<div class="accordion-item">
												<p id="flush-groupsheading" class="accordion-header">
													<router-link
														:to="{
															name: 'DatosPersonales',
														}">
														<button
															class="accordion-button button-noicon collapsed"
															type="button"
															data-bs-dismiss="modal">
															<div class="dropdown-item pointer px-0">
																{{ $t('home.banner.button') }}
															</div>
														</button>
													</router-link>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							v-if="storeGlobal.permisos.night.festivales.showed || storeGlobal.permisos.night.apreski.showed"
							id="night"
							class="accordion accordion-flush">
							<div v-if="storeGlobal.translations[locale]" class="accordion-item">
								<p id="nightHeading" class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#night-collapseOne"
										aria-expanded="false"
										aria-controls="night-collapseOne">
										{{ storeGlobal.translations[locale].header.night }}
									</button>
								</p>
								<div
									id="night-collapseOne"
									class="accordion-collapse collapse"
									aria-labelledby="nightHeading"
									data-bs-parent="#night">
									<div class="accordion-body">
										<div
											v-if="storeGlobal.permisos.night.festivales.showed"
											id="festivals"
											class="accordion accordion-flush">
											<div class="accordion-item">
												<p id="festivalHeading" class="accordion-header">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#festivalCollapse"
														aria-expanded="false"
														aria-controls="festivalCollapse">
														{{ $t('header.night.festivals.title') }}
													</button>
												</p>
												<div
													id="festivalCollapse"
													class="accordion-collapse collapse"
													aria-labelledby="festivalHeading"
													data-bs-parent="#festivals">
													<div class="accordion-body">
														<ul class="list-group list-group-flush">
															<li
																v-for="festival in festivales"
																:key="festival"
																class="list-group-item"
																data-bs-dismiss="modal">
																<a href="#" class="dropdown-item" @click="goToFestival(festival.uri)">
																	{{ capitalizeFirstLetter(festival.uri) }}
																</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>

										<div v-if="storeGlobal.permisos.night.apreski.showed" id="apres" class="accordion accordion-flush">
											<div class="accordion-item">
												<p id="apresHeading" class="accordion-header">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#apresCollapse"
														aria-expanded="false"
														aria-controls="apresCollapse">
														{{ $t('header.night.apres.title') }}
													</button>
												</p>

												<div
													id="apresCollapse"
													class="accordion-collapse collapse"
													aria-labelledby="apresHeading"
													data-bs-parent="#apres">
													<div class="accordion-body">
														<ul class="list-group list-group-flush">
															<template
																v-for="lugarExperiencia in apresSkiHeader.slice(0, 4)"
																:key="lugarExperiencia.id">
																<div class="ps-3 fw-bold text-primary col-5">
																	{{ capitalize(lugarExperiencia.lugarExperiencia) }}

																	<div v-for="apre in lugarExperiencia.apresSkis" :key="apre.id" class="dropdown-item">
																		<router-link
																			:to="{
																				name: 'ApresSkisDetalle',
																				params: {
																					apresSkiId: apre.id,
																				},
																			}">
																			{{ apre.nombre }}
																		</router-link>
																	</div>
																</div>
															</template>
															<li class="list-group-item" data-bs-dismiss="modal">
																<router-link
																	class="dropdown-item fw-bold text-primary"
																	:to="{
																		name: 'ApresSkisCatalogo',
																	}">
																	{{ $t('botones.verTodos') }}
																</router-link>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="storeGlobal.permisos.nosotros.showed" id="us" class="accordion accordion-flush">
							<div class="accordion-item">
								<p id="flush-headingOne" class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseOne"
										aria-expanded="false"
										aria-controls="flush-collapseOne">
										{{ $t('header.us.title') }}
									</button>
								</p>
								<div
									id="flush-collapseOne"
									class="accordion-collapse collapse"
									aria-labelledby="flush-headingOne"
									data-bs-parent="#us">
									<div class="accordion-body">
										<ul class="list-group list-group-flush">
											<li class="list-group-item" data-bs-dismiss="modal">
												<router-link
													class="dropdown-item"
													:to="{
														name: 'QuienesSomos',
													}">
													{{ $t('header.us.whoWeAre') }}
												</router-link>
											</li>

											<li class="list-group-item" data-bs-dismiss="modal">
												<router-link
													class="dropdown-item"
													:to="{
														name: 'TrabajaConNosotros',
													}">
													{{ $t('header.us.work') }}
												</router-link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<li v-if="storeGlobal.permisos.blog.showed" class="list-group-item" data-bs-dismiss="modal">
							<router-link :to="{ name: 'BlogLanding' }">
								<span class="text-primary"> Blog </span>
							</router-link>
						</li>

						<li class="list-group-item">
							<div class="row mt-2">
								<div class="col">
									<div class="dropdown border-0">
										<button
											id="lanDropdown"
											class="btn caja-info shadow pointer hvr-grow social-icon"
											type="button"
											aria-label="Language button dropdown"
											data-bs-toggle="dropdown"
											aria-expanded="false">
											<img
												:src="languages[locale].icon"
												width="20"
												height="20"
												alt="Lenguage seleccionado"
												title="Lenguage seleccionado" />
											{{ $t('langs.' + languages[locale].code) }}
											<i class="fa-solid fa-caret-down" />
										</button>
										<ul class="dropdown-menu dropdown-index p-3 shadow-lg" aria-labelledby="lanDropdown">
											<div class="">
												<div v-for="a in availableLanguages" :key="a">
													<li
														@click="
															seleccionLang(a);
														">
														<a href="#" class="dropdown-item">
															<!-- <img
                            :src="languages[a].icon"
                            width="20"
                            height="20"
                            alt=""
                          /> -->
															<span class="mx-1">
															{{ $t('langs.' + languages[a].code) }}
															</span>
														</a>
													</li>
												</div>
											</div>
										</ul>
									</div>
								</div>
								<div v-if="storeGlobal.permisos.contacto.showed" class="col-auto">
									<div class="col-auto">
										<a href="https://wa.me/+34910054547" target="_blank">
											<div class="pointer hvr-grow">
												<i class="fa-brands fa-whatsapp fa-xl text-primary" />
												<span class="mx-2 text-primary">
													{{ phone }}
												</span>
											</div>
										</a>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal Resumen de reserva -->
	<div id="staticBackdrop" class="modal fade" tabindex="-1000" aria-labelledby="staticBackdropLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content text-primary">
				<div class="modal-header">
					<p id="resumenReservaLabel" class="modal-title fs-5 fw-bold">Resumen Reserva</p>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body">
					<CardLateralReserva v-if="storeHabitacion.reservas.length > 0" disable-collapse />
				</div>
			</div>
		</div>
	</div>

	<LoginModal @click="openModalLogin()" />
</template>

<script setup>
	import ViajesGrupoApiService from '../services/api/skiandnight/ViajesGrupoApiService';
	import { Buscador, ViajesGrupoCatalogo, UsuarioProximasReservas, UsuarioRooming } from '@/router/RouterNames';
	import { festivalesStore } from '@/store_pinia/festivales';
	import { computed } from 'vue';
	import { tiktok, instagram, phone } from '@/constants/socialMedias';
	import { ref, onBeforeMount, onMounted, onUnmounted, watch } from 'vue';
	import router from '../router';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { reservaHoteles } from '@/store_pinia/reservaHoteles';
	import { appGlobalStore } from '@/store_pinia/app';
	import { FestivalesDetalle } from '@/router/RouterNames';
	import { capitalizeFirstLetter, capitalize } from '@/helpers/filters';
	import { rolComercial } from '@/constants/rolesName';
	import { buscadorStore } from '@/store_pinia/buscador';
	import HomeApiService from '../services/api/skiandnight/HomeApiService';
	import CardLateralReserva from './card/Reserva/CardLateralReserva.vue';
	import viajesGrupo from 'src/store_pinia/viajes/viajesGrupo';
	import { calculaPrecioTotalReservas } from '@/helpers/viajesGrupos';
	import { useStore } from 'vuex';
	import LoginModal from '@/components/modales/LoginModal.vue';
	import { useLoginStore } from '@/store_pinia/login';
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';
	import Cookies from 'js-cookie';
	import i18n from '@/i18n';

	const { locale } = i18n.global;
	const route = ref(router.currentRoute);
	const store = useStore();
	const loginStore = useLoginStore();
	const buscador = viajesGrupo();
	const storeHabitacion = informacionHabitacion();
	const isMobile = ref(window.innerWidth <= 1033);

	const store_buscador = buscadorStore();
	const storeGlobal = appGlobalStore();
	const festivales = ref(null);
	const loadingFestivales = ref(true);
	const store_pinia_festivales = festivalesStore();

	store_pinia_festivales.setFestivales().then(() => {
		festivales.value = store_pinia_festivales.festivales;
		loadingFestivales.value = false;
	});
	const currentUrl = ref(window.location.href);

	const storeReservaHoteles = reservaHoteles();

	const apresSkiHeader = ref([]);

	const skiDd = ref(false);
	const organizadosDd = ref(false);
	const nosotrosDd = ref(false);
	const destinosDd = ref(false);
	const estacionesDd = ref(false);
	const minimizedHeader = ref(false);
	const dropdown = ref(false);
	const filtroViajes = ref({});
	const destinos = ref([]);
	const lugaresExperiencia = ref([]);
	const filtroSeleccionado = computed(() => store_buscador.filtrosSeleccionadosBuscador);

	const langs = ['es', 'en', 'fr', 'it'];

	const availableLanguages = ref(langs.filter(lang => lang !== locale.value));

	const languages = {
		es: {
			code: 'es',
			icon: '/es.svg',
		},
		en: {
			code: 'en',
			icon: '/gb.svg',
		},
		fr: {
			code: 'fr',
			icon: '/fr.svg',
		},
		it: {
			code: 'it',
			icon: '/it.svg',
		},
	};

	const listaGruposHeader = computed(() => {
		if (buscador.filtrosViajes && buscador.filtrosViajes.tiposGrupo.length) {
			return buscador.filtrosViajes.tiposGrupo.filter(tipoViaje => {
				const isSeaAndNight = window.location.origin === 'https://seaandnight.com';
				const basicGroupNames = ['universitarios', 'trabajadores', 'familias', 'premium', 'colegios'];
				const nombreTipoViaje = tipoViaje.nombre;
				if (isSeaAndNight) {
					if (!basicGroupNames.some(name => name == tipoViaje.nombre)) {
						return {
							nombre: nombreTipoViaje,
							descripcion: tipoViaje.descripcion,
						};
					}
				} else {
					return {
						nombre: nombreTipoViaje,
						descripcion: tipoViaje.descripcion,
					};
				}
			});
		} else {
			return [];
		}
	});

	const filtrosBuscador = computed(() => store_buscador.filtrosBuscador);
	const calcularTotalFestival = computed(() => store_pinia_festivales.getTotalTicketera);

	const nombreUsuario = computed(() => store.state.login.usuario.nombre);
	const hasRoleGestorRooming = computed(() => store.getters['login/hasRoleGestorRooming']);
	const rolesUsuario = computed(() => store.state.login.usuario.roles);
	const isUsuarioLogged = computed(() => store.getters['login/isUsuarioLogged']);

	const isValidUrl = computed(() => {
		if (storeGlobal.isSeaAndNightUrl && !isMobile.value) {
			return true;
		}
		if (storeGlobal.isSeaAndNightUrl && isMobile.value) {
			const validUrls = [
				'https://seaandnight.com/',
				'https://seaandnight-test.mygocu.com/',
				'https://seaandnight.com',
				'https://seaandnight-test.mygocu.com',
			];
			return !validUrls.some(url => url === currentUrl.value);
		}
		return true;
	});

	const totalReservaHoteles = computed(() => storeReservaHoteles.totalReserva);

	const windowLocation = computed(() => window.location.origin);

	function openModalLogin() {
		loginStore.openLoginModal();
	}

	function initLanguage() {
		const savedLang = Cookies.get('lang');
		if (savedLang) {
			seleccionLang(savedLang);
		} else {
			seleccionLang(mapLang());
		}
	}

	function mapLang() {
		const defLang = langs.filter(function (lang) { return window.navigator.language.startsWith(lang) });
		if (defLang.length !== 0) {
			return defLang[0];
		} else if(window.navigator.language.startsWith('ca')) {
			return 'es';
		} else {
			return 'en';
		}
	}

	function seleccionLang(lang) {
		locale.value = lang
		Cookies.set('lang', lang, { expires: 365 });
		availableLanguages.value = langs.filter(each => each !== lang);
	}

	function initApresSki() {
		HomeApiService.getApresSkisHeader().then(res => (apresSkiHeader.value = res));
	}

	function handleResize() {
		isMobile.value = window.innerWidth <= 1033;
	}

	function isValidResumenReservas(route) {
		let validRoutesName = [
			'GruposReservaFormulario',
			'ModificarReservas',
			'GruposReservaFormularioUnirse',
			'UsuarioProximaReservasModificar',
			'BuscadorServicios',
			'GruposReservaResumen',
			// "FestivalesServicio"
		];

		return validRoutesName.includes(route.name);
	}

	function toggleModal(modal) {
		switch (modal) {
			case 'skiDd':
				if (skiDd.value) {
					skiDd.value = false;
					organizadosDd.value.value = false;
					nosotrosDd.value = false;
					destinosDd.value = false;
					estacionesDd.value = false;
				} else {
					skiDd.value = true;
					organizadosDd.value = false;
					nosotrosDd.value = false;
					destinosDd.value = false;
					estacionesDd.value = false;
				}

				break;
			case 'organizadosDd':
				if (organizadosDd.value) {
					organizadosDd.value = false;
				} else {
					organizadosDd.value = true;
					nosotrosDd.value = false;
					destinosDd.value = false;
					estacionesDd.value = false;
				}

				break;
			case 'nosotrosDd':
				if (nosotrosDd.value) {
					skiDd.value = false;
					organizadosDd.value = false;
					nosotrosDd.value = false;
					destinosDd.value = false;
					estacionesDd.value = false;
				} else {
					skiDd.value = false;
					organizadosDd.value = false;
					nosotrosDd.value = true;
					destinosDd.value = false;
					estacionesDd.value = false;
				}

				break;
			case 'destinosDd':
				if (destinosDd.value) {
					destinosDd.value = false;
				} else {
					destinosDd.value = true;
					nosotrosDd.value = false;
					organizadosDd.value = false;
					estacionesDd.value = false;
				}

				break;
			case 'estacionesDd':
				if (estacionesDd.value) {
					estacionesDd.value = false;
				} else {
					estacionesDd.value = true;
					nosotrosDd.value = false;
					destinosDd.value = false;
					organizadosDd.value = false;
				}

				break;
			default:
				skiDd.value = false;
				organizadosDd.value = false;
				nosotrosDd.value = false;
				destinosDd.value = false;
				estacionesDd.value = false;

				break;
		}
	}

	function botonLogout() {
		store.dispatch('login/logout').then(() => {
			const dropdownMenus = document.querySelectorAll('#userDrop, #userDropMobile');
			dropdownMenus.forEach(dropdownMenu => {
				if (dropdownMenu && dropdownMenu.classList.contains('show')) {
					dropdownMenu.classList.remove('show');
				}
			});
			alertAutoCloseSuccessWithoutCallback(2000);
			if (router.currentRoute.value.matched.some(r => r.meta.requiresLogin)) {
				router.push({ name: 'home' });
			}
		});
	}

	function setDestino(nombre, areaId, estacionId) {
		const today = new Date();
		const tomorrow = new Date(today);
		store_buscador.setDestino(nombre, areaId, estacionId).then(() => {
			store_buscador.setFechasForfait(
				filtroSeleccionado.value.fechasAlojamiento[0] || tomorrow,
				filtroSeleccionado.value.fechasAlojamiento[1]
			);
			goToViajesAMedida();
		});
	}

	function onScroll() {
		toggleModal();
		if (window.top.scrollY > 80) {
			storeGlobal.isHeaderWhite = true;
			minimizedHeader.value = true;
		} else {
			if (!dropdown.value) {
				storeGlobal.isHeaderWhite = false;
				minimizedHeader.value = false;
			}
		}
	}

	function goToFestival(uri) {
		router.push({
			name: FestivalesDetalle,
			params: { uri: uri },
		});
	}

	function goToViajesAMedida() {
		router.push({
			name: Buscador,
			query: {
				destino: JSON.stringify(filtroSeleccionado.value.destino),
				fechasAlojamiento: JSON.stringify(filtroSeleccionado.value.fechasAlojamiento),
				fechasForfait: JSON.stringify(filtroSeleccionado.value.fechasForfait),
				habitaciones: JSON.stringify(filtroSeleccionado.value.habitaciones),
			},
		});
	}

	async function getData() {
		filtroViajes.value = await ViajesGrupoApiService.getFiltroViajes();
		filtroViajes.value.destino.forEach(destino => {
			destinos.value.push(destino.nombre);
			destino.lugaresExperiencia.forEach(lugarExperiencia => {
				lugaresExperiencia.value.push(lugarExperiencia.nombre);
			});
		});
	}

	watch(
		route,
		() => {
			currentUrl.value = window.location.href;
		},
		{ deep: true }
	);

	onBeforeMount(async () => {
		initLanguage();
		initApresSki();
		getData();
		window.addEventListener('scroll', onScroll);
	});
	onMounted(() => {
		window.addEventListener('resize', handleResize);
	});

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	@import '~@/assets/scss/styles.scss';
	@import '~@/assets/scss/main.scss';

	.dropdown-item {
		cursor: pointer;
		transition: 0.2s;
	}

	.subtile {
		cursor: auto;
	}

	.subtitle:hover {
		color: var(--bs-primary);
	}

	.social-icon {
		transition: 0.3s;
	}

	.social-icon:hover {
		color: var(--bs-secondary);
	}

	.dropdown-item:hover {
		background-color: transparent;
		color: var(--bs-secondary);
	}

	.img-close {
		cursor: pointer;
		height: 1rem;
	}

	.modal-content {
		background-color: white;
		border-radius: var(--bs-border-radius-lg);
		border: none;
	}

	.item-header {
		transition: 0.3s;
		color: $white;
		font-size: 23px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.18px;
	}

	.item-title {
		text-underline-offset: 8px;
	}

	.item-header:hover,
	.item-header:focus {
		color: var(--bs-secondary);
	}

	.modal-header {
		border: none;
		padding-bottom: 0;
	}

	.user {
		i {
			transition: 0.3s;
		}

		padding: 16px 16px !important;
	}

	.user:hover,
	.user:active,
	.user:focus {
		i {
			color: var(--bs-secondary);
		}
	}

	.modal-footer {
		border: none;
		padding-top: 0;
	}

	.header {
		@include media-breakpoint-down(md) {
			background-color: var(--mg-header-bg);
			color: var(--mg-header-color);
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1002;
		}
	}

	.header-main {
		@include media-breakpoint-down(md) {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 78px;
		}
	}

	.row-header {
		@include media-breakpoint-down(md) {
			height: 78px;
			width: 100% !important;
			justify-content: center !important;
		}
	}

	.img-logo {
		transition: 0.4s;
		max-width: 51px;
		max-height: 74px;
	}

	.img-perfil {
		transition: 0.4s;
		max-width: 30px;
	}

	.img-menu {
		display: inline-block;
		max-width: 20px;
		z-index: 10;
	}

	.text-align-center {
		text-align: center;
	}

	.fondoHeader {
		background-color: var(--mg-minimized-header-bg) !important;
		color: var(--mg-minimized-header-color) !important;
		.btn-menu {
			color: var(--mg-minimized-header-color) !important;
		}
	}

	.btn-menu {
		border: none;
		color: var(--mg-header-color);
	}

	.menu-collapse {
		background-color: white;
	}

	.titulo-nav-col {
		font-weight: 900;
		font-size: 1rem;
	}

	.items-navs {
		color: var(--bs-primary);
		font-size: 0.8rem;
	}

	.linkeable:hover {
		background-color: rgba(255, 255, 255, 0.2);
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		border-radius: var(--bs-border-radius-xl);
	}

	a {
		text-decoration: none;
		color: unset;
		cursor: pointer;
	}

	.content-header {
		display: none;
	}

	.mobile-buttons {
		display: flex;
		margin-right: 20px;
	}

	.resumen-reserva {
		display: block;
		box-shadow: 0px 2px 4px 0px rgba(var(--bs-primary-rgb), 0.5);
		height: 57px;
	}

	@media only screen and (min-width: 1033px) {
		.resumen-reserva {
			display: none;
		}

		.content-header {
			display: block;
		}

		.mobile-buttons {
			display: none;
		}

		.nav-header {
			font-size: 1.2rem !important;
		}

		.header {
			background-color: var(--mg-header-bg);
			color: var(--mg-header-color);
			z-index: 1001;
			transition: 0.4s;
			position: fixed;
			width: 100%;
		}

		.img-logo {
			transition: 0.4s;
			max-width: 125px;
			max-height: 170px;
		}

		.caja-info {
			font-size: 0.8rem;
			text-align: center;
			font-weight: 400;
			padding: 0.3rem 1rem 0.3rem 1rem;
			background-color: rgba(255, 255, 255, 0.25);
			border-radius: var(--bs-border-radius-xl);
		}

		.nav {
			width: 100%;
			margin: 1rem auto 0 auto;
		}

		.padding-right {
			padding: 1rem 0;
			padding-right: 4rem;
		}

		.desplegable-ski {
			width: 30rem !important;
		}

		.nav-item {
			font-size: 1rem;
			cursor: pointer;
		}

		.nav-item:hover {
			background-color: rgba(255, 255, 255, 0.2);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			border-radius: var(--bs-border-radius-xl);
		}
		.navItemSelected {
			background-color: rgba(255, 255, 255, 0.2);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			border-radius: var(--bs-border-radius-xl);
		}

		.nav-subitems {
			font-size: 0.8rem;
			padding: 1rem 2rem 1rem 2rem;
			border-radius: var(--bs-border-radius-xl);
			position: absolute;
			background-color: white;
			width: auto;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		}

		.onScrollIcon {
			max-width: 6rem;
			margin-top: 0 !important;
		}

		.onScrollColorText {
			margin-top: 0px;

			.col-auto {
				.btn {
					span {
						color: var(--bs-primary);
					}
				}

				.dropdown {
					.btn {
						span {
							color: var(--bs-primary);
						}
					}
				}

				.btn:hover {
					color: var(--bs-secondary);
				}

				.dropdown {
					.btn:hover {
						color: var(--bs-secondary);
					}
				}
			}
		}

		.onScrollHide {
			display: none;
		}
	}

	.button-noicon:not(.collapsed)::after {
		display: none;
	}

	.button-noicon.collapsed::after {
		display: none;
	}

	.btn-reserva {
		color: var(--bs-primary);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		box-shadow: 0px 1px 2px 0px rgba(var(--bs-secondary-rgb), 0.35);
	}

	.icon-perfil-container {
		z-index: 999;
		@include media-breakpoint-down(md) {
			width: 35px;
			height: 35px;
		}
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2px;
	}

	.icon-perfil {
		@include media-breakpoint-down(md) {
			width: 35px;
			height: 35px;
			font-size: 15px;
		}
		z-index: 1002;
		width: 45px;
		height: 45px;
		background-color: var(--bs-primary);
		border: 1.5px solid var(--bs-secondary);
		border-radius: 50%;
		color: var(--bs-secondary);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		box-shadow: 0 1px 3px 0 rgb(var(--bs-primary-rgb), 0.5);
	}

	.modal-ver-reserva {
		top: 50%;
		bottom: 50%;
	}

	.dropdown-index {
		z-index: 1003;
	}
</style>
