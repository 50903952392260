<template>
	<div class="card-servicios">
		<div class="row px-4 pt-3 pb-1 mt-2 text-white justify-content-between align-items-center">
			<div class="col-4">{{ $t('general.persona') }}{{ indexPersona + 1 }}</div>
			<div v-if="isFirstOne" class="col-7 col-sm-6 text-end">
				<div class="form-check">
					<label class="form-check-label" for="checkoutSoyYo">
						{{ $t('general.esMiReserva') }}
						<input id="checkoutSoyYo" v-model="soyYo" class="form-check-input" type="checkbox" />
					</label>
				</div>
			</div>
		</div>
		<div class="d-flex pb-3 flex-row px-4 text-white mb-3 justify-content-between align-items-center">
			<div :class="isUnderAge ? 'col-10 col-md-8' : 'col-10'">
				<div v-if="!soyYo" class="row d-flex flex-column flex-md-row">
					<div class="col-12 col-md-6 mb-2 mb-md-0">
						<input
							v-model="nombreUsuario.nombre"
							type="text"
							class="form-control"
							:placeholder="$t('home.form.personalInformation.name')" />
					</div>
					<div class="col-12 col-md-6">
						<input
							v-model="nombreUsuario.apellido"
							type="text"
							class="form-control"
							:placeholder="$t('home.form.personalInformation.lastName')" />
					</div>
				</div>
				<div v-else>
					<p class="m-0">
						{{ $t('general.nombreCard') }}
					</p>
				</div>
			</div>
			<div v-if="isUnderAge" class="col-3 p-2">
				<div class="form-check">
					<input id="flexCheckDefault" class="form-check-input" type="checkbox" value="" disabled checked />
					<label class="form-check-label" for="flexCheckDefault" style="font-size: 0.7rem">
						{{ $t('general.esMenorEdad') }} ({{ informacionMiembro.edad }} {{ $t('general.anios') }})
					</label>
				</div>
			</div>
			<div class="col-2 col-sm-1 text-center">
				<button class="btn btn-transparent text-white" @click="showServices = !showServices">
					<i class="fa-sharp fa-solid fa-chevron-up" :class="showServices ? 'rotate' : 'no-rotate'" />
				</button>
			</div>
		</div>
	</div>
	<Transition name="slide-up">
		<template v-if="showServices">
			<select-service-group-viajes-medida
				:index-persona="indexPersona"
				:informacion-miembro="informacionMiembro"
				:id-viaje="idViaje"
				@servicios-persona-actualizado="actualizarServicio($event)" />
		</template>
	</Transition>
</template>

<script>
	import { computed, defineComponent, reactive, ref, watch } from 'vue';
	import { useStore } from 'vuex';
	import SelectServiceGroupViajesMedida from '../../components/select/selectService/SelectServiceGroupViajesMedida.vue';
	export default defineComponent({
		components: { SelectServiceGroupViajesMedida },
		props: {
			indexPersona: {
				type: Number,
				default: null,
			},
			informacionMiembro: {
				type: Object,
				default: null,
			},
			indexHabitacion: {
				type: Number,
				default: null,
			},
			idViaje: {
				type: Number,
				default: null,
			},
		},
		emits: ['serviciosPersonaActualizado'],
		setup(props, { emit }) {
			const showServices = ref(props.indexPersona == 0 && props.indexHabitacion == 0);

			const nombreUsuario = reactive({
				nombre: '',
				apellido: '',
			});

			const store = useStore();

			const isUnderAge = props.informacionMiembro.edad < 18;

			const soyYo = ref(props.informacionMiembro.soyYo);

			const isLogged = computed(() => store.state.login.isLogged);

			let serviciosPersona = {};

			serviciosPersona['persona' + props.indexPersona] = {};

			const actualizarServicio = $event => {
				emit('serviciosPersonaActualizado', $event);
			};

			const isFirstOne = props.indexPersona == 0 && props.indexHabitacion == 0;

			watch(nombreUsuario, () => {
				serviciosPersona['persona' + props.indexPersona][
					'nombre'
				] = `${nombreUsuario.nombre} ${nombreUsuario.apellido}`;
				emit('serviciosPersonaActualizado', serviciosPersona);
			});

			const setearInformacionUsuario = () => {
				if (isFirstOne) {
					if (store.state.login.isLogged) {
						if (props.indexHabitacion == 0 && props.indexPersona == 0) {
							serviciosPersona['persona' + props.indexPersona]['nombre'] = `${store.state.login.usuario.nombre} ${
								store.state.login.usuario.apellidos.split(' ')[0]
							}`;
							serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
							emit('serviciosPersonaActualizado', serviciosPersona);
						}
					}
				}
			};

			watch(isLogged, () => {
				if (isFirstOne) {
					if (isLogged.value) {
						serviciosPersona['persona' + props.indexPersona]['nombre'] = `${store.state.login.usuario.nombre} ${
							store.state.login.usuario.apellidos.split(' ')[0]
						}`;
						serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
						emit('serviciosPersonaActualizado', serviciosPersona);
					} else {
						serviciosPersona['persona' + props.indexPersona]['nombre'] = ' ';
					}
					emit('serviciosPersonaActualizado', serviciosPersona);
				}
			});

			setearInformacionUsuario();

			watch(soyYo, () => {
				if (soyYo.value) {
					setearInformacionUsuario();
				} else {
					serviciosPersona['persona' + props.indexPersona]['nombre'] = ' ';
				}

				serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
				emit('serviciosPersonaActualizado', serviciosPersona);
			});

			return {
				showServices,
				actualizarServicio,
				nombreUsuario,
				soyYo,
				isFirstOne,
				isUnderAge,
				isLogged,
			};
		},
	});
</script>

<style scoped>
	.form-check-input:checked {
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}

	.no-rotate {
		transition: 0.3s ease-in-out;
	}

	.rotate {
		transition: 0.3s ease-in-out;
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
	}

	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: all 0.3s ease-out;
	}

	.slide-up-enter-from {
		opacity: 0;
		transform: translateY(30px);
	}

	.slide-up-leave-to {
		opacity: 0;
		transform: translateY(-30px);
	}

	.card-servicios {
		background: linear-gradient(40.81deg, var(--bs-primary) -21.45%, #454a79 144.52%, #454a79 144.52%);
		border-radius: var(--bs-border-radius-xl);
	}
</style>
