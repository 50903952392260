<template>
	<teleport to="body">
		<modal-base :show="modalActive" :title="t('rooming.hasTerminado')" :align-footer="'center'" @on-close="closeModal">
			<template #body>
				<p class="text-primary">{{ t('rooming.guardarSalirMensaje') }}</p>
			</template>
			<template #footer>
				<div class="d-flex flex-column align-items-center">
					<div class="button-group mb-2">
						<button class="text-primary btn-link" @click="publishRooming()">{{ t('rooming.enviar') }}</button>
						<button class="btn btn-primary btn-guardar" @click="saveRooming(true)">
							{{ t('rooming.guardarSalir') }}
						</button>
					</div>
					<div v-if="showError" class="error-container mt-2">
						<span class="error-message">{{ errorMessage }}</span>
					</div>
				</div>
			</template>
		</modal-base>
	</teleport>
	<ModalSuplementoRooming
		v-if="repartirCoste"
		:modal-active="modalSuplementoRoomingActive"
		@close-modal="closeSuplementoRoomingModal" />
</template>

<script setup>
	import { ref, defineProps, defineEmits, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import ModalBase from '@/components/modales/ModalBase';
	import ModalSuplementoRooming from '@/components/modales/Rooming/ModalSuplementoRooming.vue';
	import RoomingApiService from '@/services/api/skiandnight/RoomingApiService';
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';

	const { t } = useI18n();

	const props = defineProps({
		roomingData: {
			type: Object,
			required: true,
		},
		repartirCoste: {
			type: Boolean,
			required: true,
		},
		modalActive: {
			type: Boolean,
			required: true,
		},
	});

	const emits = defineEmits(['close-modal', 'close-all-modals']);

	const modalSuplementoRoomingActive = ref(false);
	const errorMessage = ref('');
	const showError = ref(false);

	async function publishRooming() {
		/* COMENTADO porque actualmente depende de la config de admin */
		/* if (props.repartirCoste) {
			modalSuplementoRoomingActive.value = true;
		} else { */
		try {
			await saveRooming(false);
			await RoomingApiService.publishRooming(props.roomingData.id);
			emits('close-all-modals');
			alertAutoCloseSuccessWithoutCallback(2000);
			errorMessage.value = '';
			showError.value = false;
		} catch (error) {
			errorMessage.value = t('rooming.error.publicar');
			showError.value = true;
		}
	}

	async function saveRooming(close = false) {
		try {
			await RoomingApiService.saveRooming(props.roomingData.id, buildData());
			if (close) {
				emits('close-all-modals');
			}
			alertAutoCloseSuccessWithoutCallback(2000);
			errorMessage.value = '';
			showError.value = false;
		} catch (error) {
			errorMessage.value = t('rooming.error.guardar');
			showError.value = true;
		}
	}

	function closeModal() {
		emits('close-modal');
	}

	function closeSuplementoRoomingModal() {
		modalSuplementoRoomingActive.value = false;
	}

	function buildData() {
		return {
			habitaciones: props.roomingData.habitaciones.map(habitacion => ({
				id: habitacion.id,
				integrantes: habitacion.integrantes.map(integrante => integrante.id),
			})),
		};
	}

	watch(
		() => props.modalActive,
		(newVal, oldVal) => {
			if (!newVal && oldVal) {
				errorMessage.value = '';
				showError.value = false;
			}
		}
	);
</script>

<style scoped lang="scss">
	.modal-title {
		color: var(--bs-primary);
	}

	.modal-footer {
		display: flex;
		justify-content: center;
		gap: 10px;
	}

	.modal-sm {
		max-width: 300px;
	}

	.btn-link {
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.12px;
		text-decoration: underline;
		color: var(--bs-primary);
		border: none;
		background: none;
		cursor: pointer;
		padding: 0;
	}
	:deep(.modal-content) {
		background: white !important;
	}

	:deep(.modal-container) {
		min-width: 50vw;
		padding: 25px 30px;
	}

	.btn-guardar {
		box-shadow: 0px 1px 2px 0px rgba(var(--bs-primary-rgb), 0.35) !important;
		font-size: 14px;
		font-weight: 500;
		padding: 10px 20px;
	}

	.error-message {
		color: #e54a5c;
		font-size: 14px;
		font-weight: bold;
		text-align: center;
	}

	.button-group {
		display: flex;
		gap: 10px;
		justify-content: center;
	}

	.error-container {
		text-align: center;
		color: red;
	}
</style>
