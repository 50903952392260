<template>
	<div>
		<div v-if="currentVideo" class="video-overflow py-5 my-5">
			<div class="container p-4">
				<p class="fw-bold text-white d-block d-lg-none mb-4" style="font-size: 18px">
					{{ currentVideo.title }}
				</p>
				<div class="d-flex flex-column flex-lg-row">
					<div class="col d-flex align-items-center">
						<iframe
							v-if="currentVideo.thumbnail"
							:srcdoc="linkYoutube"
							style="border-radius: var(--bs-border-radius-xl); max-width: 640px; width: 100%; aspect-ratio: 16/9"
							frameborder="0" />
					</div>
					<div class="col text-white py-3 ms-0 ms-lg-5">
						<p class="fw-bold d-none d-lg-block" style="font-size: 36px">
							{{ currentVideo.title }}
						</p>
						<p v-sanitize-html="currentVideo.description" class="description" />
						<!-- <button class="btn btn-secondary text-white" style="border-radius: var(--bs-border-radius-xxl);">Consultar todos los
                            viajes</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref, onBeforeMount } from 'vue';

	onBeforeMount(() => {
		videoReady.value = true;
	});

	const videoReady = ref(false);

	const props = defineProps({
		categoria: {
			type: String,
			default: '',
		},
	});

	const categoriaTipo = ref(props.categoria);

	const currentVideo = computed(() => {
		let categoriaValue = categoriaTipo.value;
		if (categoriaValue === 'random') {
			const categories = ['sail', 'surf', 'sand'];
			categoriaValue = categories[Math.floor(Math.random() * categories.length)];
		}
		// let urlViajes = [
		// 	'https://www.youtube.com/embed/UzWFHOC7VKs',
		// 	'https://www.youtube.com/embed/IyP55M9BjjE',
		// 	'https://www.youtube.com/embed/RdgEQjLtWE8'
		// ];
		switch (categoriaValue) {
			case 'sail':
				return {
					url: 'https://www.youtube.com/embed/G6OwIqF6yTI?si=pI0A1Q-DhxNjhnPC',
					title: 'Descubre todas las experiencias Ski&Night',
					description:
						'<span><br>Organiza tu viaje a la nieve a cualquier destino de Europa. <b>Andorra, Formigal o Sierra Nevada </b> son los destinos perfectos para disfrutar del de la nieve y del mejor ocio nocturno.<br><br> En Ski&Night te ofrecemos una experiencia única en los mejores destinos en pleno paraíso natural. Te facilitamos el <b>alojamiento, clases, alquiler de material y acceso a festivales</b> para que solo te preocupes de disfrutar. Disfruta de nuestro Winter Snow Festival, Snowdaze, y mucho más...</span>',
					thumbnail:
						'https://static1.squarespace.com/static/636e796b92411c02dd7d3d39/t/637405f638b16523fc4fa37f/1668548086315/FACEBOOK.png?format=1500w',
				};
			// return {
			// 	url: 'https://www.youtube.com/embed/RdgEQjLtWE8',
			// 	title: '¿Quieres navegar por nuestras costas este verano?',
			// 	description:
			// 		'<span><br> Navega con los tuyos en <b>catamarán o velero</b> por las aguas de Ibiza, Valencia, Denia o Javea al mejor precio.<br><br> Desde Sea&Night <b>organizamos la experiencia completa </b>  para que no te preocupes de nada; desde el patrón, pasando por la reserva en los mejores restaurantes y beach clubs de moda hasta alojamiento en tierra o en el propio barco.</span>',
			// 	thumbnail: 'https://vumbnail.com/RdgEQjLtWE8_large.jpg'
			// };
			case 'surf':
				return {
					url: 'https://www.youtube.com/embed/G6OwIqF6yTI?si=pI0A1Q-DhxNjhnPC',
					title: 'Descubre todas las experiencias Ski&Night',
					description:
						'<span><br>Organiza tu viaje a la nieve a cualquier destino de Europa. <b>Andorra, Formigal o Sierra Nevada </b> son los destinos perfectos para disfrutar del de la nieve y del mejor ocio nocturno.<br><br> En Ski&Night te ofrecemos una experiencia única en los mejores destinos en pleno paraíso natural. Te facilitamos el <b>alojamiento, clases, alquiler de material y acceso a festivales</b> para que solo te preocupes de disfrutar. Disfruta de nuestro Winter Snow Festival, Snowdaze, y mucho más...</span>',
					thumbnail:
						'https://static1.squarespace.com/static/636e796b92411c02dd7d3d39/t/637405f638b16523fc4fa37f/1668548086315/FACEBOOK.png?format=1500w',
				};
			// return {
			// 	url: 'https://www.youtube.com/embed/IyP55M9BjjE',
			// 	title: 'Nuestros viajes de surf en pleno paraíso natural',
			// 	description:
			// 		'<span><br>Organizamos tu viaje de surf al mejor precio. <b>Zarauz, Loredo o Salinas </b> son los destinos perfectos para disfrutar del surf en las playas más exóticas del norte.<br><br> En Sea&Night te ofrecemos una experiencia única en los mejores surfcamps en pleno paraíso natural. Te facilitamos el <b>alojamiento, clases, alquiler de material y otras actividades</b> para que solo te preocupes de disfrutar. </span>',
			// 	thumbnail: 'https://vumbnail.com/IyP55M9BjjE_large.jpg'
			// };
			case 'sand':
				return {
					url: 'https://www.youtube.com/embed/G6OwIqF6yTI?si=pI0A1Q-DhxNjhnPC',
					title: 'Descubre todas las experiencias Ski&Night',
					description:
						'<span><br>Organiza tu viaje a la nieve a cualquier destino de Europa. <b>Andorra, Formigal o Sierra Nevada </b> son los destinos perfectos para disfrutar del de la nieve y del mejor ocio nocturno.<br><br> En Ski&Night te ofrecemos una experiencia única en los mejores destinos en pleno paraíso natural. Te facilitamos el <b>alojamiento, clases, alquiler de material y acceso a festivales</b> para que solo te preocupes de disfrutar. Disfruta de nuestro Winter Snow Festival, Snowdaze, y mucho más...</span>',
					thumbnail:
						'https://static1.squarespace.com/static/636e796b92411c02dd7d3d39/t/637405f638b16523fc4fa37f/1668548086315/FACEBOOK.png?format=1500w',
				};
			// return {
			// 	url: 'https://www.youtube.com/embed/UzWFHOC7VKs',
			// 	title: '¿Sin viaje de verano de fin de exámenes?',
			// 	description:
			// 		'<span><br>Viaja este verano con tus amigos al mejor precio.<b> Conil, El Palmar o Salou </b> son los destinos perfectos para disfrutar de la playa en alojamientos con piscina a un paso de la playa o en nuestros gamplings.<br><br> Elige con tus amigos las fechas y destino y nosotros <b>nos encargamos de organizarlo todo</b>, desde el alojamiento hasta las fiestas en las discotecas y beach clubs de moda. </span>',
			// 	thumbnail: 'https://vumbnail.com/UzWFHOC7VKs_large.jpg'
			// };
			default:
				return {
					url: 'https://www.youtube.com/embed/G6OwIqF6yTI?si=pI0A1Q-DhxNjhnPC',
					title: 'Descubre todas las experiencias Ski&Night',
					description:
						'<span><br>Organiza tu viaje a la nieve a cualquier destino de Europa. <b>Andorra, Formigal o Sierra Nevada </b> son los destinos perfectos para disfrutar del de la nieve y del mejor ocio nocturno.<br><br> En Ski&Night te ofrecemos una experiencia única en los mejores destinos en pleno paraíso natural. Te facilitamos el <b>alojamiento, clases, alquiler de material y acceso a festivales</b> para que solo te preocupes de disfrutar. Disfruta de nuestro Winter Snow Festival, Snowdaze, y mucho más...</span>',
					thumbnail:
						'https://static1.squarespace.com/static/636e796b92411c02dd7d3d39/t/637405f638b16523fc4fa37f/1668548086315/FACEBOOK.png?format=1500w',
				};

			//{
			// 	url: urlViajes[Math.floor(Math.random() * urlViajes.length)],
			// 	title: 'Descubre nuestras experiencias de verano',
			// 	description:
			// 		'<span><br><b>Veleros y catamaranes, viajes de surf y sandtrips</b> son las nuevas experiencias que hemos lanzado bajo la marca Sea&Night para que disfrutes este verano. <br><br>Conoce todos los detalles para vivir tu verano al máximo y disfrutar con tu familia o amigos de un viaje inolvidable. <b>Elige tu destino y fechas </b> para viajar y nosotros nos encargamos del resto. </span>',
			// 	thumbnail: 'https://vumbnail.com/IyP55M9BjjE_large.jpg'
			// };
		}
	});

	const linkYoutube = computed(
		() => `  <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style><a
            href= ${currentVideo.value.url}
            class="full"
        >
            <img
                src=${currentVideo.value.thumbnail}
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>`
	);
</script>

<style>
	.video-overflow {
		width: 100vw;
		position: relative;
		left: calc(-50vw + 50%);
		background: linear-gradient(49.46deg, #313056 30.28%, #313056 30.28%, #30356b 87.32%);
	}

	.description {
		font-size: 21px;

		@media only screen and (max-width: 991px) {
			font-size: 11px;
		}
	}

	.video {
		height: 386px;

		@media only screen and (max-width: 450px) {
			height: 250px;
		}
	}
</style>
