<template>
	<div class="container superior">
		<div class="row titulo">
			<div class="col">
				{{ $t('general.terminosCondiciones') }}
			</div>
		</div>
		<div class="row question mt-5">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseOne"
				role="button"
				aria-expanded="false"
				aria-controls="collapseOne">
				{{ $t('footer.terminosCondiciones.orgTecJuridica.titulo') }}
			</a>
			<div id="collapseOne" class="collapse">
				<div class="card card-body border-0">
					<p>
						{{ $t('footer.terminosCondiciones.orgTecJuridica.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseTwo"
				role="button"
				aria-expanded="false"
				aria-controls="collapseTwo">
				{{ $t('footer.terminosCondiciones.inscripciones.titulo') }}
			</a>
			<div id="collapseTwo" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.inscripciones.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapsThree"
				role="button"
				aria-expanded="false"
				aria-controls="collapsThree">
				{{ $t('footer.terminosCondiciones.condicionContractual.titulo') }}
			</a>
			<div id="collapsThree" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.condicionContractual.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseFour"
				role="button"
				aria-expanded="false"
				aria-controls="collapseFour">
				{{ $t('footer.terminosCondiciones.cancelaciones.titulo') }}
			</a>
			<div id="collapseFour" class="collapse">
				<div class="card card-body">
					<p>
						<span class="spanned">{{ $t('footer.terminosCondiciones.cancelaciones.descripcionA') }}</span>
					</p>
					<p>
						<span class="spanned">{{ $t('footer.terminosCondiciones.cancelaciones.descripcionB') }}</span>
						<br />
						<span class="spanned">{{ $t('footer.terminosCondiciones.cancelaciones.cancelacionGratuita') }}</span>
						<br />
						{{ $t('footer.terminosCondiciones.cancelaciones.noReembolsable') }}
						<br />
						{{ $t('footer.terminosCondiciones.cancelaciones.25%') }}
						<br />
						{{ $t('footer.terminosCondiciones.cancelaciones.50%') }}
						<br />
						{{ $t('footer.terminosCondiciones.cancelaciones.100%') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseFive"
				role="button"
				aria-expanded="false"
				aria-controls="collapseFive">
				{{ $t('footer.terminosCondiciones.modificaciones.titulo') }}
			</a>
			<div id="collapseFive" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.modificaciones.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseSix"
				role="button"
				aria-expanded="false"
				aria-controls="collapseSix">
				{{ $t('footer.terminosCondiciones.precioViaje.titulo') }}
			</a>
			<div id="collapseSix" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.precioViaje.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseSeven"
				role="button"
				aria-expanded="false"
				aria-controls="collapseSeven">
				{{ $t('footer.terminosCondiciones.establecimientosAlojamiento.titulo') }}
			</a>
			<div id="collapseSeven" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.establecimientosAlojamiento.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseEight"
				role="button"
				aria-expanded="false"
				aria-controls="collapseEight">
				{{ $t('footer.terminosCondiciones.documentacion.titulo') }}
			</a>
			<div id="collapseEight" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.documentacion.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseNine"
				role="button"
				aria-expanded="false"
				aria-controls="collapseNine">
				{{ $t('footer.terminosCondiciones.responsabilidad.titulo') }}
			</a>
			<div id="collapseNine" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.responsabilidad.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseExample"
				role="button"
				aria-expanded="false"
				aria-controls="collapseExample">
				{{ $t('footer.terminosCondiciones.seguros.titulo') }}
			</a>
			<div id="collapseExample" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.seguros.descripcionA') }}
						<br />
						{{ $t('footer.terminosCondiciones.seguros.descripcionB') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseTen"
				role="button"
				aria-expanded="false"
				aria-controls="collapseTen">
				{{ $t('footer.terminosCondiciones.normas.titulo') }}
			</a>
			<div id="collapseTen" class="collapse">
				<div class="card card-body">
					<p>
						{{ $t('footer.terminosCondiciones.normas.descripcion') }}
					</p>
				</div>
			</div>
		</div>
		<div class="row question">
			<a
				class="btn"
				data-bs-toggle="collapse"
				href="#collapseTwelve"
				role="button"
				aria-expanded="false"
				aria-controls="collapseTwelve">
				{{ $t('footer.terminosCondiciones.datosPersonales.titulo') }}
			</a>
			<div id="collapseTwelve" class="collapse">
				<div class="card card-body">
					<table>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.responsable') }}</td>
							<td>{{ $t('footer.terminosCondiciones.datosPersonales.apartados.responsableDescripcion') }}</td>
						</tr>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.finalidad') }}</td>
							<td>
								{{ $t('footer.terminosCondiciones.datosPersonales.apartados.finalidadDescripcion') }}
							</td>
						</tr>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.legitimacion') }}</td>
							<td>{{ $t('footer.terminosCondiciones.datosPersonales.apartados.legitimacionDescripcion') }}</td>
						</tr>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.destinatario') }}</td>
							<td>
								{{ $t('footer.terminosCondiciones.datosPersonales.apartados.destinatarioDescripcion') }}
							</td>
						</tr>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.derechos') }}</td>
							<td>
								{{ $t('footer.terminosCondiciones.datosPersonales.apartados.derechosDescripcion') }}
							</td>
						</tr>
						<tr>
							<td class="spanned">{{ $t('footer.terminosCondiciones.datosPersonales.apartados.adicional') }}</td>
							<td>
								{{ $t('footer.terminosCondiciones.datosPersonales.apartados.adicionalDescripcion') }}
								<p>pd@skiandnight.com</p>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {};
</script>

<style scoped lang="scss">
	.btn {
		border-radius: 0;
		color: var(--bs-primary);
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0.2px;
		padding: 1.2rem 0;
		padding-left: 2rem;
		position: relative;
		text-align: left;
		width: 100%;

		&::after {
			content: '\2303';
			font-size: 2rem;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
		}

		&.collapsed {
			&::after {
				content: '\2304';
			}
		}

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.card-body {
		border: none;
	}

	.collapse {
		margin-bottom: 1rem;
	}

	.row.question {
		border-top: 1px solid #21244440;
	}

	.spanned {
		color: var(--bs-primary);
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.15px;
		line-height: 22px;
		text-align: left;
	}

	.superior {
		padding-top: 7rem;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 36px;
		font-weight: 700;
		letter-spacing: 0.2px;
		line-height: 44px;
		text-align: left;
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}

		.titulo {
			font-size: 2rem;
		}
	}
</style>
