<template>
	<div v-if="informacionReserva">
		<div class="banner-servicio d-flex flex-column">
			<div class="align-self-center h-50 w-100 d-flex align-items-center mt-5">
				<BaseMigas
					:migas-text="[
						$t('baseMigas.seleccionFestival'),
						$t('baseMigas.datosReserva'),
						$t('baseMigas.confirmacionReserva'),
						$t('baseMigas.pago'),
					]"
					:posicion="2"
					class="d-none d-md-block my-5" />
			</div>
			<div class="ps-5 pb-5 text-center">
				<h5 class="fw-bold text-primary">
					{{ $t('reserva.revisaDatos') }}
				</h5>
				<!-- <button class="btn btn-link ps-0">Ver Calendario</button> -->
			</div>
		</div>
	</div>
	<div v-if="informacionReserva" class="container-resumen">
		<div class="card card-resumen shadow m-4">
			<div class="card-body">
				<div class="card-title">
					<p class="text-primary fw-bold fs-4 mb-0">
						{{ informacionFestival.titulo }}
					</p>
					<p class="text-muted fs-5 mb-0">
						{{ informacionFestival.subtitulo }}
					</p>
					<p>
						{{ informacionFestival.descripcion }}
					</p>
					<p class="text-primary fw-bold">
						<i class="fa-solid fa-circle-info me-2" />
						Localizacion : {{ informacionFestival.ubicacion }}
					</p>
				</div>
				<div class="card-text pt-0">
					<template v-for="servicio in informacionReserva.paquetes_ticket" :key="servicio">
						<div class="row mt-3">
							<div class="col-auto">
								<p class="text-primary fw-bold mb-0">
									<span v-if="servicio.tipo != 'consumicion'"> {{ servicio.tickets[0].cantidad }} x </span>

									{{ servicio.titulo }}
								</p>
								<p class="text-muted text-pimrary mb-0">
									{{ servicio.subtitulo }}
								</p>
							</div>
							<div class="col text-secondary fw-bold text-end">
								<span v-if="servicio.tipo != 'consumicion'">
									{{ calcularTotalPaquete(servicio.tickets) }}
									€
								</span>
							</div>
						</div>
						<div v-for="ticket in servicio.tickets" :key="ticket" class="row ps-5">
							<div class="col-auto">
								<span v-if="ticket.cantidad && servicio.tipo == 'consumicion'"> {{ ticket.cantidad }} x </span>

								{{ ticket.nombre }}
							</div>
							<div class="col text-end text-secondary fw-bold text-end">
								<span v-if="ticket.precio && servicio.tipo == 'consumicion'">
									{{ ticket.precio * ticket.cantidad }} €
								</span>
							</div>
						</div>

						<!-- <div v-if="servicio.tipo == 'consumicion'">
              <hr />
              <div class="row fw-bold text-primary">
                <div class="col">TOTAL</div>
                <div class="col"></div>
                <div class="col text-end">
                  <span class="text-secondary">
                    {{ calculoSubTotal(servicio.tickets) }} €
                  </span>
                </div>
              </div>
            </div> -->
					</template>
					<template v-if="informacionReserva.codigoDescuento">
						<div class="row justify-content-between">
							<div class="col-auto text-primary fw-bold">
								{{ $t('reserva.descuento') }}
							</div>
							<div class="col-5 col-md-6">
								{{ informacionReserva.codigoDescuento.descripcion }}
							</div>
							<div class="col-3 col-md-2 text-end text-secondary fw-bold">
								<span class="mx-2">
									-{{ informacionReserva.codigoDescuento.cantidad }}
									<span v-if="informacionReserva.codigoDescuento.tipoCantidad === 'PORCENTAJE'"> % </span>
									<span v-else>€</span>
								</span>
								<span @click="limpiarCodigo()">
									<div class="btn btn-close text-primary"></div>
								</span>
							</div>
						</div>
					</template>
					<div v-if="informacionReserva.tasaGestion != 0" class="row">
						<div class="col-auto">{{ $t('servicios.repercutirFee') }}</div>
						<div class="col text-secondary fw-bold text-end">{{ informacionReserva.tasaGestion }} €</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="d-flex justify-content-md-between justify-content-center my-4 align-items-baseline row w-100 mx-auto g-3 align-items-start">
			<div class="col-12 col-md-6 d-flex flex-column m-0">
				<FestivalesFormCodigo
					:uri="uri"
					:evento-id="eventoId"
					:ticketera-id="informacionReserva.id"
					@descuento-validado="handlerCodigoDescuento" />
			</div>

			<div class="d-flex fs-4 shadow container-total col-auto">
				<div class="text-total p-3 text-primary fw-bold rounded-3">Total</div>
				<div class="p-3 text-secondary fw-bold background-linear rounded-3">
					<template v-if="informacionReserva.codigoDescuento && informacionReserva.codigoDescuento.totalActualizado">
						{{ informacionReserva.codigoDescuento.totalActualizado }}
					</template>
					<template v-else>
						{{ informacionReserva.precioTotal }}
					</template>
					€
				</div>
			</div>
		</div>

		<div class="my-4">
			<div class="d-flex flex-column align-items-end me-2">
				<div class="form-check">
					<input id="flexCheckDefault" v-model="politicasPrivacidad" class="form-check-input" type="checkbox" />
					<label class="form-check-label">
						{{ $t('home.form.policy.readAccept') }}
						<span class="btn-link" @click="openPoliticas()">
							{{ $t('home.form.policy.privacyPolicy') }}
						</span>
					</label>
				</div>
				<div class="form-check">
					<input id="flexCheckDefault2" v-model="politicasCancelacion" class="form-check-input" type="checkbox" />
					<label class="form-check-label">
						{{ $t('home.form.policy.readAccept') }}
						<span class="btn-link" data-bs-toggle="modal" data-bs-target="#politicas">
							{{ $t('home.form.policy.policyCancel') }}
						</span>
					</label>
				</div>
				<!-- Modal -->
				<div id="politicas" class="modal fade" tabindex="-1" aria-labelledby="politicasLabel" aria-hidden="true">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-body">
								<button type="button" class="btn-close button" data-bs-dismiss="modal" aria-label="Close" />
								<h5 class="text-secondary fw-bold">
									{{ $t('home.form.policy.cancelReserva') }}
								</h5>
								<p>Las condiciones de reserva para el viaje son las siguientes.</p>
								<p>
									Si el cliente desiste de la reserva, después de la formalización del depósito exigido por considerar
									firme la reserva NO REEMBOLSABLE
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="text-end my-5 pe-3">
			<button class="btn btn-primary fw-bold" @click="realizarPago()">
				{{ $t('botones.pagar').toLocaleUpperCase() }} {{ $t('subtitulo.total').toLocaleUpperCase() }}
			</button>
		</div>

		<div v-if="redsys">
			<form name="from" :action="redsys.url" method="POST">
				<input type="hidden" name="Ds_SignatureVersion" :value="redsys.version" />
				<input type="hidden" name="Ds_MerchantParameters" :value="redsys.parametros" />
				<input type="hidden" name="Ds_Signature" :value="redsys.firma" />
			</form>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent, ref } from 'vue';
	import BaseMigas from '@/components/BaseMigas';
	import { festivalesStore } from 'src/store_pinia/festivales';
	import EventosApiService from 'src/services/api/skiandnight/EventosApiService';
	import { useRoute } from 'vue-router';
	import { controlErrorRealizarPago } from 'src/services/utils/ErrorControl';
	import PasarelaPagoApiService from '@/services/api/skiandnight/PasarelaPagoApiService';
	import { createToaster } from '@meforma/vue-toaster';
	import FestivalesFormCodigo from './FestivalesFormCodigo.vue';
	import router from 'src/router';

	export default defineComponent({
		components: { BaseMigas, FestivalesFormCodigo },
		setup() {
			const store = festivalesStore();
			const route = useRoute();
			const uri = route.params.uri;
			const eventoId = route.params.eventoId;
			const informacionFestival = ref({});
			const redsys = ref();
			const politicasPrivacidad = ref(false);
			const politicasCancelacion = ref(false);

			const toaster = createToaster({ position: 'top-right' });

			const informacionReserva = computed(() => store.ticketeraCreada);

			if (!informacionReserva.value) {
				router.push({ name: 'home' });
			}

			EventosApiService.getFestival(uri)
				.then(res => {
					informacionFestival.value = res;
				})
				.catch(() => router.push('home'));

			const calculoSubTotal = listaServicio => {
				let sumAux = 0;
				listaServicio.map(ticket => {
					sumAux += ticket.precio * ticket.cantidad;
				});
				return sumAux;
			};

			const calcularTotalPaquete = listaTickets => {
				let sumAux = 0;
				listaTickets.forEach(ticket => {
					sumAux += ticket.cantidad * ticket.precio;
				});
				return sumAux;
			};

			const calcularTotalServicio = servicioReservado => {
				let totalAux = 0;
				servicioReservado.paquetes_ticket.map(ticket => {
					ticket.tickets.forEach(t => {
						totalAux += t.precio * t.cantidad;
					});
				});
				return totalAux;
			};

			const realizarPago = () => {
				if (!politicasPrivacidad.value) {
					toaster.error('Falta confirmar politicas de privacidad');
					return;
				}
				if (!politicasCancelacion.value) {
					toaster.error('Falta confirmar politicas de cancelacion');
					return;
				}

				var codigo = informacionReserva.value.codigoDescuento ? informacionReserva.value.codigoDescuento.codigo : null;

				PasarelaPagoApiService.ticketeraCobroInicial(informacionReserva.value.id, codigo)
					.then(res => {
						redsys.value = res;
					})
					.catch(err => {
						controlErrorRealizarPago(err);
					})
					.finally(() => {
						document.from.submit();
					});
			};

			const openPoliticas = () => {
				let routeData = router.resolve({ name: 'PoliticaPrivacidad' });
				window.open(routeData.href, '_blank');
			};

			const handlerCodigoDescuento = informacionCodigoValidado => {
				store.setDescuentoTicketera(informacionCodigoValidado);
			};

			const limpiarCodigo = () => {
				store.clearDescuentoTicketera();
			};

			return {
				informacionReserva,
				calculoSubTotal,
				calcularTotalServicio,
				informacionFestival,
				realizarPago,
				calcularTotalPaquete,
				openPoliticas,
				politicasPrivacidad,
				politicasCancelacion,
				redsys,
				handlerCodigoDescuento,
				limpiarCodigo,
				eventoId,
				uri,
			};
		},
	});
</script>

<style scoped>
	.banner-servicio {
		width: 100%;
		min-height: 350px;
		background-image: url('src/assets/festivales/bannerServicio.webp');
		background-size: cover;
		padding-top: 7rem;
	}

	.container-resumen {
		max-width: 900px;
		margin: auto;
	}

	.card-resumen {
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-xl);
	}

	.card-resumen,
	.text-total {
		background-color: #f9f7f8;
	}

	.container-total {
		border-radius: var(--bs-border-radius-xl);
		overflow: hidden;
	}

	.background-linear {
		background: linear-gradient(185.13deg, #5f5e8f -7.77%, #313056 76.51%);
	}
</style>
../../../store_pinia/festivales../../../services/api/skiandnight/EventosApiService../../../services/utils/ErrorControl../../../router
