import { defineStore } from 'pinia';
import EventosApiService from '../services/api/skiandnight/EventosApiService';
import { firstLetterCapital } from '../helpers/languageUtils';

export const festivalesStore = defineStore('festivalesStore', {
	state: () => ({
		festivales: null,
		festival: null,
		reservaServiciosFestivales: null,
		ticketeraCreada: null,
		totalTicketera: 0,
	}),

	actions: {
		async setFestivales() {
			if (!this.festivales) {
				await EventosApiService.getFestivales().then(res => (this.festivales = res));
			}
		},
		async setFestival(uri) {
			if (!this.festival || this.festival.uri != uri) {
				await EventosApiService.getFestival(uri).then(res => (this.festival = res));
			}
		},
		setReservaServiciosFestivales(informacionServicios) {
			this.reservaServiciosFestivales = informacionServicios;
		},
		addCantidad(servicio, ticket) {
			this.reservaServiciosFestivales[
				`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
			].cantidad++;
			if (servicio.tipo == 'consumicion') {
				let index = this.reservaServiciosFestivales[
					`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
				].tickets.findIndex(({ id }) => id == ticket.id);
				if (index == -1) {
					let ticketAux = { ...ticket, cantidad: 1 };
					this.reservaServiciosFestivales[
						`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
					].tickets.push({ ...ticketAux });
				} else {
					let valorAnterior =
						this.reservaServiciosFestivales[
							`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
						].tickets[index];
					let cantidadAux = ++valorAnterior.cantidad;
					this.reservaServiciosFestivales[
						`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
					].tickets[index] = {
						...valorAnterior,
						cantidad: cantidadAux,
					};
				}
			}
		},
		removeCantidad(servicio, ticket) {
			this.reservaServiciosFestivales[
				`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
			].cantidad--;
			if (servicio.tipo == 'consumicion') {
				let index = this.reservaServiciosFestivales[
					`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
				].tickets.findIndex(({ id }) => id == ticket.id);
				if (index != -1) {
					let valorAnterior =
						this.reservaServiciosFestivales[
							`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
						].tickets[index];
					let cantidadAux = --valorAnterior.cantidad;
					this.reservaServiciosFestivales[
						`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
					].tickets[index] = {
						...valorAnterior,
						cantidad: cantidadAux,
					};
				}
			}
		},
		updateTicketsServicios(servicio, informacionTickets) {
			let listaTicketsAux = [];

			informacionTickets.map(ticket => {
				if (ticket) {
					listaTicketsAux.push(ticket);
				}
			});
			this.reservaServiciosFestivales[
				`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`
			].tickets = listaTicketsAux;
		},
		setTicketeraGuardada(ticketera) {
			this.ticketeraCreada = ticketera;
		},
		setDescuentoTicketera(informacionCodigoDescuento) {
			this.ticketeraCreada.codigoDescuento = informacionCodigoDescuento;
			if (informacionCodigoDescuento.tipoCantidad == 'PORCENTAJE') {
				this.ticketeraCreada.codigoDescuento.totalActualizado = (
					(this.ticketeraCreada.precioTotal * (100 - this.ticketeraCreada.codigoDescuento.cantidad)) /
					100
				).toFixed(2);
			} else {
				this.ticketeraCreada.codigoDescuento.totalActualizado =
					this.ticketeraCreada.precioTotal - this.ticketeraCreada.codigoDescuento.cantidad;
			}
		},
		clearDescuentoTicketera() {
			this.ticketeraCreada.codigoDescuento = undefined;
		},
	},
	getters: {
		getTotalTicketera() {
			let totalAux = 0;
			let auxReservas = this.reservaServiciosFestivales;
			if (auxReservas == null) return;
			Object.keys(this.reservaServiciosFestivales).map(servicio => {
				this.reservaServiciosFestivales[servicio].tickets.map(ticket => {
					totalAux += ticket.precio * this.reservaServiciosFestivales[servicio].cantidad;
				});
			});
			return totalAux;
		},
	},
});
