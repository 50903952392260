import SkiAndNightApiService from './BaseSkiAndNightApiService';

const HOME_API_VERSION = 'v1';
const HOME_API_BASEPATH_URL = HOME_API_VERSION + '/grupos';

class ReservasApiService {
	async getProximasExperiencias() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL);
	}

	async getGrupo(grupoId, draft = false) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/' + grupoId + '?draft=' + draft));
	}

	async getGrupoByCodigo(codigo) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/' + codigo));
	}

	async getReservaDetalleUsuario(reservaId, email) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/${reservaId}/detalle/${email}`));
	}

	async doModificarServiciosReservasGrupo(grupoId, modificarReservaBody) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/servicios`),
			modificarReservaBody
		);
	}

	async doForzarPlazasLibres(grupoId) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/forzarPlazasLibres`));
	}

	async doEnviarCodigoGrupo(grupoId, info) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/enviarCodigo`),
			info
		);
	}

	async doCrearSuperGrupo(grupoId, infoSuperGrupo) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo`),
			infoSuperGrupo
		);
	}

	async doUnirseSuperGrupo(grupoId, infoSuperGrupo) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/unir`),
			infoSuperGrupo
		);
	}

	async doCompartirSuperGrupo(grupoId, info) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/enviarCodigo`),
			info
		);
	}

	async doCancelarReservasGrupo(grupoId, listaReservasCancelar, motivoCancelacionId, explicacionCancelacion) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/cancelar`), {
			reservasId: listaReservasCancelar,
			motivoCancelacionId,
			explicacionCancelacion,
		});
	}

	async doRecuperarReservasGrupo(grupoId, listaReservasRecuperar) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/recuperar`), {
			reservasId: listaReservasRecuperar,
		});
	}

	async getBusesReservaHabitacion(grupoId, reservaId) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/bus`));
	}

	async getBonoAdjunto(grupoId, reservaId) {
		return await SkiAndNightApiService.get(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/bonoAdjunto`)
		);
	}

	async getMotivosCancelacion() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/motivosCancelacion'));
	}

	async getInformacionCheckIn(grupoId, reservaId) {
		return await SkiAndNightApiService.get(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/checkIn`)
		);
	}

	async realizarCheckIn(reservaId, grupoId, reservaForm) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/checkIn`),
			reservaForm
		);
	}

	async getServicioToValidar(grupoId, reservaId, codigoValidacion) {
		return await SkiAndNightApiService.get(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/servicios/${codigoValidacion}`)
		);
	}

	async doValidarServicio(grupoId, reservaId, codigoValidacion) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/servicios/${codigoValidacion}/validar`)
		);
	}
}

export default new ReservasApiService();
