<template>
	<div class="buscador-grupos__component-home__desktop">
		<div class="buscador-grupos__component-home__desktop__container">
			<div class="dropdown-destino__container">
				<div class="dropdown">
					<div id="dropdownBuscadorDestinosGrupos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="dropdown-titulo">
							{{ $t('searcher.destiny.title') }}
						</div>
						<div class="dropdown-subtitulo">
							<template v-if="dataBuscadorGruposRealizado.destiny.station">
								{{ titleCase(dataBuscadorGruposRealizado.destiny.station) }}
								<button class="clear-filter" @click="limpiarDestinoBuscador">
									<i class="fa-solid fa-xmark fa-2xs" />
								</button>
							</template>
							<template v-else>
								{{ $t('searcher.destiny.subtitle') }}
							</template>
						</div>
					</div>
					<ul class="dropdown-menu" aria-labelledby="dropdownBuscadorDestinoGrupos">
						<div v-for="(area, index) in dataFiltroViajes.destino" :key="index" class="item-menu__container">
							<div class="item-menu__title">
								{{ titleCase(area.nombre) }}
							</div>
							<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
								<div
									:id="lugarExperiencia.id"
									:value="lugarExperiencia.nombre"
									@click="sendDestinoBuscador(area, lugarExperiencia)">
									{{ titleCase(lugarExperiencia.nombre) }}
								</div>
							</div>
						</div>
					</ul>
				</div>
			</div>
			<div class="dropdown-fechas__container">
				<div class="dropdown">
					<div id="dropdownBuscadorFechasGrupos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="dropdown-titulo">
							{{ $t('searcher.date.title') }}
						</div>
						<div class="dropdown-subtitulo">
							<template v-if="dataBuscadorGruposRealizado.date.year">
								{{ dataBuscadorGruposRealizado.date.monthName }}
								-
								{{ dataBuscadorGruposRealizado.date.year }}
								<button class="clear-filter" @click="limpiarFechaBuscador">
									<i class="fa-solid fa-xmark fa-2xs" />
								</button>
							</template>
							<template v-else>
								{{ $t('searcher.date.subtitle') }}
							</template>
						</div>
					</div>
					<ul class="dropdown-menu" aria-labelledby="dropdownBuscadorFechasGrupos">
						<Datepicker
							inline
							month-picker
							auto-apply
							:min-date="new Date(2022, 11, 1)"
							@update:model-value="sendFechaBuscador" />
					</ul>
				</div>
			</div>
			<div class="dropdown-grupos__container">
				<div class="dropdown">
					<div id="dropdownBuscadorTiposGrupos" data-bs-toggle="dropdown" role="button" aria-expanded="false">
						<div :class="error ? 'dropdown-titulo__error' : 'dropdown-titulo'">
							{{ $t('searcher.groups.title') }}
						</div>
						<div :class="error ? 'dropdown-subtitulo__error' : 'dropdown-subtitulo'">
							<template v-if="dataBuscadorGruposRealizado.groups.description">
								{{ dataBuscadorGruposRealizado.groups.description }}
							</template>
							<template v-else>
								{{ $t('searcher.groups.subtitle') }}
							</template>
						</div>
					</div>
					<ul class="dropdown-menu" aria-labelledby="groups">
						<div
							v-for="(grupo, index) in dataFiltroViajes.tiposGrupo"
							:key="index"
							role="button"
							class="dropdown-menu__container"
							@click="sendGruposBuscador(grupo)">
							<div class="dropdown-menu__item">
								<div class="texto-item">
									<div class="item-menu__title">
										{{ grupo.descripcion }}
										<p class="item-menu__descripcion">
											{{ selectorDescripcionGrupos(grupo.nombre) }}
										</p>
									</div>
								</div>
								<div class="selector-item">
									<div
										:class="
											tipoGrupoSeleccionado(grupo.descripcion)
												? 'selector-item__seleccionado'
												: 'selector-item__no-seleccionado'
										">
										<i v-if="tipoGrupoSeleccionado(grupo.descripcion)" class="fa-solid fa-check text-primary" />
									</div>
								</div>
							</div>
						</div>
					</ul>
				</div>
			</div>
			<div class="boton-buscar__container">
				<div class="boton-buscar__lupa" @click="doBusquedaGrupos">
					<i class="fa-solid fa-magnifying-glass fa-2x boton-buscar__icono" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref, reactive, toRaw } from 'vue';
	import Datepicker from '@vuepic/vue-datepicker';
	import { titleCase } from '@/helpers/filters';
	import { selectorDescripcionGrupos } from '@/helpers/viajesGrupos';
	import { useRoute } from 'vue-router';
	import { watch } from 'vue';

	const props = defineProps({
		informacionBusquedaGrupos: {
			type: Object,
			required: true,
		},
		filtroViajes: {
			type: Object,
			required: true,
		},
	});

	const emits = defineEmits([
		'limpiarDestinoBuscador',
		'setearDestinoBuscador',
		'limpiarFechaBuscador',
		'setearFechaBuscador',
		'setearGruposBuscador',
		'doBusquedaViajesGrupos',
	]);

	const error = ref(false);
	const route = useRoute();

	watch(route, () => {
		if (route.query.grupo) {
			sendGruposBuscador(dataFiltroViajes.value.tiposGrupo.find(({ nombre }) => nombre == route.query.grupo));
		}
	});

	const informacionBuscadorGrupos = reactive({
		destino: null,
		fechas: null,
		grupo: null,
	});

	const dataBuscadorGruposRealizado = computed(() => props.informacionBusquedaGrupos);
	const dataFiltroViajes = computed(() => props.filtroViajes);

	const tipoGrupoSeleccionado = tipoGrupoDescripcion => {
		return dataBuscadorGruposRealizado.value.groups.description == tipoGrupoDescripcion;
	};

	const limpiarDestinoBuscador = () => {
		informacionBuscadorGrupos.destino = null;
		emits('limpiarDestinoBuscador');
	};

	const limpiarFechaBuscador = () => {
		informacionBuscadorGrupos.fechas = null;
		emits('limpiarFechaBuscador');
	};

	const sendDestinoBuscador = (area, lugarExperiencia) => {
		informacionBuscadorGrupos.destino = {
			...area,
			lugarExperiencia: toRaw(lugarExperiencia),
		};
		emits('setearDestinoBuscador', area, lugarExperiencia);
	};

	const sendFechaBuscador = fecha => {
		informacionBuscadorGrupos.fechas = fecha;
		document.getElementById('dropdownBuscadorFechasGrupos').click();
		emits('setearFechaBuscador', fecha);
	};

	const sendGruposBuscador = grupo => {
		informacionBuscadorGrupos.grupo = grupo;
		emits('setearGruposBuscador', grupo);
	};

	const doBusquedaGrupos = () => {
		if (!dataBuscadorGruposRealizado.value.groups.name) {
			error.value = true;
		} else {
			error.value = false;
			emits('doBusquedaViajesGrupos', toRaw(informacionBuscadorGrupos));
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/san.scss';
	.buscador-grupos {
		&__component-home__desktop {
			@extend .text-start, .justify-content-center, .text-primary, .align-items-stretch, .d-none, .d-md-flex;
			height: 80px;
			background-color: rgba(255, 255, 255, 0.7);
			box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
			border-radius: var(--bs-border-radius-xl);
			width: 100%;
			max-width: 780px;
			border-radius: var(--bs-border-radius-xxl);
			margin: auto;

			&__container {
				@extend .row, .justify-content-between, .align-items-center, .w-100;
				padding: 0.2rem 2rem;

				.dropdown {
					&-titulo {
						font-size: 18px;
						font-weight: 500;
						font-style: normal;
						padding-top: 0;

						&__error {
							@extend .text-danger;
							font-size: 1.125rem;
						}
					}

					&-subtitulo {
						@extend .position-relative;
						color: #5d5c6a;
						font-size: 0.8rem;
						font-style: normal;

						.clear-filter {
							@extend .btn, .btn-secondary;
							width: 33px;
							height: 33px;
							position: absolute;
							top: -42px;
							right: 5px;
							border-radius: 100%;
						}

						&__error {
							@extend .text-danger;
						}
					}

					.dropdown-menu {
						.item-menu__title {
							@extend .fw-bold, .text-primary;
						}

						.item-menu__descripcion {
							@extend .text-muted, .fw-normal, .mb-0;
							font-size: 0.8rem;
						}
					}

					&-destino {
						&__container {
							@extend .col-3, .border-primary, .p-0;
							border-right: 1.5px solid;

							.dropdown-menu {
								@extend .px-2;
								min-width: 300px !important;
								width: auto !important;

								&__container {
									@extend .d-flex, .justify-content-start, .py-2;

									.item-menu {
										&__container {
											@extend .ps-2;
											width: auto !important;
											row-gap: 1rem;
										}
									}
								}
							}
						}
					}

					&-fechas {
						&__container {
							@extend .col-4, .border-primary, .px-0;
							border-right: 1.5px solid;
						}
					}

					&-grupos {
						&__container {
							@extend .col-3;

							.dropdown-menu {
								@extend .p-3;

								&__container {
									@extend .row, .align-items-center, .py-2, .px-0, .mx-0;
								}

								&__item {
									@extend .d-flex, .justify-content-between, .p-0;

									.texto-item {
										@extend .col-10;
									}

									.selector-item {
										@extend .col-2;

										&__seleccionado {
											@extend .bg-secondary, .d-flex, .align-items-center, .justify-content-center;
											margin-left: auto;
											margin-right: 10%;
											text-align: end;
											width: 30px;
											height: 30px;
											border-radius: 50%;
										}

										&__no-seleccionado {
											@extend .border-primary;
											margin-left: auto;
											margin-right: 10%;
											text-align: end;
											border: solid 1px;
											width: 30px;
											height: 30px;
											border-radius: 50%;
										}
									}
								}
							}
						}
					}
				}

				.boton-buscar {
					&__container {
						@extend .col-auto, .text-end, .pe-0;
					}

					&__lupa {
						@extend .px-0;
						cursor: pointer;
						transition: 0.3s ease-in-out;

						&:hover {
							transform: scale(1.1);
						}
					}

					&__icono {
						@extend .text-primary, .bg-secondary, .p-3, .rounded-circle;
					}
				}
			}
		}
	}
</style>
