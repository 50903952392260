<template>
	<div class="container">
		<base-carousel-center
			:items-carousel="experiencias"
			:is-loading="!experiencias"
			:autoplay-time="2500"
			:max-elements="3.5">
			<template #title>
				{{ $t('home.experiencias.title') }}
			</template>
			<template #itemCarousel="itemCarouselProps">
				<TarjetaHomeMejoresExperiencias :experiencia="itemCarouselProps.data" />
			</template>
		</base-carousel-center>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	//import { useI18n } from 'vue-i18n';
	import BaseCarouselCenter from '@/components/Carousel/BaseCarouselCenter.vue';
	import TarjetaHomeMejoresExperiencias from '@/components/tarjetas/TarjetaHomeMejoresExperiencias.vue';

	//import router from '@/router';

	const props = defineProps({
		experienciasList: {
			type: Array,
			required: true,
			default: () => null,
		},
	});

	const experiencias = computed(() => props.experienciasList);
</script>

<style lang="scss" scoped>
	.container {
		max-width: 100vw;
	}
</style>
