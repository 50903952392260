<template>
	<template v-if="bajaPublicidad != null">
		<div v-if="bajaPublicidad == true" class="container fs-3 mensaje">
			<h2 class="fw-bold text-primary text-center">Baja Email Publicitarios</h2>
			Ha sido dado de baja satisfactoriamente de los emails comerciales
		</div>
		<div v-else class="container fs-3 mensaje text-center">
			<h2 class="fw-bold text-primary text-center">Baja Email Publicitarios</h2>
			Ha ocurrido un error. Por favor, inténtelo más tarde.
		</div>
	</template>
	<template v-else>
		<Spinner />
	</template>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import UsuarioApiService from '@/services/api/skiandnight/UsuarioApiService';
	import { ref } from 'vue';
	import { useRoute } from 'vue-router';

	const route = useRoute();
	const codigo = route.params.codigo;
	const bajaPublicidad = ref();

	UsuarioApiService.darBajaPublicidad(codigo)
		.then(() => {
			bajaPublicidad.value = true;
		})
		.catch(() => {
			bajaPublicidad.value = false;
		});
</script>

<style lang="scss" scoped>
	.mensaje {
		min-height: 60vh;
		padding-top: 12rem;
	}
</style>
